/**
 *  candidates.jsx
 *  @version: 1.0.0
 *  @author: DWIT MÉXICO - Sergio
 *  @description: Card de Candidatos
*/

import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import HolderImage from '../../img/user.png';
import { Consumer } from '../../context';
import Request from "../../core/httpClient";
import shave from 'shave';
const request = new Request();

class CardFavoritesCandidates extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: { id: 0, tipo: 0 }
        }
    }

    passVariable() {
        this.props.history.push(`/candidato/${this.props.candidato.id_candidato}`);
    }

    async deleteFavorite() {
        const user = await this.props.context.loadUser();
        if (user) { this.setState({ user: user.id }); }
        let data = '';

        console.log("Candidato: ", this.props.candidato.id_candidato);

        if (this.props.candidato.freelacer === 1) {
            data = {
                tipo_user: user.id.tipo,
                id_user: user.id.id,
                tipo: 2,
                id_elemento: this.props.candidato.id_candidato
            };
        } else {
            data = {
                tipo_user: user.id.tipo,
                id_user: user.id.id,
                tipo: 3,
                id_elemento: this.props.candidato.id_candidato
            };
        }

        const res = await request.post('/favorites/set', data);
        if (res.error) {
            console.log(res.message);
        } else {
            this.setState({ fav: !this.state.fav });
        }
        this.props.load();
    }

    render() {
        let nombre = '', ciudad = '', imagen = '', funcion = '';
        if (this.props.candidato) {
            nombre = this.props.candidato.nombre;
            ciudad = this.props.candidato.ciudad;
            funcion = this.props.candidato.funcion;
            shave('.card-name', 22);
            if (this.props.candidato.imagen) {
                imagen = this.props.candidato.imagen;
            } else {
                imagen = HolderImage;
            }
        }

        return (
            <div className="card-company card-candidate card-company-fav" onClick={this.passVariable.bind(this)}>
                <div className="head">
                    <div className="logo responsive-img align-center">
                        <img src={imagen} alt="HP Logo" title="HP Logo" className="cover-img" />
                    </div>
                </div>
                <div className="info column">
                    <div className="title column">
                        <h4 className="font-regular padding card-name">
                            {nombre}
                        </h4>
                    </div>
                    <div className="white-space-8"></div>
                    <div className="align-center padding">
                        <i className="far fa-compass font-mini"></i>&nbsp;
                        <p className="font-mini">
                            {ciudad}
                        </p>
                    </div>
                    <div className="white-space-8"></div>
                    <div className="align-center padding">
                        <i className="far fa-object-group font-mini sector"></i>&nbsp;
                        <p className="font-mini sector">
                            {funcion}
                        </p>
                    </div>
                    <div className="white-space-8"></div>
                    <div className="btn-container">
                        <button className="btn btn-lightBlue font-mini weight-bold color-darkBlue text-center" onClick={this.deleteFavorite.bind(this)}>
                            <i className="fas fa-times"></i>
                        </button>
                    </div>
                </div>
            </div>
        );
    }

    componentDidMount() {
        shave('.card-name', 22);
    }

}

export default withRouter(Consumer(CardFavoritesCandidates));