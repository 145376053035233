/**
 *  conformation.jsx
 *  @version: 1.0.0
 *  @author: DWIT MÉXICO - Kath
 *  @description: MODAL AGREGAR OFERTA
*/


import React, { Component } from 'react';
import ThumbNail from '../../img/thumbnail.jpg';
import moment from 'moment-timezone';
import { Consumer } from '../../context';
import Request from '../../core/httpClient';
const request = new Request();


class OfferAdd extends Component {

	constructor(props){
        super(props);
        this.state = {
			badges: [], userData: { id: 0, tipo: 0 }, ciudades: [], created: false, loading1: false, loading2: false
        }
	}
	
    render() {
		let badges = [], cities= [];
        if (this.state.badges) { badges = this.state.badges; }
        if (this.state.ciudades.ciudades) { cities = this.state.ciudades.ciudades; }
        return (
            <div className="modal modal-confirm column justify-center align-center hidden" id="modal-offer">
                <div className="container modal-content align-center column" >
					<button type="button" className="btn auto" onClick = { this.handleClose.bind(this) }>
						<i className="fas fa-times font-medium" id="close-modal"></i>
					</button>
					<div className="offer offer-add">
						<div className="column">
							<form className="column" id="mobile-form" onSubmit={this.handleUpdate.bind(this)}>
								<div className="justify-center">
									<div className="container column">
										<div className="white-space-16"></div>
										<p className="weight-semi">
											Título:
										</p>
										<div className="white-space-8"></div>
										<input type="text" autoComplete="off" name="title" className="input-offer" minLength="10" maxLength="60" placeholder="Título de la oferta" />
										<div className="white-space-8"></div>
										<p className="weight-semi">
											Palabras clave:
										</p>
										<div className="white-space-8"></div>
										<div className="row row-tags">
											<input type="text" autoComplete="off" id="input-badge" name="badge" className="input-offer" placeholder="Anade palabras clave" />
											<button type="button" className="btn-darkBlue color-white weight-semi" onClick={this.handleBadge.bind(this)}>
												Añadir
											</button>
										</div>
										<div className="white-space-8"></div>
										<div className="badges badges-add wrap">
											{
												badges ?
													(
														badges.map((bage, key) =>
															<div className="badge-offer badge-offer-add auto" id={`badge-${key}`} key={key}>
																<p className="weight-semi font-mini color-darkBlue">
																	{bage}
																</p>
																<i className="fas fa-times-circle font-tiny color-darkBlue" onClick={this.handleDeleteBagde.bind(this)} ></i>
															</div>
														)
													) : (null)
											}
										</div>
										<div className="white-space-8"></div>
										<textarea name="description" placeholder="Descripción de la oferta (400 caracteres máximo)" cols="30" rows="4" minLength="50" maxLength="400" className="input-offer" required={true}>
										</textarea>
										<div className="white-space-8"></div>
										<p className="weight-semi">
											Salario:
										</p>
										<div className="white-space-8"></div>
										<input type="number" name="salary" className="input-offer" max="99999.99" placeholder="€ Ingresa cantidad" />
										<div className="white-space-8"></div>
										<p className="weight-semi">
											Ciudad:
										</p>
										<div className="white-space-8"></div>
										<select name="city" className="input-select" onChange={event => this.setState({ ciudad: event.target.value })}>
											{
												cities.map((item) =>
													<option id={item.ciudad} key={item.ciudad} value={item.ciudad} {...this.state.ciudad ? this.state.ciudad === item.ciudad ? document.getElementById(item.ciudad).selected = true : null : null}>
														{item.ciudad}
													</option>
												)
											}
										</select>
										<div className="white-space-16"></div>
										<div className="row align-center">
											<input name="temporal" id="trabtemp_chkbx" type="checkbox" className="input"></input>
											<label htmlFor="trabtemp_chkbx">Trabajo temporal</label>
										</div>
										<div className="white-space-8"></div>
										<div className="description column">
											<div className="white-space-16"></div>
											<p className="weight-semi">
												Añadir vídeos:
											</p>
											<div className="white-space-8"></div>
											<div className="videos">
												<div className="thumbnail responsive-img justify-center align-center">
													{
														this.state.video1 ?
															<video src={`${this.state.video1}#t=3,4`} width="150"></video>
															:
															<img src={ThumbNail} alt="Video Placeholder" title="Video Placeholder" />
													}
													<button type="button" onClick={() => document.getElementById('vd-src1').click()} className="btn-play">
														<i className="fas fa-upload fa-2x color-lightBlue"></i>
													</button>
													<input type="file" name="vd-src1" id="vd-src1" className="hidden" onChange={this.handleVideo.bind(this, 1)} accept="video/*" />
												</div>
												<div className="thumbnail responsive-img justify-center align-center">
													{
														this.state.video2 ?
															<video src={`${this.state.video2}#t=3,4`} width="150"></video>
															:
															<img src={ThumbNail} alt="Video Placeholder" title="Video Placeholder" />
													}
													<button type="button" onClick={() => document.getElementById('vd-src2').click()} disabled={this.state.loading1 ? false : true} className="btn-play">
														<i className="fas fa-upload fa-2x color-lightBlue"></i>
													</button>
													<input type="file" name="vd-src2" id="vd-src2" className="hidden" onChange={this.handleVideo.bind(this, 2)} accept="video/*" />
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="white-space-24"></div>
								<button  className="btn btn-large btn-full btn-lightBlue color-darkBlue text-center weight-bold" id="btn-top">
								{ this.state.created ? 'OFERTA PUBLICADA'  :'PUBLICAR OFERTA'  }
								{ this.state.loading ? <i className="fas fa-spinner fa-spin"></i> : null }
								</button>
							</form>
						</div>
					</div>
      			</div>
            </div>
        );
	}
		
    handleClose() {
		let modalAdd = document.getElementById('modal-offer');
		modalAdd.classList.add('fadeOut');
		setTimeout(() => {
			modalAdd.classList.add('hidden');
		}, 500);
	}
	
	componentDidMount() {
        this.load();
    }

    async load() {
        const user = await this.props.context.loadUser();
        if (user) {
            this.setState({ userData: user.id });
            this.loadCities();
        }
    }

    async loadCities() {
        const res = await request.get('/cities');
        if (res.ciudades) { this.setState({ ciudades: res.ciudades }); }
    }

    handleBadge() {
        let input = document.getElementById('input-badge');
        if ( input.value.length > 0) {
            let arrayState = this.state.badges;
            arrayState.push(input.value);
            this.setState({ badges: arrayState });
            input.value = '';
            input.focus();
        }
    }

    handleDeleteBagde(element) {
        let arrayState = this.state.badges;
        let currentBadge = element.target.parentNode.querySelector('p').innerHTML;        
        this.removeItem(arrayState, currentBadge);
    }

    removeItem( array, item ) {
        let i = array.indexOf(item);
        if (i !== -1) {
            array.splice(i, 1);
            this.setState({ badges: array });
        }
	}
	
	handleVideo(numVideo, event) {
		if (event.target.files.length > 0) {
			const video = event.target.files[0];
			const reader = new FileReader();
			reader.onload = () => {
				const media = new Audio(reader.result);
				media.onloadedmetadata = function () {
					if (media.duration < 4) {
						this.setState({ message: 'El video debe ser mayor a 4 segundos' });
						return;
					}
					if (media.duration > 30) {
						this.setState({ message: 'El video debe ser menor a 30 segundos' });
						return;
					}
					if (media.duration >= 4 && media.duration <= 30) {
						if (numVideo === 1) {
							this.setState({ video1: reader.result, video1_duracion: media.duration, loading1: true });
						}
						if (numVideo === 2) {
							this.setState({ video2: reader.result, video2_duracion: media.duration, loading2: true });
						}
					}
				}.bind(this);
			}
			reader.readAsDataURL(video);
		}
	}

	async handleUpdate(event) {
		event.preventDefault();
		const form = event.target;
		this.setState({ loading: true });
		if (this.state.loading1) {
			if (this.state.loading2) {
				if (this.state.badges.length > 0) {
					const data = {
						id: this.state.userData.id,
						title: form.title.value,
						description: form.description.value,
						salary: form.salary.value,
						keywords: JSON.stringify(this.state.badges),
						city: form.city.value,
						temporal: form.temporal.checked,
						video1: await this.uploadFirebase({ video: this.state.video1, num: 1, title: form.title.value }),
						video1_duracion: this.state.video1_duracion,
						video2: await this.uploadFirebase({ video: this.state.video2, num: 2, title: form.title.value }),
						video2_duracion: this.state.video2_duracion
					};
					const response = await request.post("/offers/upload", data);
					if (response.error) {
						this.setState({ message: response.message });
						alert(response.message);
					} else {
						if (response.created) {
							setTimeout(() => {
								window.location = "/perfil/empresa"
							}, 1500);
						}
					}
				} else {
					alert("Añade palabras clave");
				}
				this.setState({ loading: false });
			} else {
				if (this.state.badges.length > 0) {
					const data = {
						id: this.state.userData.id,
						title: form.title.value,
						description: form.description.value,
						salary: form.salary.value,
						keywords: JSON.stringify(this.state.badges),
						city: form.city.value,
						temporal: form.temporal.checked,
						video1: await this.uploadFirebase({ video: this.state.video1, num: 1, title: form.title.value }),
						video1_duracion: this.state.video1_duracion,
						video2: '',
						video2_duracion: 0
					};
					const response = await request.post("/offers/upload", data);
					if (response.error) {
						this.setState({ message: response.message });
						alert(response.message);
					} else {
						if (response.created) {
							setTimeout(() => {
								window.location = "/perfil/empresa"
							}, 1500);
						}
					}
				} else {
					alert("Añade palabras clave");
				}
				this.setState({ loading: false });
			}
		} else {
			if (this.state.badges.length > 0) {
				const data = {
					id: this.state.userData.id,
					title: form.title.value,
					description: form.description.value,
					salary: form.salary.value,
					keywords: JSON.stringify(this.state.badges),
					city: form.city.value,
					temporal: form.temporal.checked,
					video1: '',
					video1_duracion: 0,
					video2: '',
					video2_duracion: 0
				};
				const response = await request.post("/offers/upload", data);
				if (response.error) {
					this.setState({ message: response.message });
					alert(response.message);
				} else {
					if (response.created) {
						setTimeout(() => {
							window.location = "/perfil/empresa"
						}, 1500);
					}
				}
			} else {
				alert("Añade palabras clave");
			}
			this.setState({ loading: false });
		}
	}
	
	async uploadFirebase(data) {
		const storageRef = window.firebase.storage().ref();
		let fileName = `${this.state.userData.tipo}/${this.state.userData.id}/Ofertas/${data.title}/${moment().format('YYYYMMDDhhmmss')}_video${data.num}`;
		const ref = storageRef.child(`videos/${fileName}`);
		// Obtener ruta
		const result = await new Promise((resolve) => {
			ref.putString(data.video, 'data_url').then(function (snapshot) {
				const url = snapshot.ref.getDownloadURL().then(urlRef => {
					resolve(urlRef);
					return urlRef;
				});
				return url;
			});
		})
		return result;
	}

}

export default Consumer(OfferAdd);