import React, { Component } from 'react';
import { Consumer } from '../context';
import Header from '../components/header/header';
import HeaderWebHomeLoggedIn from '../components/header/header-desktop';
import LeftBar from '../components/left-bar/left-bar';
import MediaQuery from 'react-responsive';
import LeftBarSetting from '../components/left-bar/left-settings-bar';
import SupReq from 'superagent';
import Request from '../core/httpClient';
import ModalVideoAdd from '../components/modals/AddVideo';
import ModalOfferAdd from '../components/modals/offerAdd';
import moment from 'moment-timezone';


const req = new Request();

class Suscripcion extends Component {
	constructor(props) {
        super(props);
        this.state = {
			user: { id: 0, tipo: 0 },
			pagado: false,
			referencia: false,
			suscriptions: []
        };
	}

	makeid(length) {
		var result           = '';
		var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
		var charactersLength = characters.length;
		for ( var i = 0; i < length; i++ ) {
		   result += characters.charAt(Math.floor(Math.random() * charactersLength));
		}
		return result;
	 }
	 
	async pagar() {
		console.log(this.state)
		/*if(this.state.suscripcion === 'ACTIVA') {
			const modal = document.getElementById('modal_pay_response');
			this.setState({payMessage: 'Ya existe una suscripción activa'});
			if(modal) {
				modal.classList.remove('hidden')
			}
			return;
		}*/
		this.setState({payLoading: true});
		const data = {
			amount: 1000,
			order: this.makeid(12),
			url: 'http://goworkers.dwitsites.com/',
			urlOK: `http://localhost:8081/payments/responsePay`,
			urlKO: `http://localhost:8081/payments/responsePay`,
			identifier: this.state.referencia? this.state.referencia : 'REQUIRED',
			direct: true,
			parametros: `${this.state.user.id};${this.state.user.tipo}`
		}
		const postURL = 'http://localhost/paygoworkers.php'
		await SupReq.post(postURL).send(data).then(res => {
			if(res.body) {
				this.setState({
					Ds_MerchantParameters: res.body.parametros,
					Ds_Signature: res.body.llave,
					redsysUrl: 'https://sis-t.redsys.es:25443/sis/realizarPago'
				});
				const form = document.getElementById('formSbdll');
				if(form) {
					form.submit();
				}
			}
		})
		.catch(err => {
			console.log(err.message);
			this.setState({message: err.message, loading: false});
		});

		this.setState({payLoading: false});
	}

	async verifySuscription() {
		let fechas = this.state.suscriptions.map(sus => sus.fecha);
		const max = fechas.reduce(function (a, b) { return a > b ? a : b; });
		const actual = this.state.suscriptions.find(sus => sus.fecha === max);
		if(actual) {
			let estado = '';
			if(actual.estado === 'PAGADO') {
				estado = 'ACTIVA';
				console.log(moment().format())
				if(moment().add('days', 30).diff(moment(actual.fecha), 'days') < 0) {
					estado = 'VENCIDA';
				}
			} else {
				estado = 'ERROR'
			}
			this.setState({suscripcion: estado})
		}
	}

	async autoPay(event) {
		const data = {
			id: this.state.user.id,
			tipo: this.state.user.tipo,
			auto: event.target.checked
		}
		this.setState({autoLoading: true});
		const res = await req.post('/suscriptions/auto_pay', data);
		if(res.updated) {
			this.load();
		}
		this.setState({autoLoading: false, checked: data.auto});
	}

	render() {
		let lastSuscription = {};
		let renew_fecha = '';
		let monto = 0;
		if(this.state.suscriptions.length > 0) {
			// console.log(this.state.suscriptions)
			lastSuscription = this.state.suscriptions[0];
			renew_fecha = moment(lastSuscription.fecha).add('days', 30).format('DD/MM/YYYY');
			monto = parseFloat(lastSuscription.monto).toFixed(2);
		}
		const checkbox = document.getElementById('check_auto');
		if(checkbox) {
			checkbox.checked = this.state.checked;
		}
		return (
			<div className="subscription settings column align-center">
				<MediaQuery minDeviceWidth = { 768 }>
				<HeaderWebHomeLoggedIn/>
				<div className="row">
					<LeftBar active = "menuSettings"/>
					<div className="column align-center">
					<div className="white-space-32"></div>
						<div className="row-responsive container">
							<LeftBarSetting link="/ajustes/perfil" option = "3"/>
							<div className="column">
							<div className="column align-center justify-center ">
									<div className="row">
									<div className="column card-suscripcion align-center">
									<div className="white-space-16"></div>
										<div className ="row justify-arround">
											<div className="column align-center auto">
												<h4 className="font-small">Importe</h4>
												<div className="white-space-8"></div>
												<p>${monto}</p>
											</div>
											<div className="column align-center auto">
												<h4 className="font-small">Renovación</h4>
												<div className="white-space-8"></div>
												<p>{renew_fecha}</p>
											</div>
											<div className="column align-center auto">
												<h4 className="font-small">Estado</h4>
												<div className="white-space-8"></div>
												<p>{this.state.suscripcion}</p>
											</div>
										</div>	
										<div className="white-space-16"></div>
										{this.state.autoLoading? 
											<i className="fas fa-spinner fa-spin color-darkBlue"></i>:	
											<div className="row container justify-startr" id="check-content">
											{/*	<label className="checkbox-container">Activar renovación automática
												<input type="checkbox" name="tipoCandidato" id="check_auto" onChange={this.autoPay.bind(this)}/>
												<span className="checkmark"></span>
												</label> */ }
											</div>
										}			
										<div className="white-space-8"></div>
										<div className="row">
											<div className="column">
												<div className="btn-container justify-center">
													{
														this.state.pagado ?  (
															<button className="btn btn-lightBlue weight-semi color-darkBlue" id="btn-option">
																<i className="fas fa-money-check" />
																&nbsp; Actualizar
															</button>
														) :
														(
															this.state.payLoading?
															<i className="fas fa-spinner fa-spin"></i>
															:
															<button className="btn btn-lightBlue weight-semi color-darkBlue" id="btn-option"
																onClick={this.pagar.bind(this)}>
																<i className="fas fa-money-check" />
																&nbsp; Pagar
															</button>
														)
													}
												</div>
											</div>
											<div className="column">
												<div className="btn-container justify-center">
													<button className="btn btn-darkBlue weight-semi color-white" id="btn-option">
														<i className="fas fa-user-slash" />
														&nbsp; Cancelar
													</button>
												</div>
											</div>
										</div>
									</div>
									</div>
									<div className="white-space-24"></div>
									{this.state.loading? 
										<i className="fas fa-spinner fa-spin color-darkBlue"></i>:
										this.state.error? 
										<h4>{this.state.message}</h4>
										:
										<table className="list-subscription">
											<thead>
												<tr>
													<th className="font-small">Fecha</th>
													<th className="font-small">Estado</th> 
												</tr>
											</thead>
											<tbody>
												{this.state.suscriptions.map((sus,index) =>
													<tr key={index}>
														<td>{moment(sus.fecha).format('DD/MM/YYYY')}</td>
														<td>{sus.estado}</td> 
													</tr>
												)}
											</tbody>
										</table>
									}
							</div>
							<form className="column" id="formSbdll" method="post" action={this.state.redsysUrl}>
								<input type="hidden" name="Ds_SignatureVersion" value="HMAC_SHA256_V1" />
								<input type="hidden" name="Ds_MerchantParameters" value={this.state.Ds_MerchantParameters} />
								<input type="hidden" name="Ds_Signature" value={this.state.Ds_Signature} />
							</form>
							<div className="modal modal-confirm hidden" id="modal_pay_response">
								<div className="container modal-content align-center column">						
									<div className="white-space-16"></div>
									<div className="justify-center">
										<h4>
											{this.state.payMessage}
										</h4>
									</div>
									<div className="white-space-16"></div>
									<div className="btn-container-modal">
										<button className="btn btn-lightBlue color-darkBlue text-center weight-bold" onClick={() => {
											const modal = document.getElementById('modal_pay_response');
											if (modal) {
												modal.classList.add('hidden')
											}}}>
											<i className="fas fa-check color-darkBlue"></i> ACEPTAR
										</button>
									</div>
								</div>
							</div>		

							</div>
							</div>
						</div>
					</div>
				</MediaQuery>
				<MediaQuery maxWidth = { 768 }>
				<Header back = "/ajustes"/>
				<div className="white-space-24"></div>
				<div className="column align-center justify-center container">
						<div className="row">
						<div className="column card-suscripcion align-center">
						<div className="white-space-16"></div>
							<div className ="row justify-arround">
								<div className="column align-center auto">
									<h4 className="font-small">Importe</h4>
									<div className="white-space-8"></div>
									<p>${monto}</p>
								</div>
								<div className="column align-center auto">
									<h4 className="font-small">Renovación</h4>
									<div className="white-space-8"></div>
									<p>{renew_fecha}</p>
								</div>
								<div className="column align-center auto">
									<h4 className="font-small">Estado</h4>
									<div className="white-space-8"></div>
									<p>{this.state.suscripcion}</p>
								</div>
							</div>	
							<div className="white-space-16"></div>
							{this.state.autoLoading? 
								<i className="fas fa-spinner fa-spin color-darkBlue"></i>:	
								<div className="row container justify-startr" id="check-content">
									<label className="checkbox-container">Activar renovación automática
									<input type="checkbox" name="tipoCandidato" id="check_auto" onChange={this.autoPay.bind(this)}/>
									<span className="checkmark"></span>
									</label>
								</div>
							}			
							<div className="white-space-8"></div>
							<div className="row">
								<div className="column">
									<div className="btn-container justify-center">
										{
											this.state.pagado ?  (
												<button className="btn btn-lightBlue weight-semi color-darkBlue" id="btn-option">
													<i className="fas fa-money-check" />
													&nbsp; Actualizar
												</button>
											) :
											(
												this.state.payLoading?
												<i className="fas fa-spinner fa-spin"></i>
												:
												<button className="btn btn-lightBlue weight-semi color-darkBlue" id="btn-option"
													onClick={this.pagar.bind(this)}>
													<i className="fas fa-money-check" />
													&nbsp; Pagar
												</button>
											)
										}
									</div>
								</div>
								<div className="column">
									<div className="btn-container justify-center">
										<button className="btn btn-darkBlue weight-semi color-white" id="btn-option">
											<i className="fas fa-user-slash" />
											&nbsp; Cancelar
										</button>
									</div>
								</div>
							</div>
						</div>
						</div>
						<div className="white-space-24"></div>
						{this.state.loading? 
							<i className="fas fa-spinner fa-spin color-darkBlue"></i>:
							this.state.error? 
							<h4>{this.state.message}</h4>
							:
							<table className="list-subscription">
								<thead>
									<tr>
										<th className="font-small">Fecha</th>
										<th className="font-small">Estado</th> 
									</tr>
								</thead>
								<tbody>
									{this.state.suscriptions.map((sus,index) =>
										<tr key={index}>
											<td>{moment(sus.fecha).format('DD/MM/YYYY')}</td>
											<td>{sus.estado}</td> 
										</tr>
									)}
								</tbody>
							</table>
						}
				</div>
				<form className="column" id="formSbdll" method="post" action={this.state.redsysUrl}>
					<input type="hidden" name="Ds_SignatureVersion" value="HMAC_SHA256_V1" />
					<input type="hidden" name="Ds_MerchantParameters" value={this.state.Ds_MerchantParameters} />
					<input type="hidden" name="Ds_Signature" value={this.state.Ds_Signature} />
				</form>
				<div className="modal modal-confirm hidden" id="modal_pay_response">
					<div className="container modal-content align-center column">						
						<div className="white-space-16"></div>
						<div className="justify-center">
							<h4>
								{this.state.payMessage}
							</h4>
						</div>
						<div className="white-space-16"></div>
						<div className="btn-container-modal">
							<button className="btn btn-lightBlue color-darkBlue text-center weight-bold" onClick={() => {
								const modal = document.getElementById('modal_pay_response');
								if (modal) {
									modal.classList.add('hidden')
								}}}>
								<i className="fas fa-check color-darkBlue"></i> ACEPTAR
                            </button>
						</div>
					</div>
				</div>		
				</MediaQuery>		
				<ModalVideoAdd></ModalVideoAdd>
            <ModalOfferAdd></ModalOfferAdd>	
			</div>
		)
	}

	componentDidMount() {
		this.load();
		// respuesta por parte del banco
		const modal = document.getElementById('modal_pay_response');
		const url = new URL(window.location.href);
		let response = url.searchParams.get("response");
		if(response) {
			response = JSON.parse(response);
			this.setState({payMessage: response.message});
			if(response.error) {
				if(modal) {
					modal.classList.remove('hidden')
				}
			}
			if(response[0]) {
				this.setState({payMessage: response[0].message});
				if(modal) {
					modal.classList.remove('hidden')
				}
			}
		}
		//MapLocation
        this.props.context.mapLocation(window.location.pathname);
	}

	async load() {
		const user = await this.props.context.loadUser();
		if(user) {
			this.setState({user: user.id})
		}
		this.setState({loading: true});
		const data = {
			id: user.id.id,
			tipo: user.id.tipo
		}
		const res = await req.post('/suscriptions/get', data);
		this.setState({message: res.message});
		if(res.error) {
			this.setState({error: res.error});
		}
		if(res.suscriptions) {
			// console.log(res.suscriptions);
			this.setState({suscriptions: res.suscriptions});
			if(res.suscriptions[0].identifier) {
				this.setState({referencia: res.suscriptions[0].identifier})
			}
			if(res.suscriptions[0].pago_auto) {
				this.setState({checked: res.suscriptions[0].pago_auto})
			}
			this.verifySuscription();
		}
		this.setState({loading: false});
	}
}

export default Consumer(Suscripcion);