/**
 *  register.jsx
 *  @version: 1.0.0
 *  @author: DWIT MÉXICO - Sergio
 *  @description: Modal para el registro de escritorio
*/

import React, { Component } from 'react';
import { Link, withRouter } from "react-router-dom";
import { Consumer } from '../../context';
import Request from "../../core/httpClient";
const req = new Request();

class ModalRegister extends Component {

    constructor(props) {
        super(props);
        this.state = {
            message: '', loading: false, ciudades: [], city: ''
        }
    }

    render() {
        let cities = [];
        if (this.state.ciudades.ciudades) { cities = this.state.ciudades.ciudades; }
        return (
            <div className="column">
                <div className="modal justify-center hidden" id="modal-register">
                    <div className="container modal-content align-center column" >
                        <div className="white-space-48"></div>
                        <h1 className="text-center">
                            Crea una cuenta
						</h1>
                        <button className="auto" id="close-modal-register">
                            <i className="fas fa-times"></i>
                        </button>
                        <div className="white-space-24"></div>
                        <form className="column" onSubmit={this.hanldeSubmit.bind(this)}>
                            <div className="input-container align-center">
                                <i className="fas fa-user-edit font-small text-center" />
                                <input type="text" name="name" placeholder="Nombre completo" minLength="6" maxLength="60" className="input" required />
                            </div>
                            <div className="white-space-8" />
                            <div className="input-container align-center">
                                <i className="fas fa-at font-small text-center" />
                                <input type="email" name="email" placeholder="Correo electrónico" minLength="8" maxLength="60" className="input" required />
                            </div>
                            <div className="white-space-8" />
                            <div className="input-container align-center">
                                <i className="fas fa-user-friends font-small text-center" />
                                <select name="gender" className="input input-select" required>
                                    <option value="">Género</option>
                                    <option value="Hombre">Hombre</option>
                                    <option value="Mujer">Mujer</option>
                                </select>
                            </div>
                            <div className="white-space-8" />
                            <div className="input-container align-center">
                                <i className="fas fa-map-marked-alt font-small text-center" />
                                <select name="city" id="city" className="input input-select" required onChange={this.cityChange.bind(this)}>
                                    {
                                        cities.map((item, index) =>
                                            <option key={index} value={item.ciudad}>{item.ciudad}</option>
                                        )
                                    }
                                </select>
                            </div>
                            <div className="white-space-8" />
                            <div className="input-container align-center">
                                <i className="fas fa-key font-small text-center" />
                                <input type="password" name="password" placeholder="Contraseña" className="input" minLength="8" maxLength="16" />
                            </div>
                            <div className="white-space-16" />
                            <div className="align-center">
                                <label className="checkbox-container">Soy un Freelancer
									<input type="checkbox" name="tipoCandidato" id="check" />
                                    <span className="checkmark"></span>
                                </label>
                            </div>
                            <div className="btn-container justify-center">
                                <button className="btn btn-lightBlue weight-bold font-regular color-darkBlue">
                                    CREAR CUENTA
								</button>
                            </div>
                            <div className="white-space-8" />
                            <div className="row-responsive justify-center">
                                <p className="text-center">
                                    {
                                        this.state.loading ?
                                            <i className="fas fa-sync fa-spin"></i>
                                            :
                                            this.state.message
                                    }
                                </p>
                            </div>
                            <div className="white-space-8"></div>
                            <div className="justify-center">
                                <Link to="/files/" className="text-center font-mini">
                                    Al registrarte, aceptas los Términos y Condiciones de Uso de Go Workers.
							    </Link>
                            </div>
                            <div className="white-space-16" />
                            <div className="btn-container justify-center">
                                <Link to="/registro/empresa" className="btn btn-darkBlue color-white text-center" id="btn-marcas">
                                    REGISTRO PARA EMPRESAS
							    </Link>
                            </div>
                        </form>
                        <div className="white-space-48"></div>
                    </div>
                </div>
            </div>
        );
    }

    componentDidMount() {
        this.getCities();
        let modalRegister = document.getElementById('modal-register');
        document.getElementById('close-modal-register').onclick = () => {
            modalRegister.classList.add('fadeOut');
            setTimeout(() => {
                modalRegister.classList.add('hidden');
            }, 500);
        }
    }

    async getCities() {
        const res = await req.get('/cities');
        if (res.ciudades) {
            this.setState({ ciudades: res.ciudades });
        }
    }

    async cityChange(e) {
        this.setState({ city: e.target.value });
    }

    async hanldeSubmit(event) {
        event.preventDefault();
        this.setState({ loading: true, message: "" });
        event.preventDefault();
        let freelancer = document.getElementById("check").checked;
        if (freelancer === true) {
            freelancer = 1;
        } else {
            freelancer = 0;
        }
        let ciudad = '';
        const city = document.getElementById('city');
        if (city) {
            ciudad = city.value;
        }
        const form = event.target;
        const data = {
            Nombre: form.name.value, Email: form.email.value, Genero: form.gender.value,
            Ciudad: ciudad, Password: form.password.value, Tipo: 1, Freelancer: freelancer
        };
        this.props.context.saveRegister(data);
        this.props.history.push('/categorias');
    }

}

export default withRouter(Consumer(ModalRegister));