/**
 *  home.jsx
 *  @version: 1.0.0
 *  @author: DWIT MÉXICO - Kath, Sergio
 *  @description: Pagina de busqueda
*/

import React, { Component } from 'react';
import { Consumer } from "../context";
import HeaderSearch from '../components/header/headerSearch';
import LeftBar from '../components/left-bar/left-bar';
import CardVideo from '../components/cards/video';
import CardJob from '../components/cards/job';
import CardCompany from '../components/cards/company';
import CardCandidates from '../components/cards/candidates';
import Tabs from '../components/tabs/tabs';
import Request from '../core/httpClient';
import MediaQuery from 'react-responsive';

const req = new Request();

class search extends Component {
	constructor(props) {
		super(props);
		this.state = {
			userData: {id: 0, tipo: 0},
			search: '',
			elements: [],
			numItems: 3,
		}
	}

	async filterCards(filters) {
		this.setState({ loading: true });
		document.getElementById('menyAvPaa').checked = false;
		await this.load(filters);
	}

	changeTab(tab) {
        window.localStorage.setItem('gow_stab', tab)
        this.setState({ tab, cards: [] });
        this.load();
    }

	render() {
    	return (
    		<div className="find column">
				 <MediaQuery minDeviceWidth = { 768 }>
                <div className="view-web column">
					<HeaderSearch filterCards={this.filterCards.bind(this)} search={(event)=> {event.preventDefault(); this.load()}} setSearch={(event)=> this.setState({search: event.target.value})}/>
                    <div className="row">
                        <LeftBar active = "menuHome"/>
                        <div className="column">
                            <div className="row justify-center">
                                <div className="cards-container container  column align-center justify-center">
                                <div className="white-space-32"></div>
								<Tabs tab = { this.state.tab } first="Videos" second={this.state.userData.tipo === 1 ? "Ofertas" : "Candidatos"} third={this.state.userData.tipo === 1 ? "Empresas" : "Freelancers"} user = { this.state.userData } changeTab = { this.changeTab.bind(this) } />
								<div className="justify-center">
									{this.state.loading ?
									(
										<div className="column loading-center">
											<div className="white-space-64"></div>
											<div className="justify-center">
												<i className="fas fa-spin fa-2x fa-spinner color-purple"></i>
											</div>
										</div>
									)
									:
									(
										this.state.empty ?
										(
											<div className="column">
												<div className="white-space-16"></div>
												<div className="justify-center">
													<h3 className="text-center">
														NO SE ENCONTRARON RESULTADOS
													</h3>
												</div>
												<div className="white-space-16"></div>
												<p className="text-center">
													Intenta buscar con palabras clave
												</p>
												<div className="white-space-16"></div>
												<i className="text-center fa-2x far fa-frown color-pink"></i>
											</div>
										)
										:
										<div className="vc column list-videos" ref="iScroll">
											{this.state.first ? this.state.cards : this.displayItems()}
										</div>
									)
									}
								</div>
								<div className="white-space-8"></div>
								{this.state.loadingState ? 
								<div className="justify-center">
									<i className="fas fa-spin fa-spinner"></i>  
								</div> : ""}
								<div className="white-space-32"></div>

								</div>
								</div>
							</div>
						</div>
					</div>
				</MediaQuery>
				<MediaQuery maxWidth = { 768 }>
				<div className="header-sticky column">
            	<HeaderSearch filterCards={this.filterCards.bind(this)} search={(event)=> {event.preventDefault(); this.load()}} setSearch={(event)=> this.setState({search: event.target.value})}/>
				<Tabs tab = { this.state.tab } first="Videos" second={this.state.userData.tipo === 1 ? "Ofertas" : "Candidatos"} third={this.state.userData.tipo === 1 ? "Empresas" : "Freelancers"} user = { this.state.userData } changeTab = { this.changeTab.bind(this) } />
				</div>
				<div className="justify-center">
					{this.state.loading ?
					(
						<div className="column loading-center">
							<div className="white-space-64"></div>
							<div className="justify-center">
								<i className="fas fa-spin fa-2x fa-spinner color-purple"></i>
							</div>
						</div>
					)
					:
					(
						this.state.empty ?
						(
							<div className="column">
								<div className="white-space-16"></div>
								<div className="justify-center">
									<h3 className="text-center">
										NO SE ENCONTRARON RESULTADOS
									</h3>
								</div>
								<div className="white-space-16"></div>
								<p className="text-center">
									Intenta buscar con palabras clave
								</p>
								<div className="white-space-16"></div>
								<i className="text-center fa-2x far fa-frown color-pink"></i>
							</div>
						)
						:
						<div className="vc container column" ref="iScroll">
							{this.state.first ? this.state.cards : this.displayItems()}
						</div>
					)
					}
				</div>
				<div className="white-space-8"></div>
				{this.state.loadingState ? 
				<div className="justify-center">
					<i className="fas fa-spin fa-spinner"></i>  
				</div> : ""}
				<div className="white-space-32"></div>
				</MediaQuery>
          	</div>
       	);
	}

	displayItems() {
        var items = [];
        if (this.state.tab === 'first') {
            for (let i = 0; i < this.state.numItems + 1; i++) {
                if (this.state.elements[i]) {
					if(this.state.elements[i].id_video) {
						items.push(
							<div key={this.state.elements[i].id_video}>
								<CardVideo video={this.state.elements[i]} />
							</div>);
					}
                }
            }
        } else if (this.state.tab === 'second') {
            if (this.state.userData.tipo === 1) {
                for (let i = 0; i < this.state.numItems + 1; i++) {
                    if (this.state.elements[i]) {
						if(this.state.elements[i].id_oferta) {
							items.push(
								<div key={this.state.elements[i].id_oferta}>
									<CardJob oferta={this.state.elements[i]} />
								</div>);
						}
                    }
                }
            } else {
				for (let i = 0; i < this.state.numItems + 1; i++) {
					if (this.state.elements[i]) {
						if (this.state.elements[i].id_candidato) {
							items.push(
								<div key={this.state.elements[i].id_candidato}>
									<CardCandidates candidato={this.state.elements[i]} />
								</div>);
						}
					}
				}
            }
        } else {
            if (this.state.userData.tipo === 1) {
                for (let i = 0; i < this.state.numItems + 1; i++) {
                    if (this.state.elements[i]) {
						if(this.state.elements[i].id_empresa) {
							items.push(
								<div key={this.state.elements[i].id_empresa }>
									<CardCompany empresa={this.state.elements[i]} />
								</div>);
						}
                    }
                }
            } else {
				for (let i = 0; i < this.state.numItems + 1; i++) {
					if (this.state.elements[i]) {
						if (this.state.elements[i].id_candidato) {
							items.push(
								<div key={this.state.elements[i].id_candidato}>
									<CardCandidates candidato={this.state.elements[i]} />
								</div>);
						}
					}
				}
            }
        }
        return items;
	}

	componentDidMount() {
		const input = document.getElementById('search');
		if (input) {
			input.value = '';
		}
		this.load();
		if (this.refs.iScroll) {
            this.refs.iScroll.addEventListener("scroll", () => {
                if (this.refs.iScroll.scrollTop + this.refs.iScroll.clientHeight >= this.refs.iScroll.scrollHeight) {
                    this.loadMoreItems();
                    this.setState({ first: false });
                }
            });
        }
        if (this.refs.iScroll) {
            this.refs.iScroll.addEventListener("scroll", () => {
                if (this.refs.iScroll.scrollTop + this.refs.iScroll.clientHeight >= this.refs.iScroll.scrollHeight) {
                    this.loadMoreItems();
                    this.setState({ first: false });
                }
            });
		}

		//MapLocation
		this.props.context.mapLocation(window.location.pathname);
	}
	
	async load(filters) {
		this.setState({ loading: true });
        const user = await this.props.context.loadUser();
		if (user) { this.setState({ userData: user.id }); }
		if(window.localStorage.getItem('gow_stab')) {
            this.setState({tab: window.localStorage.getItem('gow_stab')})
		}
		this.setState({ loading: true });
		const data = {
			search: this.state.search,
			tipo: user.id.tipo,
			tab: this.state.tab,
			id: user.id.id,
			filters
		}
		const res = await req.post('/buscar', data);
		if(res.busqueda) {
			this.setState({elements: res.busqueda, empty: false});
		} else {
			this.setState({empty: true});
		}
		this.setState({ loading: false});
	}

	loadMoreItems() {
        if (this.state.numItems <= this.state.elements.length) {
            this.setState({ loadingState: true });
            setTimeout(() => {
                this.setState({ numItems: this.state.numItems + 4, loadingState: false });
            }, 2000);
        }
    }
};
  
export default Consumer(search);