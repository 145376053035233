/**
 *  fav-video.jsx
 *  @version: 1.0.0
 *  @author: DWIT MÉXICO - Sergio
 *  @description: Card de favoritos de Empresa
*/

import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Consumer } from '../../context';
import Request from "../../core/httpClient";
import shave from 'shave';
const request = new Request();

class CardFavoriteVideo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: { id: 0, tipo: 0 }
        }
    }

    passVariable() {
        this.props.history.push(`/video/${this.props.video.id_video}`);
    }

    async deleteFavorite() {
        const user = await this.props.context.loadUser();
        if (user) { this.setState({ user: user.id }); }

        const data = {
            tipo_user: user.id.tipo,
            id_user: user.id.id,
            tipo: 1,
            id_elemento: this.props.video.id_video
        }

        const res = await request.post('/favorites/set', data);
        if (res.error) {
            console.log(res.message);
        } else {
            this.setState({ fav: !this.state.fav });
        }
        this.props.load();
    }

    render() {
        let videoUrl = '', titulo = '', nombre = '', duracion = 0, minutes = 0;
        if (this.props.video) {
            videoUrl = this.props.video.video_url+"#t=3,4";
            titulo = this.props.video.titulo;
            nombre = this.props.video.nombre;
            shave('.card-name', 22);
            duracion = parseInt(this.props.video.duracion);
            if (duracion > 60) {
                minutes = (duracion / 60).toFixed(1);
            } else {
                duracion.toFixed(1);
            }
        }

        return (
            <div className="card-video card-video-fav" >
                <div className="thumbnail responsive-img justify-center align-center" onClick={this.passVariable.bind(this)}>
                    <video src={videoUrl} width="130"></video>
                    <button className="btn-play">
                        <i className="far fa-play-circle fa-2x color-lightBlue"></i>
                    </button>
                </div>
                <div className="info column">
                    <h4 className="font-regular padding card-name">
                        {titulo}
                    </h4>
                    <div className="white-space-8"></div>
                    <div className="align-center padding">
                        <i className="far fa-user-circle font-mini"></i>&nbsp;
                        <p className="font-mini">
                            {nombre}
                        </p>
                    </div>
                    <div className="white-space-8"></div>
                    <div className="padding">
                        <div className="align-center">
                            <i className="far fa-clock font-mini"></i>&nbsp;
                            <p className="font-mini">
                                {
                                    minutes > 0 ?
                                    (
                                        minutes + " min."
                                    )
                                    :
                                    (
                                        duracion + " seg."
                                    )
                                }
                            </p>
                        </div>
                    </div>
                    <div className="btn-container">
                        <div className="contact justify-center">
                            <Link to={`/chat/${this.props.video.id_user}`} className="font-tiny weight-bold color-darkBlue">
                                <i className="fas fa-comments"></i>&nbsp; CHAT
                            </Link>
                        </div>
                        <div className="contact-remove justify-center">
                            <button className="btn-play" onClick={this.deleteFavorite.bind(this)}>
                                <i className="fas fa-times color-white"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    componentDidMount() {
        shave('.card-name', 22);
    }

}

export default withRouter(Consumer(CardFavoriteVideo));