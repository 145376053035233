/**
 *  offer.jsx
 *  @version: 1.0.0
 *  @author: DWIT MÉXICO - Sergio
 *  @description: Pantalla de ofertas
*/

import React, { Component } from 'react';
import Header from '../components/header/header';
import HeaderWebHomeLoggedIn from '../components/header/header-desktop';
import BadgeOffer from '../components/badges/offer';
import ThumbNail from '../img/thumbnail.jpg';
import ThumbNailUser from '../img/user.png';
import LoadingImage from '../img/loading.gif';
import ImageLoader from 'react-loading-image';
import MediaQuery from 'react-responsive';
import LeftBar from '../components/left-bar/left-bar';
import { Consumer } from '../context';
import { withRouter } from 'react-router-dom';
import Request from '../core/httpClient';
const request = new Request();

class Offer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            userData: { id: 0, tipo: 0 } ,
            video1: true, video2: true
            
        }
    }

    async addFav() {
        const idOferta = parseInt(this.props.match.params.id);

        const data = {
            tipo_user: this.state.userData.tipo,
            id_user: this.state.userData.id,
            tipo: 3,
            id_elemento: idOferta
        };

        const res = await request.post('/favorites/set', data);
        if (res.error) {
            console.log(res.message);
        } else {
            this.setState({ fav: !this.state.fav });
        }
    }

 

    closeModal(id) {
        const modal = document.getElementById(id);
        let video = document.getElementById("video-offer");
        video.pause();
        if(modal) {
            modal.classList.add('fadeOut');
            setTimeout(() => {
                modal.classList.remove('fadeIn');
                modal.classList.add('hidden');
            }, 500);
        }
    }

    render() {
        let offer = '', image = '', keywords = [];
        if (this.state.offer) {
            offer = this.state.offer;
            keywords = JSON.parse(this.state.offer.keywords);
            this.state.offer.imagen_principal_url ? image = this.state.offer.imagen_principal_url : image = ThumbNailUser ;
        }
        // console.log(this.props.context);
        return (
            <div className="offer column">
                <MediaQuery minDeviceWidth = { 768 }>
                    <div className="settings view-web column">
                        <HeaderWebHomeLoggedIn/>
                        <div className="row">
                            <LeftBar/>
                            <div className="column align-center">
                                <div className="white-space-24"></div>
                                <div className="row justify-between container justify-center">
                                <div className="column left-column">
                                    <div className="white-space-16"></div>
                                    <h2>
                                        {offer.titulo}
                                    </h2>
                                    <div className="date-fav">
                                        <p className="font-mini color-gray date">
                                            Publicado {offer.fecha_update}
                                        </p>
                                        <button className="btn-fav" onClick={this.addFav.bind(this)}>
                                            {
                                                this.state.fav ? <i className="fas fa-heart fa-1x"></i> : <i className="far fa-heart fa-1x"></i>
                                            }
                                        </button>
                                    </div>
                                    <div className="white-space-16"></div>
                                    <div className="badges badges-all wrap">
                                        {
                                            keywords ?
                                            (
                                                keywords.map((bagde, key) =>
                                                    (
                                                        <BadgeOffer key={key} name={bagde} />
                                                    )
                                                )
                                            ) :
                                            ( null )
                                        }
                                    </div>
                                    <div className="white-space-8"></div>
                                    <div className="line-divider"></div>
                                    <div className="white-space-8"></div>
                                    <div className="description column">
                                        <p>
                                            <b>DESCRIPCIÓN</b>
                                        </p>
                                            <div className="white-space-8"></div>
                                        <p className="description">
                                            {offer.descripcion}
                                        </p>
                                        <div className="white-space-16"></div>
                                        {
                                            this.state.offer ?
                                            (
                                                this.state.offer.video1 || this.state.offer.video2 ?
                                                <p>
                                                <b>VIDEOS DE EMPRESA</b>
                                                </p>
                                                : null
                                            )
                                                
                                            : null
                                        } 
                               
                                        <div className="white-space-16"></div>
                                        <div className="videos">
                                            
                                        {
                                            this.state.offer ?
                                            (
                                                this.state.offer.video1 ?
                                                    <div className="thumbnail responsive-img justify-center align-center">
                                                    <video id="video1" src={`${this.state.offer.video1}#t=3,4`} width="150" onClick={this.playVideo.bind(this, this.state.offer.video1)}></video> 
                                                        <button className="btn-play" onClick={() => document.getElementById('video1').click()}>
                                                            <i className="far fa-play-circle fa-2x color-lightBlue"></i>
                                                        </button>
                                                    </div>
                                                : null
                                            )
                                               
                                            :
                                            (<img id="video1" src={ThumbNail} alt="Video Placeholder" title="Video Placeholder" />)
                                        }   


                                        {
                                        this.state.offer ?
                                        (
                                         this.state.offer.video2 ?

                                                    <div className="thumbnail responsive-img justify-center align-center">
                                                    <video id="video2" src={`${this.state.offer.video2}#t=3,4`} width="150" onClick={this.playVideo.bind(this, this.state.offer.video2)}></video> 
                                                        <button className="btn-play" onClick={() => document.getElementById('video2').click()}>
                                                            <i className="far fa-play-circle fa-2x color-lightBlue"></i>
                                                        </button>
                                                    </div>
                                                 :  null
                                                 )
                                                    
                                                 :
                                                 (<img id="video1" src={ThumbNail} alt="Video Placeholder" title="Video Placeholder" />)
                                         }    
                                        
                                
                                        </div>
                                        <div className="white-space-16"></div>
                                    </div>
                                </div>
                                <div className="column right-column">
                                <div className="quick-info">
                                        <div className="column">
                                        <div className="row company justify-center">
                                            <div className="logo auto">
                                                <div className="responsive-img responsive-img-logo">
                                                    <ImageLoader src = { image } alt="Imagen de marca" class="logo-img" title="Imagen de marca" loading = { () => <img alt="Imagen de marca" title="Imagen del marca" src = { LoadingImage } /> } />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="white-space-24"></div>
                                        <div className="row location justify-center">
                                            <div className="column padding-left cursor-pointer" onClick = { this.handleProfile.bind(this) }>
                                                <p className="weight-bold text-center">
                                                    EMPRESA
                                                </p>
                                                <p className="color-dimgray name text-center" onClick={this.handleProfile.bind(this)}>
                                                    {offer.nombre}
                                                </p>
                                            </div>
                                            <div className="column padding-left">
                                                <p className="weight-bold text-center">
                                                    LOCACIÓN
                                                </p>
                                                <p className="color-dimgray name text-center">
                                                    {offer.ciudad}
                                                </p>
                                            </div>
                                        </div>
                                        <div className="white-space-24"></div>
                                        <button className="btn btn-large btn-full btn-lightBlue color-darkBlue font-small text-center weight-bold" onClick={this.postularse.bind(this)}>
                                            <i className="fas fa-file icon-btn"></i>
                                            INSCRIBIRSE A ÉSTA OFERTA
                                            {this.state.loading ? <i className="fas fa-spinner fa-spin"></i> : ''}
                                        </button>
                                        <div className="white-space-8"></div>
                                        <button className="btn btn-large btn-full btn-darkBlue color-white font-small text-center weight-bold" onClick={this.handleChat.bind(this)}>
                                            <i className="fas fa-comment font-small icon-btn"></i>
                                            CHAT
                                        </button>
                                        </div>
                                    </div>
                                </div>
                                </div>        
                            </div>
                        </div>
                    </div>
                </MediaQuery>
                
                <MediaQuery maxWidth = { 768 }>
                <Header back = {this.props.context.prevLocation} />
                    <div className="justify-center">
                        <div className="container column">
                            <div className="white-space-16"></div>
                            <h2>
                                {offer.titulo}
                            </h2>
                            <div className="date-fav">
                                <p className="font-mini color-gray date">
                                    Publicado {offer.fecha_update}
                                </p>
                                <button className="btn-fav" onClick={this.addFav.bind(this)}>
                                    {
                                        this.state.fav ? <i className="fas fa-heart fa-1x"></i> : <i className="far fa-heart fa-1x"></i>
                                    }
                                </button>
                            </div>
                            <div className="white-space-8"></div>
                            <div className="quick-info">
                                <div className="company">
                                    <div className="logo auto">
                                        <div className="responsive-img responsive-img-logo">
                                            <ImageLoader src = { image } alt="Imagen de marca" class="logo-img" title="Imagen de marca" loading = { () => <img alt="Imagen de marca" title="Imagen del marca" src = { LoadingImage } /> } />
                                        </div>
                                    </div>
                                    <div className="column padding-left">
                                        <p className="weight-bold">
                                            EMPRESA
                                        </p>
                                        <p className="color-dimgray name">
                                            {offer.nombre}
                                        </p>
                                    </div>
                                </div>
                                <div className="location">
                                    <div className="column padding-left">
                                        <p className="weight-bold">
                                            UBICACIÓN
                                        </p>
                                        <p className="color-dimgray name">
                                            {offer.ciudad}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="white-space-8"></div>
                            <div className="badges badges-all wrap">
                                {
                                    keywords ?
                                    (
                                        keywords.map((bagde, key) =>
                                            (
                                                <BadgeOffer key={key} name={bagde} />
                                            )
                                        )
                                    ) :
                                    ( null )
                                }
                            </div>
                            <div className="white-space-8"></div>
                            <div className="line-divider"></div>
                            <div className="white-space-8"></div>
                            <div className="description column">
                                <p className="description">
                                    {offer.descripcion}
                                </p>
                                <div className="white-space-16"></div>
                                {
                                    this.state.offer ?
                                    (
                                        this.state.offer.video1 || this.state.offer.video2 ?
                                        <p>
                                        <b>VIDEOS DE EMPRESA</b>
                                        </p>
                                        : null
                                    )
                                        
                                    : null
                                } 
                               
                                <div className="white-space-8"></div>
                                <div className="videos">
                                  
                                        {
                                            this.state.offer ?
                                            (
                                                this.state.offer.video1 ?
                                                    <div className="thumbnail responsive-img justify-center align-center">
                                                    <video id="video1" src={`${this.state.offer.video1}#t=3,4`} width="150" onClick={this.playVideo.bind(this, this.state.offer.video1)}></video> 
                                                        <button className="btn-play" onClick={() => document.getElementById('video1').click()}>
                                                            <i className="far fa-play-circle fa-2x color-lightBlue"></i>
                                                        </button>
                                                    </div>
                                                : null
                                            )
                                               
                                            :
                                            (<img id="video1" src={ThumbNail} alt="Video Placeholder" title="Video Placeholder" />)
                                        }   


                                        {
                                        this.state.offer ?
                                        (
                                         this.state.offer.video2 ?

                                                    <div className="thumbnail responsive-img justify-center align-center">
                                                    <video id="video2" src={`${this.state.offer.video2}#t=3,4`} width="150" onClick={this.playVideo.bind(this, this.state.offer.video2)}></video> 
                                                        <button className="btn-play" onClick={() => document.getElementById('video2').click()}>
                                                            <i className="far fa-play-circle fa-2x color-lightBlue"></i>
                                                        </button>
                                                    </div>
                                                 :  null
                                                 )
                                                    
                                                 :
                                                 (<img id="video1" src={ThumbNail} alt="Video Placeholder" title="Video Placeholder" />)
                                         }    
                                        
                                
                                                                    
                                </div>
                                <div className="white-space-24"></div>
                                <button className="btn btn-large btn-full btn-lightBlue color-darkBlue text-center weight-bold" onClick={this.postularse.bind(this)}>
                                    INSCRIBIRSE A ÉSTA OFERTA
                                    {this.state.loading? <i className="fas fa-spinner fa-spin"></i>: ''}
                                </button>
                                <div className="white-space-16"></div>
                            </div>
                        </div>
                    </div>
                </MediaQuery>
                {/**MODAL VIDEO */}
                <div className="modal hidden column align-center justify-center" id="modal-video">
                    <button className="btn auto font-Large" id="close-modal-video" onClick={this.closeModal.bind(this, 'modal-video')}>
                        <i className="fas fa-times color-white"></i>
                    </button>
                    <div className="modal-content">
                        <video id ="video-offer" src={`${this.state.video}`} width="100%" height="100%" controls></video>
                    </div>
                </div>
                {/**MODAL POSTULACION */}
                <div className="modal hidden column" id="modal-postulacion">
                    <div className="modal-content">
                        <div className="column align-center justify-center">
                            <h3 className="text-center uppercase">
                                {this.state.message}
                            </h3>
                            {
                                this.state.description ?
                                (
                                    <p className="text-center">
                                        { this.state.description }
                                    </p>
                                ) : null
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    componentDidMount() {
        this.load();
        this.props.context.mapLocation(window.location.pathname);
    }

    async load() {
        const user = await this.props.context.loadUser();
        if (user) {
            this.setState({ userData: user.id });
        }
        this.getOffer();
    }

    async getOffer() {
        this.setState({ loading: true });
        const data = { id: this.props.match.params.id, id_user: this.state.userData.id };
        const response = await request.post("/offers/get", data);
        if (response && !response.error) {
            if (response.empty) {
                this.setState({ message: response.message, loading: false });
            } else {
                this.setState({ offer: response.offer, fav: response.offer.favorito, loading: false, id_empresa: response.offer.id_empresa });
            }
        } else {
            this.setState({ error: response.error, loading: false });
        }
    }

    playVideo(video) {
        this.setState({video});
        let modal = document.getElementById('modal-video'); // Tu ID
        if (modal) {
            modal.classList.remove('fadeOut');
            modal.classList.add('animated', 'fadeIn','faster');
            modal.classList.remove('hidden'); 
        }
    }

    async postularse() {
        this.setState({loading: true, message: ''});
        const data = {
            user: this.state.userData,
            offer: this.state.offer
        }
        const res = await request.post('/offers/postulate', data);
        this.setState({message: res.message});
        let modal = document.getElementById('modal-postulacion');
        if (modal) {
            modal.classList.remove('fadeOut');
            modal.classList.add('animated', 'fadeIn','faster');
            modal.classList.remove('hidden'); 
        }
        if (res.error) {
            setTimeout(() => {
                modal.classList.remove('fadeIn');
                modal.classList.add('animated', 'fadeOut', 'faster');
                modal.classList.add('hidden');
            }, 3000)
        }
        if(res.postulated) {
            this.setState({ description: 'Recibirás una notificación si la empresa está interesada en tu perfil' });
            setTimeout(()=>{
                this.props.history.push(this.props.context.prevLocation);
            }, 3500)
        }
        this.setState({loading: false});
    }    

    handleChat() {
        this.props.history.push(`/chat/${this.state.id_empresa}`);
    }

    handleProfile() {
        this.props.history.push(`/empresa/${this.state.id_empresa}`);
    }

}

export default withRouter(Consumer(Offer));