/**
 *  interests.jsx
 *  @version: 1.0.0
 *  @author: DWIT MÉXICO - Sergio, kath
 *  @description: Página de selección de interés
*/

import React, { Component } from 'react';
import { HeaderSimple } from '../components/header/header';
import MediaQuery from 'react-responsive';
import Request from '../core/httpClient';
import { Consumer } from '../context';
import { Link } from 'react-router-dom';
import ModalVideoAdd from '../components/modals/AddVideo';
import ModalOfferAdd from '../components/modals/offerAdd';

const req = new Request();

class Categories extends Component {
    constructor(props) {
        super(props);
        this.state = {
            firstList: [], secondList: [], categoria: '', type: 0, list: [], listaIntereses: [], intereses: []
        }
    }

    componentDidMount() {
        this.load();
        if(!this.props.context.registerData) {
            const type = window.localStorage.getItem('registertype');
            if( type.toString() === '1') {
                this.props.history.push('/registro');
            } else {
                this.props.history.push('/registro/empresa');
            }
        }
    }

    async load() {
        const res = await req.get('/areas');
        this.props.context.getList(res);
        if(res.categorias) {
            let list = JSON.parse(window.localStorage.getItem("list"));
                if(list.categorias.length >= 3) {
                const type = JSON.parse(window.localStorage.getItem("registertype"));
                this.setState({ type: type });
                if (type === 1) {
                    this.setState({ firstList: res.categorias[0].funciones });
                    this.setState({ secondList: res.categorias[2].habilidades });
                } else if(type === 2) {
                    this.setState({ firstList: res.categorias[1].sectores });
                    this.setState({ secondList: res.categorias[3].cualidades });
                }
                this.setState({ listaIntereses: list.categorias[4].intereses });
            } else {
                console.log( 'ERROR AL ENLISTAR CATEGORIAS');
                this.setState({message: 'ERROR AL ENLISTAR CATEGORIAS'})
            }
        }
    }

    /*handleClick(categoria) {
        let firstListData = this.state.categorias;
        const idCat = firstListData.findIndex(data => data.name === categoria);
        if (idCat >= 0) {
            firstListData = firstListData.filter((interes, index) => index !== idCat);
        } else {
            firstListData.push({ name: categoria });
        }
        this.setState({ categorias: firstListData });
    }*/

    handleClick(categoria) {
        this.setState({ categoria });
    }

    interestClick(interes) {
        let firstListData = this.state.intereses;
        const idInteres = firstListData.findIndex(data => data.name === interes);
        if (idInteres >= 0) {
            firstListData = firstListData.filter((item, index) => index !== idInteres);
        } else {
            firstListData.push({ name: interes });
        }
        this.setState({ intereses: firstListData });
    }

    saveCategories() {
        if (this.state.categoria === '') {
            alert("SELECCIONA UNA OPCIÓN");
            this.setState({ message: 'SELECCIONA UNA OPCIÓN', loading: false });
            return;
        }

        const registerData = this.props.context.registerData;
        const data = {
            ...registerData,
            Categoria: this.state.categoria
        }

        console.log("data: ", data);
        
        this.props.context.saveRegister(data);
        this.props.history.push('/habilidades');
    }

    render() {
        return (
            <div className="interests column">
                <MediaQuery minDeviceWidth = { 768 }>
                    <div className="view-web column">
                        <header className="header-web-interest justify-center">
                            <div className="container">
                                <div className="header-search align-center">
                                    <div className="row justify-center align-center">
                                        <div className="column auto">
                                            <Link to="/">
                                                <i className="fas fa-angle-left fa-1x color-white back-icon font-regular"></i>
                                            </Link>
                                        </div>
                                        <div className="column">
                                            <h2 className="color-white font-regular">
                                                Selecciona {this.state.type === 1 ? "tu área de especialidad" : "tu sector"}
                                            </h2>
                                        </div>
                                        <div className="column">
                                        <div className="btn-container auto btn-splash justify-end">
                                                <div className="btn-web btn-border-white weight-bold font-small text-center color-white auto" onClick={this.saveCategories.bind(this)} >
                                                Siguiente
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </header>
                        <div className="column justify-center align-center">
                            <div className="container row">
                                <div className="column ">
                                    <div className="white-space-32"></div>
                                    <div className="justify-start interests-options wrap">
                                        {
                                            this.state.firstList.map((interes, index) => {
                                                return (
                                                    <div className={`photo responsive-img ${this.state.categoria === interes.name ? 'active-option' : null}`} key={index} onClick={this.handleClick.bind(this, interes.name) } >
                                                        <div className="tittle align-center justify-center ">
                                                            <h4 className="color-white font-regular text-center">
                                                                { interes.name }
                                                            </h4>
                                                        </div>
                                                        <img  className="img-gallery" src={interes.image ? interes.image : "https://d500.epimg.net/cincodias/imagenes/2016/10/31/sentidos/1477940584_841907_1477940672_noticia_normal.jpg"}  alt="Imagen de categoría" title="Imagen del categoría" />
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </MediaQuery>
                <MediaQuery maxWidth = { 768 }>
                    <HeaderSimple back={"/registro"} />
                    <div className="white-space-24"></div>
                    <div className="justify-center">
                        <div className="container column">
                            <h4 className="text-center weight-bold">
                                Selecciona {this.state.type === 1 ? "tu área de especialidad" : "tu sector"}
                            </h4>
                            <div className="white-space-16"></div>
                            <div className="interests-container wrap justify-between">
                            {
                                this.state.firstList.map((categoria, index) => {
                                    return (
                                        <div className={`interests-element column justify-center align-center ${this.state.categoria === categoria.name ? 'active' : null}`} key={index} onClick={this.handleClick.bind(this, categoria.name)}>
                                            <div className="icon justify-center">
                                                <i className={`fas fa-${categoria.icon} fa-2x color-darkBlue`}></i>
                                            </div>
                                            <div className="white-space-8"></div>
                                            <p className="text-center">
                                                {categoria.name}
                                            </p>
                                        </div>
                                    )
                                })
                            }
                            </div>
                        </div>
                    </div> 
                    <div className="white-space-24"></div>
                    <div className="btn-container justify-center" id="btn-top">
                        <button type="button" className="btn btn-lightBlue btn-square color-darkBlue weight-bold text-center" onClick={this.saveCategories.bind(this)}>
                            SIGUIENTE
                        </button>
                    </div>
                </MediaQuery>
                <ModalVideoAdd></ModalVideoAdd>
            <ModalOfferAdd></ModalOfferAdd>
            </div>
        );
    }
}

export default Consumer(Categories);