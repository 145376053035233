/**
 *  abilities.jsx
 *  @version: 1.0.0
 *  @author: DWIT MÉXICO - Sergio
 *  @description: Página de selección de habilidades
*/

import React, { Component } from 'react';
import { HeaderSimple } from '../components/header/header';
import { Consumer } from '../context';
import { Link } from 'react-router-dom';
import ModalAlert from '../components/modals/profileAlert';
import MediaQuery from 'react-responsive';
import Request from '../core/httpClient';

const req = new Request();

class Abilities extends Component {
    constructor(props) {
        super(props);
        this.state = {
            list: [],
            listaIntereses: [],
            habilidades: [],
            intereses: [],
            type: 0,
            user: { id: 0, tipo: 0 }
        }
        
    }

    componentDidMount() {
        this.loadListData();
        if(!this.props.context.registerData) {
            const type = window.localStorage.getItem('registertype');
            if( type.toString() === '1') {
                this.props.history.push('/registro');
            } else {
                this.props.history.push('/registro/empresa');
            }
        }
    }

    async loadListData() {
        const user = await this.props.context.loadUser();
        if (user) { this.setState({ user: user.id }); }
        const list = JSON.parse(window.localStorage.getItem("list"));
        const type = JSON.parse(window.localStorage.getItem("registertype"));

        this.setState({ type });
        if(type === 1) {
            this.setState({ list: list.categorias[2].habilidades });
        } else if(type === 2) {
            this.setState({ list: list.categorias[3].cualidades });
        }
        this.setState({ listaIntereses: list.categorias[4].intereses });
    }

    handleClick(data, index) {
        const newData = document.getElementById(index).checked;
        let habilidades = this.state.habilidades;
        const idHabilidad = habilidades.findIndex(item => item.name === data);
        if (newData === false && idHabilidad >= 0) {
            habilidades = habilidades.filter((item, index) => index !== idHabilidad);
        } else {
            habilidades.push({ name: data });
        }
        this.setState({habilidades});
    }

    interestClick(interes) {
        let firstListData = this.state.intereses;
        const idInteres = firstListData.findIndex(data => data.name === interes);
        if (idInteres >= 0) {
            firstListData = firstListData.filter((item, index) => index !== idInteres);
        } else {
            firstListData.push({ name: interes });
        }
        this.setState({ intereses: firstListData });
    }

    async registerUser() {
        if (this.state.habilidades.length <= 0) {
            alert("SELECCIONA POR LO MENOS UNA OPCIÓN");
            this.setState({ message: 'SELECCIONA POR LO MENOS UNA OPCIÓN', loading: false });
            return;
        }

        if (this.state.type === 1) {
            if (this.state.intereses.length <= 0) {
                alert("SELECCIONA POR LO MENOS UNA OPCIÓN");
                this.setState({ message: 'SELECCIONA POR LO MENOS UNA OPCIÓN', loading: false });
                return;
            }
        }

        const registerData = this.props.context.registerData;
        if (registerData) {
            this.setState({ loading: true });
            let Experiencia = '';
            const exp = document.getElementById('experience');
            if(exp) {
                Experiencia = exp.value;
            }
            const data = {
                ...registerData,
                Habilidades: JSON.stringify(this.state.habilidades),
                Intereses: JSON.stringify(this.state.intereses),
                Experiencia
            }
            const res = await req.post('/users/register', data);
            if (res) {
                console.log(res)
                if (res.error) {
                    alert(`Error: ${res.message}`);
                    this.setState({ loading: false });
                } else {
                    if(res.register) {
                        this.setState({ loading: false });
                        let modal = document.getElementById('modal-profileAlert');
                        if (modal.classList.contains('hidden')) {
                            modal.classList.remove('fadeOut');
                            modal.classList.add('animated', 'fadeIn');
                            modal.classList.remove('hidden');
                        }
                        setTimeout(() => {
                            this.props.history.push('/');
                        }, 5000);
                    } else {
                        alert("No registro: ", res.message);
                        this.setState({ loading: false });
                    }
                }
            }
        }
    }

    render() {
        return (
            <div className="abilities column">
                <MediaQuery minDeviceWidth = { 768 }>
                    <div className="view-web column">
                        <header className="header-web-interest justify-center">
                            <div className="container">
                                <div className="header-search align-center">
                                    <div className="row justify-center align-center">
                                        <div className="column auto">
                                            <Link to={"/"}>
                                                <i className="fas fa-angle-left fa-1x color-white back-icon font-regular"></i>
                                            </Link>
                                        </div>
                                        <div className="column">
                                            <h2 className="color-white font-regular">
                                                Intereses y cualidades
                                            </h2>
                                        </div>
                                        <div className="column">
                                            <div className="btn-container auto btn-splash justify-end">
                                                <button type="button" className="btn-web btn-border-white weight-bold font-small text-center color-white auto" onClick={this.registerUser.bind(this)}>
                                                    {this.state.loading ? <i className="fas fa-spinner fa-spin"></i> : 'FINALIZAR'}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </header>
                        <div className="column justify-center align-center">
                            <div className="white-space-24"></div>
                            <div className="container row justify-center">
                            {this.state.type === 1 ?
                                <div className="justify-start">
                                    <div className="column">
                                        <h2 className="text-center">
                                            Selecciona tus intereses
                                        </h2>
                                        <div className="white-space-16"></div>
                                        <div className="conteiner-labels column">
                                            <div className="interests-options  wrap justify-arround">
                                                {this.state.listaIntereses.map((item, index) =>

                                                    <div className={`photo responsive-img ${this.state.intereses.find((interes) => interes.name === item.name) ? 'active-option' : null}`} key={index} onClick={this.interestClick.bind(this, item.name)}>
                                                        <div className="tittle align-center justify-center ">
                                                            <h4 className="color-white font-small text-center">
                                                                {item.name}</h4>
                                                        </div>
                                                        
                                                        <img className="img-gallery" src={item.image} alt="Imagen del influencer" title="Imagen del influencer" />
                                                    </div>
                                                )}
                                            </div>
                                            <div className="white-space-64"></div>
                                        </div>
                                    </div>
                                </div> : null }

                                <div className="justify-center column-qualities">
                                    <div className="column">
                                        <h2 className="text-center">
                                            Selecciona tus {this.state.type === 1 ? "habilidades" : "cualidades"}
                                        </h2>
                                        <div className="white-space-16"></div>
                                        <div className="skill-list column">
                                            {this.state.list.map((data, index) =>
                                                <label className="checkbox-container" key={index}>{data.name}
                                                    <input type="checkbox" id={index} onClick={this.handleClick.bind(this, data.name, index)} {...this.state.habilidades.find(inter => inter.name === data.name) ? document.getElementById(index).checked = true : null} />
                                                    <span className="checkmark"></span>
                                                </label>)}
                                        </div>
                                        <div className="white-space-16"></div>
                                        <h2 className="text-center">
                                            Nivel de experiencia
                                        </h2>
                                        <div className="white-space-16"></div>
                                        <select id="experience" className="input">
                                            {this.state.experiencia ? document.getElementById(this.state.experiencia).selected = true : null}
                                            <option id="0-2" value="0-2">0-2 Años</option>
                                            <option id="2-5" value="2-5">2-5 Años</option>
                                            <option id="5+" value="5+">5 y +</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </MediaQuery>
                <MediaQuery maxDeviceWidth = { 768 }>
                    <HeaderSimple back={"/categorias"} />
                    <div className="white-space-32"></div>
                    <div className="justify-center">
                        <div className="container column">
                            <h4 className="text-center weight-bold">
                                Selecciona {this.state.type === 1 ? "habilidades" : "cualidades"}
                            </h4>
                            <div className="white-space-16"></div>
                            <div className="skill-list column">
                                {
                                    this.state.list.map((data, index) =>
                                        <label className="checkbox-container" key={index}>{data.name}
                                            <input type="checkbox" id={index} onClick={this.handleClick.bind(this, data.name, index)} />
                                            <span className="checkmark" ></span>
                                        </label>
                                    )
                                }
                            </div>
                            <div className="white-space-16"></div>
                            <h4 className="text-center weight-bold">
                                Nivel de experiencia
                        </h4>
                            <div className="white-space-16"></div>
                            <select id="experience" name="experience" className="input">
                                <option value="0-2">0-2 Años</option>
                                <option value="2-5">2-5 Años</option>
                                <option value="5+">5 y +</option>
                            </select>
                            <div className="divider"></div>
                        </div>
                    </div>
                    {this.state.type === 1 ?
                    <div className="justify-center">
                        <div className="container column">
                            <div className="conteiner-labels column">
                                <div className="white-space-16"></div>
                                <h4 className="text-center weight-bold">
                                    Intereses
                                </h4>
                                <div className="white-space-16"></div>
                                <div className="soft-skills wrap justify-between">
                                    {
                                        this.state.listaIntereses.map((item, index) =>
                                            <div className={`user-label auto ${this.state.intereses.find((interes) => interes.name === item.name) ? 'active-label' : null}`} key={index} onClick={this.interestClick.bind(this, item.name)}>
                                                <h5 className="font-mini">
                                                    {item.name}
                                                </h5>
                                            </div>
                                        )
                                    }
                                </div>
                                <div className="white-space-64"></div>
                            </div>
                        </div>
                    </div> : null}
                    <div className="btn-container justify-center" id="btn-top">
                        <button className="btn btn-lightBlue btn-square color-darkBlue weight-bold text-center" onClick={this.registerUser.bind(this)} >
                            {this.state.loading ? <i className="fas fa-spinner fa-spin"></i> : 'FINALIZAR REGISTRO'}
                        </button>
                    </div>
                </MediaQuery>
                <ModalAlert title="USUARIO REGISTRADO" description="Por favor active su cuenta por correo" />
            </div>
        );
    }

}

export default Consumer(Abilities);