/**
 *  header.jsx
 *  @version: 1.0.0
 *  @author: DWIT MÉXICO - Kath
 *  @description: Boton agregar una video
*/

import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

class HeaderButtonAddVideo extends Component {

    handleWebAdd() {
        let modalAdd = document.getElementById('modal-video');
        modalAdd.classList.remove('fadeOut');
        modalAdd.classList.add('animated', 'fadeIn','faster');
        modalAdd.classList.remove('hidden');    
    }

    render() {
        return (
            <div className="column auto">
                <div className="row justify-end">
                    <div className="btn-container auto btn-splash justify-center">
                        <button className="btn-web btn-lightBlue font-small  text-center color-darkBlue weight-bold btn-add" onClick={this.handleWebAdd.bind(this)}>
                            <i className="far fa-file-video color-darkBlue icon-add"></i> Añadir vídeo
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(HeaderButtonAddVideo);