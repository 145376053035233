import React, { Component } from "react";
import Req from '../../core/httpClient';
import { request } from "https";

const req = new Req();

class ModalDeleteAccount extends Component {
 
	constructor(props) {
		super(props);
		this.state = {

		}
	}

	componentDidMount() {
		let modalNewsLetter = document.getElementById('modal-delete-account');
		document.getElementById('close-modal-delete-account').onclick = () => {
		modalNewsLetter.classList.add('fadeOut','faster');
			setTimeout(() => {
				modalNewsLetter.classList.add('hidden');
			}, 500);
		}
	}

	async deleteAccount() {
		this.setState({loading: true});
		const data = this.props.userData;
		const res = await req.post('/users/delete/account', data);
		alert(data)
		console.log(res);
		this.setState({message: res.message});
		if(res.deleted) {
			this.props.context.logOut();
        	window.location = "/";
		}
		this.setState({loading: false});
	}

	render() {
		return (    
			<div className="column">       
				<div className="white-space-32"></div>
				<div className="modal justify-center hidden" id="modal-delete-account">
					<div className="modal-content align-center column" >
						<div className="white-space-16"></div>                      
						<div className="column">
							<h4 className="text-center">
								¿Estás seguro de eliminar ésta cuenta?
							</h4>
							<div className="white-space-8"></div>
							<p className="text-center">
								Se eliminarán todos los datos relacionados con esta cuenta (imágenes, chats, historial de pagos)
							</p>
							<div className="white-space-16"></div>
							<div className="modal-controlls">
								<button className="btn btn-square btn-lightBlue color-white weight-semi" id="btn-deleteChat" onClick = { this.deleteAccount.bind(this) } >
									{ this.state.loading? <i className="fas fa-spinner fa-spin"></i> : 'SI' }
								</button>
								<button className="btn btn-square btn-darkBlue color-white weight-semi" id="close-modal-delete-account">
									<i className="fas fa-times color-white"></i> NO
								</button>
							</div>
							{
								this.state.message ?
								(
									<div className="column">
										<div className="white-space-16"></div>
										<div className="column align-center">
											<h4>{this.state.message}</h4> 
										</div>
									</div>
								) : null
							}
						</div>
					</div>
				</div>
			</div>
		);
	}
}


export default ModalDeleteAccount;