/**
 *  chat.jsx
 *  @version: 1.0.0
 *  @author: DWIT MÉXICO - Sergio
 *  @description: Página del Chat activo
 */

import React, { Component } from "react";
import { HeaderChat, HeaderChatSearch } from "../components/header/header";
import HeaderWebHomeLoggedIn from '../components/header/header-desktop';
import BottomControls from "../components/chat/bottomcontrols";
import Messages from "../components/chat/messages";
import socket from "../core/socket";
import Request from "../core/httpClient";
import { Consumer } from '../context';
import LeftBar from '../components/left-bar/left-bar';
import ModalVideoAdd from '../components/modals/AddVideo';
import ModalOfferAdd from '../components/modals/offerAdd';
import MediaQuery from 'react-responsive';
import Chats from '../pages/chats';
import moment from 'moment-timezone';

class Chat extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userData: {id: 0, tipo: 0},
      messages: [],
      room: '',
      recibe: {id:0}
    };
  }

  async loadMessages() {
    const user = await this.props.context.loadUser();
    if(user) {
      this.setState({userData: user.id});
      if(this.props.match.params.id) {
        let room = '';
        if(user.id.tipo === 2) {
          room = `${this.props.match.params.id}:${user.id.id}`;
        } else {
          room = `${user.id.id}:${this.props.match.params.id}`;
        }
        this.setState({room})
      }
    }
    const data = {
      room: this.state.room,
      user: this.state.userData,
      recibe: {id: this.props.match.params.id, tipo: this.state.userData.tipo === 1? 2:1}
    }
    const req = new Request();
    const res = await req.post("/chat/mensajes", data);
    if (res.mensajes) {      
      this.setState({ messages: res.mensajes });
    }
    if(res.recibe) {
      this.setState({recibe: res.recibe})
    }
    //Indica al socket en que chat se encuentra el usuario
    socket.emit('setRoom', { envia: this.state.userData, recibe: this.state.recibe });
  }

  componentDidMount() {
    this.loadMessages();
    //Recibe los mensajes del chat
    socket.on("chat", message => {
      //console.log(message);
      const messages = this.state.messages;
      messages.push(message);
      this.setState({ messages });
    });
    this.props.context.mapLocation(window.location.pathname)
  }

  handleSubmit(event) {
    event.preventDefault();
    const form = event.target;
    if(form.mensaje.value.length > 0 && form.mensaje.value !== '' && !(/^\s+$/.test(form.mensaje.value))) {
        socket.emit('chat', { 
            id: this.state.userData.id, 
            mensaje: form.mensaje.value,
            room: this.state.room,
            tipo: this.state.userData.tipo,
            visto: 0
        });
        form.mensaje.value = '';
    }
  }

  sendImage() {
    const upload = document.createElement('input');
    upload.type = 'file';
    upload.click();
    upload.onchange = this.uploadImage.bind(this, upload);
  }

  uploadImage(event) {
    let resizedImage = "";
    const src = event;
    let fr = new FileReader();
    fr.onload = function(e) {
        const image = new Image();
        image.onload = function() {
            const canvas = document.createElement("canvas");
            const max_size = 600;
            let width = image.width;
            let height = image.height;
            if (width > height) {
                if (width > max_size) {
                    height *= max_size / width;
                    width = max_size;
                }
            } else {
                if (height > max_size) {
                    width *= max_size / height;
                    height = max_size;
                }
            }
            canvas.width = width;
            canvas.height = height;
            canvas.getContext("2d").drawImage(image, 0, 0, width, height);
            resizedImage = canvas.toDataURL("image/png");
            this.uploadImageFirebase(resizedImage);
        }.bind(this)
        image.src = e.target.result;
    }.bind(this)
    if (src.files.length > 0) {
        fr.readAsDataURL(src.files[0]);
    }
  }

  async uploadImageFirebase(image) {
    const storageRef = window.firebase.storage().ref();
    let fileName = `${this.state.room}/${moment().format('YYYYMMDD_hhmmss')}`;
    const ref = storageRef.child(`chat/${fileName}`);
    const imageURL = await ref.putString(image, 'data_url').then(async snapshot => {
        const url = await snapshot.ref.getDownloadURL().then(downloadURL => {
               return downloadURL;
            }
        );
        return url
    });
    socket.emit('chat', {
        id:this.state.userData.id,
        tipo: this.state.userData.tipo,
        mensaje: imageURL,
        imagen: 1,
        visto: 0,
        room: this.state.room
    });
}

openBuscar() {
  const head = document.getElementById('chat-search');
  if(head){
      head.classList.toggle("hidden");
  }
}

buscar(event) {
  event.preventDefault();
  var name = event.target.mensaje.value;
  var pattern = name.toLowerCase();
  var targetId = "";

  var divs = document.getElementsByClassName("message");
  for (var i = 0; i < divs.length; i++) {
     var para = divs[i].getElementsByTagName("p");
     var index = para[0].innerText.toLowerCase().indexOf(pattern);
     if (index != -1) {
        targetId = divs[i].id;
        console.log(divs[i]);
        document.getElementById(targetId).scrollIntoView();
        break;
     }
  }
  const head = document.getElementById('chat-search');
  if(head){
      head.classList.toggle("hidden");
  }
}

  render() {
    return (
      <div className="chat column">
            <MediaQuery minDeviceWidth = { 768 }>
                <div className="view-web column">
                <HeaderWebHomeLoggedIn/>
                    <div className="row justify-center">
                        <LeftBar active = "menuChats"/>
                        <div className="column align-center main-chat-column">
                        <div className="white-space-32"></div>
                        <div className="row container">
                        <div className="content-chat-list">
                        <Chats origen="chat"></Chats>
                        </div>
                        <div className="column chat-column chat-content">
                        <HeaderChat user={this.state.recibe} prevLocation={this.props.context.prevLocation} openBuscar={this.openBuscar.bind(this)}/>
                       
                        <div className="justify-center">
                            <div id="chat_messages_content" className="container content-chat" style={{overflowY: 'auto'}}>
                                {
                                    this.state.loading ?
                                    (
                                        <div className="column">
                                            <div className="white-space-16"></div>
                                            <div className="justify-center">
                                                <i className="fas fa-spin fa-2x fa-spinner color-purple"></i>
                                            </div>
                                        </div>
                                    )
                                    :
                                    (
                                      <Messages 
                                      messages={this.state.messages}
                                      sId={this.state.userData.id}
                                      userData={this.state.userData}
                                      room={this.state.room}
                                      recibe={this.state.recibe}/>
                                    )
                                }
                            </div>
                        </div>
                        <BottomControls submit={this.handleSubmit.bind(this)} sendImage={this.sendImage.bind(this)}/>
                        </div>
                      
                        </div>
                        <div className="white-space-32"></div>
                        </div>
                    </div>
                </div>
                </MediaQuery>
            <MediaQuery maxWidth = { 768 }>
            <HeaderChat user={this.state.recibe} prevLocation={this.props.context.prevLocation} prevLoc={this.props.context.prevLoc} openBuscar={this.openBuscar.bind(this)}/>
            <div className="justify-center">
              <div className="container content-chat">
                <Messages 
                  messages={this.state.messages}
                  sId={this.state.userData.id}
                  userData={this.state.userData}
                  room={this.state.room}
                  recibe={this.state.recibe}/>
              </div>
            </div>
            <BottomControls submit={this.handleSubmit.bind(this)} sendImage={this.sendImage.bind(this)}/>
        </MediaQuery>
        <ModalVideoAdd></ModalVideoAdd>
            <ModalOfferAdd></ModalOfferAdd>
      </div>
    );
  }
}

export default Consumer(Chat);
