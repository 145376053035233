/**
 *  header.jsx
 *  @version: 1.0.0
 *  @author: DWIT MÉXICO - Kath
 *  @description: Boton agregar una video
*/

import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

class HeaderButtonSearch extends Component {

    handleToSearch() {
        this.props.history.push(`/buscar/?search=`);
    }

    render() {
        return (
            <div className="column auto">
                <div className="row justify-end">
                    <div className="btn-container auto btn-splash justify-center">
                        <button className="btn-web btn-lightBlue font-small text-center color-lightBlue weight-bold btn-search" onClick={this.handleToSearch.bind(this)}>
                            <i className="fas fa-search icon-add"></i>Buscar
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(HeaderButtonSearch);