/**
 *  job.jsx
 *  @version: 1.0.0
 *  @author: DWIT MÉXICO - Sergio
 *  @description: Cards de ofertas
*/

import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Consumer } from '../../context';
import shave from 'shave';

class CardJob extends Component {

    passVariable() {
        this.props.history.push(`/oferta/${this.props.oferta.id_oferta}`);
    }

    render() {
        let titulo = '', nombre = '', ciudad = '', imagen = '', salario = '';
        if (this.props.oferta) {
            titulo = this.props.oferta.titulo;
            nombre = this.props.oferta.nombre;
            ciudad = this.props.oferta.ciudad;
            imagen = this.props.oferta.imagen;
            salario = this.props.oferta.salario;
            shave('.card-name', 22);
        }
        return (
            <div className="card-job">
                <div className="head">
                    <div className="logo responsive-img align-center cursor-pointer" onClick={this.passVariable.bind(this)}>
                        <img src={imagen} alt="HP Logo" title="HP Logo"/>
                    </div>
                </div>
                <div className="info column cursor-pointer">
                    <div className="title column" onClick={this.passVariable.bind(this)}>
                        <h4 className="font-regular padding card-name">
                            {titulo}
                        </h4>
                        <div className="white-space-4"></div>
                        <p className="padding font-mini color-darkgray" onClick={this.passVariable.bind(this)}>
                            Por: { nombre }
                        </p>
                    </div>
                    <div className="align-center padding" onClick={this.passVariable.bind(this)}>
                        <i className="far fa-compass font-mini"></i>&nbsp; 
                        <p className="font-mini">
                            { ciudad }
                        </p>
                    </div>
                    <div className="white-space-4"></div>
                    <div className="align-center padding" onClick={this.passVariable.bind(this)}>
                        <i className="fas fa-euro-sign font-mini"></i>&nbsp;
                        <p className="font-mini">
                            { salario ? salario : 'A convenir' }
                        </p>
                    </div>
                    {
                        this.props.type === "profile" ?
                        (
                            <div className="white-space-4"></div>
                        ) :
                        (
                                <div className="contact justify-center">
                                    <button type="button" className="btn-play weight-semi" onClick={this.handleCompany.bind(this)}>
                                        <i className="fas fa-reply"></i>&nbsp; VER EMPRESA
                                    </button>
                                </div>
                        )
                    }
                </div>
            </div>
        );
    }

    componentDidMount() {
        shave('.card-name', 22);
    }

    handleCompany() {
        this.props.history.push(`/empresa/${this.props.oferta.id_empresa}`);
    }

}

export default withRouter(Consumer(CardJob));