/**
 *  conformation.jsx
 *  @version: 1.0.0
 *  @author: DWIT MÉXICO - Kath
 *  @description: MODAL AGREGAR CURRICULUM
*/

import React, { Component } from 'react';
import { Consumer } from '../../context';
import Request from "../../core/httpClient";
const request = new Request();


class AddCurriculum extends Component {

    constructor(props) {
        super(props);
        this.state = {
            userData: { id: 0, tipo: 0 }, file: ''
        }
    }

    render() {
		
        return (
            <div className="modal modal-confirm column justify-center align-center hidden" id="modal-Curriculum">
                <div className="container modal-content align-center column" >
                    <button type="button" className="btn auto" onClick = { this.handleClose.bind(this) }>
					    <i className="fas fa-times font-medium" id="close-modal"></i>
					</button>

                    <div className="upload-summary column">

                    <div className="white-space-64"></div>
                    <form className="content column justify-center align-center" onSubmit = { this.handleSubmit.bind(this) }>
                        <div className="icon justify-center">
                            <i className="fas fa-upload color-darkBlue fa-2x"></i>
                        </div>
                        <div className="white-space-16"></div>
                        <div className="btn-container justify-center">
                            {
                                this.state.loading ? 
                                (
                                    <div className="justify-center">
                                        <i className="fas fa-spin fa-spinner color-darkBlue"></i>
                                    </div>
                                )
                                :
                                (
                                    this.state.file ?
                                    (
                                        <div className="column">
                                            <div className="justify-center">
                                                <p className="color-darkBlue">
                                                    { this.state.file.name }
                                                </p>
                                            </div>
                                            <div className="white-space-8"></div>
                                            <div className="justify-center">
                                                <button type="submit" className="btn btn-radius btn-large btn-darkBlue weight-bold color-white">
                                                    CARGAR CV
                                                </button>
                                            </div>
                                            <div className="white-space-8"></div>
                                            <div className="justify-center">
                                                <div className="divider"></div>
                                            </div>
                                            <div className="white-space-8"></div>
                                            <div className="justify-center">
                                                <p className="color-darkBlue font-mini" onClick = { this.handleRemplace.bind(this) }>
                                                    Cambiar documento
                                                </p>
                                            </div>
                                        </div>
                                    )
                                    :
                                    (
                                        <button type="button" className="btn btn-radius btn-large btn-darkBlue weight-bold color-white" onClick={this.handleFile.bind(this)}>
                                            SELECCIONAR CV
                                        </button>
                                    )
                                )
                            }
                            <input type="file" name="cv" className="hidden" id="input-cv" accept="application/msword, text/plain, application/pdf" onChange = { this.handleChange.bind(this) } />
                        </div>
                    </form>
                    <div className="white-space-64"></div>
                     </div>
                    
                    </div>
                </div>
        );
    }

    handleClose() {
		let modalAdd = document.getElementById('modal-Curriculum');
		modalAdd.classList.add('fadeOut');
		setTimeout(() => {
			modalAdd.classList.add('hidden');
		}, 500);
    }
    
    componentDidMount() {
        this.load();
    }
	
    async load() {
        const user = await this.props.context.loadUser();
        if (user) { this.setState({ userData: user.id }); }
    }

    handleFile() {
        document.getElementById('input-cv').click();
    }

    handleRemplace() {
        this.setState({ file: '' });
    }

    handleChange(event) {
        const file = event.target;
        if (file.files[0].type === 'application/msword' || file.files[0].type === 'text/plain' || file.files[0].type === 'application/pdf' ) {
            this.setState({ file: file.files[0] });
        } else {
            this.setState({ file: false });
            alert("TIPO DE DOCUMENTO NO ADMITIDO");
        }
    }

    async handleSubmit(event) {
        event.preventDefault();
        this.setState({ loading: true });
        const form = event.target;
        const storageRef = window.firebase.storage().ref();
        let dateTime = new Date();
        let fileName = dateTime.getFullYear() + "-" + (dateTime.getMonth() + 1) + "-" + dateTime.getDate() + "-" + dateTime.getHours() + "/" + dateTime.getMinutes() + ":" + dateTime.getSeconds() + ":" + dateTime.getMilliseconds();
        const ref = storageRef.child(`resumes/${fileName}`);
        const resumeURL = await ref.put(form.cv.files[0]).then(async snapshot => {
            const url = await snapshot.ref.getDownloadURL().then(downloadURL => {
                return downloadURL;
            });
            return url
        });
        const data = { id: this.state.userData.id, url: resumeURL };
        const response = await request.post("/candidates/upload/cv", data);
        if (response.error) {
            this.setState({ message: response.message });
        } else {
            if (response.update) {
                this.setState({ message: response.message, loading: false });
                setTimeout(() => {
                    if (this.state.userData.tipo.toString() === "1") {
                        window.location = "/perfil/candidato"
                    } 
                }, 1500);
            }
        }
    }
  
}

export default Consumer(AddCurriculum);