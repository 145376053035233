/**
 *  home.jsx
 *  @version: 1.0.0
 *  @author: DWIT MÉXICO - Sergio
 *  @description: Página inicial tras Login
*/

import React, { Component } from 'react';
import Header from '../components/header/header';
import HeaderWebHomeLoggedIn from '../components/header/header-desktop';
import LeftBar from '../components/left-bar/left-bar';
import CardVideo from '../components/cards/video';
import CardJob from '../components/cards/job';
import CardCompany from '../components/cards/company';
import CardCandidates from '../components/cards/candidates';
import MediaQuery from 'react-responsive';
import Tabs from '../components/tabs/tabs';
import { Consumer } from '../context';
import Request from "../core/httpClient";
import ModalOfferAdd from '../components/modals/offerAdd';
import ModalVideoAdd from '../components/modals/AddVideo';
const request = new Request();

class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: { id: 0, tipo: 0 }, cards: [],  tab: 'first',
            elements: [], items: [], numItems: 3, numItemsE: 11,
            loadingState: false, loadingScroll: false, first: true,
            loading: false, cardsE: []
        }
    }
    
    render() {
        return (
            <div className="home column">
                <MediaQuery minDeviceWidth = { 768 }>
                <div className="view-web column">
                    <HeaderWebHomeLoggedIn />
                    <div className="row">
                        <LeftBar active = "menuHome"/>
                        <div className="column">
                            <div className="row justify-center">
                                <div className="cards-container container  column align-center justify-center">
                                    <div className="white-space-32"></div>
                                    <Tabs tab={this.state.tab} first="Videos" second={this.state.user.tipo === 1 ? "Ofertas" : "Candidatos"} third={this.state.user.tipo === 1 ? "Empresas" : "Freelancers"} user={this.state.user} changeTab={this.changeTab.bind(this)} />
                                    <div className="white-space-16"></div>
                                    {this.state.loadingState ?
                                        <div className="justify-center">
                                            <i className="fas fa-spin fa-spinner"></i>
                                        </div> : ""}
                                    {this.state.empty ?
                                        <div className="column align-center">
                                            <div className="white-space-16"></div>
                                            <h3 className="text-center">
                                                No hay resultados.
                                            </h3>
                                        </div>
                                        :
                                        <div className="scrollE justify-center" onScroll={this.scrollItems.bind(this)}>
                                            <div className={this.state.tab === 'first'? "row list-videos wrap" : "row list-cards wrap" }>
                                                {this.state.firstE ? this.state.cardsE : this.displayItemsE()}
                                            </div>
                                        </div>}
                                    <div className="white-space-8"></div>
                                    {this.state.loadingScrollE ?
                                        <div className="justify-center">
                                            <i className="fas fa-spin fa-spinner"></i>
                                        </div> : ""}
                                    <div className="white-space-32"></div>
                                </div>
                            </div>                           
                        </div>
                        <ModalOfferAdd></ModalOfferAdd>
                        <ModalVideoAdd></ModalVideoAdd>
                    </div>
                </div>
                </MediaQuery>
                <MediaQuery maxWidth = { 768 }>
                    <div className="header-sticky column">
                    <Header />
                    <Tabs tab = { this.state.tab } first="Videos" second={this.state.user.tipo === 1 ? "Ofertas" : "Candidatos"} third={this.state.user.tipo === 1 ? "Empresas" : "Freelancers"} user = { this.state.user } changeTab = { this.changeTab.bind(this) } />
                    </div>

                    <div className="white-space-8"></div>
                    {this.state.loadingState ?
                    <div className="justify-center">
                        <i className="fas fa-spin fa-spinner"></i>
                    </div> : ""}
                    {this.state.empty ?
                        <div className="column align-center">
                            <div className="white-space-16"></div>
                            <h3 className="text-center">
                                No hay resultados.
                            </h3>
                        </div>
                        :
                        <div className="justify-center">
                            <div className="vc container column" ref="iScroll">
                                {this.state.first ? this.state.cards : this.displayItems()}
                            </div>
                        </div>}
                    <div className="white-space-8"></div>
                    {this.state.loadingScroll ? 
                    <div className="justify-center">
                        <i className="fas fa-spin fa-spinner"></i>  
                    </div> : ""}
                </MediaQuery>
            </div>
        );
    }

    componentDidMount() {
        this.load();
        if (this.refs.iScroll) {
            this.refs.iScroll.addEventListener("scroll", () => {
                if (this.refs.iScroll.scrollTop + this.refs.iScroll.clientHeight >= this.refs.iScroll.scrollHeight) {
                    this.loadMoreItems();
                    this.setState({ first: false });
                }
            });
        }
        if(window.localStorage.getItem('gow_htab')) {
            this.setState({tab: window.localStorage.getItem('gow_htab')})
        }
        this.props.context.mapLocation(window.location.pathname)
    }

    scrollItems(event) {
        if (event.target.scrollTop + event.target.clientHeight >= event.target.scrollHeight) {
            this.loadMoreItemsE();
            this.setState({ firstE: false });
        }
    }

    displayItems() {
        var items = [];
        if (this.state.tab === 'first') {
            for (let i = 0; i < this.state.numItems + 1; i++) {
                if (this.state.elements[i]) {
                    items.push(
                        <CardVideo key={this.state.elements[i].id_video} video={this.state.elements[i]} />
                    );
                }
            }
        } else if (this.state.tab === 'second') {
            if (this.state.user.tipo === 1) {
                for (let i = 0; i < this.state.numItems + 1; i++) {
                    if (this.state.elements[i]) {
                        items.push(
                            <CardJob key={this.state.elements[i].id_oferta} oferta={this.state.elements[i]} />
                        );
                    }
                }
            } else {
                for (let i = 0; i < this.state.numItems + 2; i++) {
                    if (this.state.elements[i]) {
                        items.push(                            
                            <CardCandidates key={this.state.elements[i].id_candidato} candidato={this.state.elements[i]} />
                        );
                    }
                }
            }
        } else {
            if (this.state.user.tipo === 1) {
                for (let i = 0; i < this.state.numItems + 1; i++) {
                    if (this.state.elements[i]) {
                        items.push(
                            <CardCompany key={this.state.elements[i].id_empresa} empresa={this.state.elements[i]} />
                        );
                    }
                }
            } else {
                for (let i = 0; i < this.state.numItems + 2; i++) {
                    if (this.state.elements[i]) {
                        items.push(
                            <CardCandidates key={this.state.elements[i].id_candidato} candidato={this.state.elements[i]} />
                        );
                    }
                }
            }
        }
        return items;
    }

    loadMoreItems() {
        if (this.state.numItems <= this.state.elements.length) {
            this.setState({ loadingScroll: true });
            setTimeout(() => {
                this.setState({ numItems: this.state.numItems + 4, loadingScroll: false });
            }, 3000);
        }
    }

    displayItemsE() {
        var items = [];
        if (this.state.tab === 'first') {
            for (let i = 0; i < this.state.numItemsE + 1; i++) {
                if (this.state.elements[i]) {
                    items.push(
                        <div key={this.state.elements[i].id_video}>
                            <CardVideo video={this.state.elements[i]} />
                        </div>);
                }
            }
        } else if (this.state.tab === 'second') {
            if (this.state.user.tipo === 1) {
                for (let i = 0; i < this.state.numItemsE + 1; i++) {
                    if (this.state.elements[i]) {
                        items.push(
                            <div key={this.state.elements[i].id_oferta}>
                                <CardJob oferta={this.state.elements[i]} />
                            </div>);
                    }
                }
            } else {
                for (let i = 0; i < this.state.numItemsE + 1; i++) {
                    if (this.state.elements[i]) {
                        items.push(
                            <div key={this.state.elements[i].id_candidato}>
                                <CardCandidates candidato={this.state.elements[i]} />
                            </div>);
                    }
                }
            }
        } else {
            if (this.state.user.tipo === 1) {
                for (let i = 0; i < this.state.numItemsE + 1; i++) {
                    if (this.state.elements[i]) {
                        items.push(
                            <div key={this.state.elements[i].id_empresa}>
                                <CardCompany empresa={this.state.elements[i]} />
                            </div>);
                    }
                }
            } else {
                for (let i = 0; i < this.state.numItemsE + 1; i++) {
                    if (this.state.elements[i]) {
                        items.push(
                            <div key={this.state.elements[i].id_candidato}>
                                <CardCandidates candidato={this.state.elements[i]} />
                            </div>);
                    }
                }
            }
        }
        return items;
    }

    loadMoreItemsE() {
        if (this.state.numItemsE <= this.state.elements.length) {
            this.setState({ loadingScrollE: true });
            setTimeout(() => {
                this.setState({ numItemsE: this.state.numItemsE + 12, loadingScrollE: false });
            }, 3000);
        }
    }

    changeTab(tab) {
        window.localStorage.setItem('gow_htab', tab);
        this.setState({ tab, cards: [], cardsE: [], first: true, firstE: true, numItems: 3, numItemsE: 11, elements: [], items: [] });
        this.load();
    }

    async load() {
        this.setState({ loading: true });
        const user = await this.props.context.loadUser();
        if (user) { this.setState({ user: user.id }); }

        let list = [], secondList = [];

        if (this.state.tab === 'first') {
            this.setState({ loadingState: true, empty: false });
            const data = { id: user.id.id, tipo: user.id.tipo };
            const videosData = await request.post('/videos/allvideos', data);
            if (videosData) {
                this.setState({ message: videosData.message });
                if (videosData.empty) {
                    this.setState({ empty: videosData.empty, loadingState: false });
                } else {
                    if (videosData.video) {
                        const dataVideo = videosData.video.slice(0, 4).map((item) => item);
                        list = dataVideo;
                        const twelveVideos = videosData.video.slice(0, 12).map((item) => item);
                        secondList = twelveVideos;
                        this.setState({ elements: videosData.video, empty: false, items: dataVideo, loadingState: false });
                    }
                }
            }
            const videos = list.map((item, index) => <CardVideo key={index} video={item} />);
            const videosT = secondList.map((item, index) => <CardVideo key={index} video={item} />);
            this.setState({ cards: videos, cardsE: videosT });
        } else if (this.state.tab === 'second') {
            this.setState({ loadingState: true, empty: false });
            if (user.id.tipo === 1) {
                const offersData = await request.post('/offers/getAll');
                if (offersData) {
                    this.setState({ message: offersData.message });
                    if (offersData.empty) {
                        this.setState({ empty: offersData.empty, loadingState: false });
                    } else {
                        if (offersData.offers) {
                            const dataOffers = offersData.offers.slice(0, 4).map((item) => item);
                            list = dataOffers;
                            const twelveVideos = offersData.offers.slice(0, 12).map((item) => item);
                            secondList = twelveVideos;
                            this.setState({ elements: offersData.offers, empty: false, items: dataOffers, loadingState: false });
                        }
                    }
                }
                const offers = list.map((item, index) => <CardJob key={index} oferta={item} />);
                const offersT = secondList.map((item, index) => <CardJob key={index} oferta={item} />);
                this.setState({ cards: offers, cardsE: offersT });
            } else {
                const data = { tipo: 0 };
                const candidatesData = await request.post('/candidates/getAll', data);
                if (candidatesData) {
                    this.setState({ message: candidatesData.message });
                    if (candidatesData.empty) {
                        this.setState({ empty: candidatesData.empty, loadingState: false });
                    } else {
                        if (candidatesData.candidates) {
                            const dataCandidates = candidatesData.candidates.slice(0, 5).map((item) => item);
                            list = dataCandidates;
                            const twelveVideos = candidatesData.candidates.slice(0, 12).map((item) => item);
                            secondList = twelveVideos;
                            this.setState({ elements: candidatesData.candidates, empty: false, items: dataCandidates, loadingState: false });
                        }
                    }
                }
                const candidates = list.map((item, index) => <CardCandidates key={index} candidato={item} />);
                const candidatesT = secondList.map((item, index) => <CardCandidates key={index} candidato={item} />);
                this.setState({ cards: candidates, cardsE: candidatesT });
            }
        } else {
            this.setState({ loadingState: true, empty: false });
            if (user.id.tipo === 1) {
                const enterprisesData = await request.post('/enterprises/getAll');
                if (enterprisesData) {
                    this.setState({ message: enterprisesData.message });
                    if (enterprisesData.empty) {
                        this.setState({ empty: enterprisesData.empty, loadingState: false });
                    } else {
                        if (enterprisesData.enterprises) {
                            const dataEnterprises = enterprisesData.enterprises.slice(0, 4).map((item) => item);
                            list = dataEnterprises;
                            const twelveVideos = enterprisesData.enterprises.slice(0, 12).map((item) => item);
                            secondList = twelveVideos;
                            this.setState({ elements: enterprisesData.enterprises, empty: false, items: dataEnterprises, loadingState: false });
                        }
                    }
                }
                const enterprises = list.map((item, index) => <CardCompany key={index} empresa={item} />);
                const enterprisesT = secondList.map((item, index) => <CardCompany key={index} empresa={item} />);
                this.setState({ cards: enterprises, cardsE: enterprisesT });
            } else {
                const data = { tipo: 1 };
                const candidatesData = await request.post('/candidates/getAll', data);
                if (candidatesData) {
                    this.setState({ message: candidatesData.message });
                    if (candidatesData.empty) {
                        this.setState({ empty: candidatesData.empty, loadingState: false });
                    } else {
                        if (candidatesData.candidates) {
                            const dataCandidates = candidatesData.candidates.slice(0, 5).map((item) => item);
                            list = dataCandidates;
                            const twelveVideos = candidatesData.candidates.slice(0, 12).map((item) => item);
                            secondList = twelveVideos;
                            this.setState({ elements: candidatesData.candidates, empty: false, items: dataCandidates, loadingState: false });
                        }
                    }
                }
                const candidates = list.map((item, index) => <CardCandidates key={index} candidato={item} />);
                const candidatesT = secondList.map((item, index) => <CardCandidates key={index} candidato={item} />);
                this.setState({ cards: candidates, cardsE: candidatesT });
            }
        }
    }
}

export default Consumer(Home);