/**
 *  index.js
 *  @version: 1.0.0
 *  @author: DWIT MÉXICO
 *  @description: Manejador principal del estado global de la aplicación
 */

import React, { Component, createContext } from "react";
import { loadUser, saveRegister, logIn, logOut, getList, interestUpdated, variableVideo, setDeleteItem,setEditVideo, setOfferCandidates } from "./users";
import { mapLocation, quitLocation } from './location';

// Instancia del Context, métodos: Provider y Consumer

const Context = createContext();

class GlobalContext extends Component {
	
	state = {
		edit: false,
		logIn: logIn.bind(this),
		logOut: logOut.bind(this),
		saveRegister: saveRegister.bind(this),
		loadUser: loadUser.bind(this),
		getList: getList.bind(this),
		interestUpdated: interestUpdated.bind(this),
		variableVideo: variableVideo.bind(this),
		setDeleteItem: setDeleteItem.bind(this),
		setEditVideo: setEditVideo.bind(this),
		setOfferCandidates: setOfferCandidates.bind(this),
		mapLocation: mapLocation.bind(this),
		quitLocation: quitLocation.bind(this)
	};

	componentDidMount() {
		this.state.loadUser();
	}

	render() {
		return (
			<Context.Provider value={this.state}>
				{this.props.children}
			</Context.Provider>
		);
	}
}

/**
 * @function: Consumer
 * @description: HOC conector entre el estado global y un componente consumidor.
 * @param: Component => Componente Web
 *
 */

const Consumer = Component => {
	return props => {
		return (
			<Context.Consumer>
				{ context => <Component { ...props } context = { context } /> }
			</Context.Consumer>
		);
	};
};

export { Consumer, GlobalContext };
