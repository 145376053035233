/**
 *  favorites.jsx
 *  @version: 1.0.0
 *  @author: DWIT MÉXICO - Sergio
 *  @description: Componente para Header Tabs
*/

import React, { Component, Fragment } from 'react';
import { Consumer } from '../../context';

class Tabs extends Component {
    
    render() {
        let tipo = 0, user = '';
        if (this.props.user) {
            user = this.props.user;
        }

        if (user) {
            tipo = user.tipo ? user.tipo.toString() : '0';
        }
        
        return (
            <div className="tabs-menu justify-center">
                <div>
                    {
                        tipo === "1" ?
                        <Fragment>
                            <div className={"videos column " + (this.props.tab === 'first' ? ' tab-active' : '')} onClick={this.props.changeTab.bind(this, 'first')}>
                                <div className="icon justify-center">
                                    <i className="fas fa-video color-darkBlue"></i>
                                </div>
                                <div className="title justify-center">
                                    <button className="color-darkBlue font-mini">
                                        { this.props.first }
                                    </button>
                                </div>
                            </div>
                            <div className={"jobs column" + (this.props.tab === 'second' ? ' tab-active' : '')} onClick={this.props.changeTab.bind(this, 'second')}>
                                <div className="icon justify-center">
                                    <i className="fas fa-mail-bulk color-darkBlue"></i>
                                </div>
                                <div className="title justify-center">
                                    <button className="color-darkBlue font-mini">
                                        { this.props.second }
                                    </button>                        
                                </div>
                            </div>
                            <div className={"companies column " + (this.props.tab === 'third' ? ' tab-active' : '')} onClick={this.props.changeTab.bind(this, 'third')}>
                                <div className="icon justify-center">
                                    <i className="fas fa-warehouse color-darkBlue"></i>
                                </div>
                                <div className="title justify-center">
                                    <button className="color-darkBlue font-mini">
                                        { this.props.third }
                                    </button>                        
                                </div>
                            </div>
                        </Fragment>
                        :
                        <Fragment>
                            <div className={"videos column " + (this.props.tab === 'first' ? ' tab-active' : '')} onClick={this.props.changeTab.bind(this, 'first')}>
                                <div className="icon justify-center">
                                    <i className="fas fa-video color-darkBlue"></i>
                                </div>
                                <div className="title justify-center">
                                    <button className="color-darkBlue font-mini">
                                        {this.props.first}
                                    </button>
                                </div>
                            </div>
                            <div className={"jobs column" + (this.props.tab === 'second' ? ' tab-active' : '')} onClick={this.props.changeTab.bind(this, 'second')}>
                                <div className="icon justify-center">
                                    <i className="fas fa-mail-bulk color-darkBlue"></i>
                                </div>
                                <div className="title justify-center">
                                    <button className="color-darkBlue font-mini">
                                        {this.props.second}
                                    </button>
                                </div>
                            </div>
                            <div className={"companies column " + (this.props.tab === 'third' ? ' tab-active' : '')} onClick={this.props.changeTab.bind(this, 'third')}>
                                <div className="icon justify-center">
                                    <i className="fas fa-warehouse color-darkBlue"></i>
                                </div>
                                <div className="title justify-center">
                                    <button className="color-darkBlue font-mini">
                                        {this.props.third}
                                    </button>
                                </div>
                            </div>
                        </Fragment>
                    }
                </div>
            </div>
        );
    }

}

export default Consumer(Tabs);