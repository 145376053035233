/**
 *  routes.jsx
 *  @version: 1.0.0
 *  @author: DWIT MÉXICO - Sergio
 *  @description: Manejo de todas las rutas de la aplicación
 * 	@process: 4
 */

import React, { Component } from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

// Conexión con el Context API
import { GlobalContext } from "../context";

// Páginas Web
import Splash from '../pages/splash';
import Login from '../pages/login';
import Register from '../pages/register';
import RegisterEnterprise from '../pages/register-enterprise';
import Categories from '../pages/categories';
import CategoriesEdit from '../pages/categories-edit';
import Abilities from '../pages/abilities';
import AbilitiesEdit from '../pages/abilities-edit';
import Settings from '../pages/settings';
import SettingsLegality from '../pages/settings-legality';
import SettingsSecurity from '../pages/settings-security';
import ProfileEditCandidate from '../pages/profile-edit-candidate';
import ProfileEditEnterprise from '../pages/profile-enterprise-edit';
import Home from '../pages/home';
import Search from '../pages/search';
import Password from '../pages/password';
import BlockedAccounts from '../pages/blocked-accounts';
import Chats from "../pages/chats";
import Chat from "../pages/chat";
import UploadSummary from '../pages/upload-summary';
import UploadVideo from '../pages/upload-video';
import UploadVideoCurriculum from '../pages/upload-video-curriculum';
import Video from '../pages/video';
import EditVideo from '../pages/edit-video';
import Favorites from '../pages/favorites';
import Offer from '../pages/offer';
import MyOffers from '../pages/my-offers';
import OfferAdd from '../pages/offer-add';
import OfferEdit from '../pages/offer-edit';
import Candidates from '../pages/candidates';
import Postulations from '../pages/postulations';
import PublicProfileCandidate from '../pages/profile-public-candidate';
import PublicProfileBrand from '../pages/profile-public-brands';
import ProfileCandidate from '../pages/profile-candidate';
import ProfileEnterprise from '../pages/profile-enterprise';
import RecoverPassword from '../pages/recover-password';
import Activate from '../pages/activate';
import Suscripcion from '../pages/suscripcion';

const PrivateRoute = ({ component: Component, ...rest }) => (
	<Route
    	{ ...rest }
		render = {
			props => {
				const usuario = window.localStorage.getItem("goworkers");
				if ( usuario ) {
					return <Component { ...props } />;
				} else {
					return (
						<Redirect to = { { pathname: "/", state: { from: props.location } } } />
					);
				}
			}
		}
	/>
);

class Routes extends Component {
	render() {
		return (
    		<GlobalContext>
        		<BrowserRouter>
          			<div className="flex main">
            			<div className="column">
							<Switch>
                				<Route path = "/" exact component = { Splash } />
                				<Route exact path = "/login" component = { Login } />
                				<Route path = "/registro" exact component = { Register } />
								<Route path="/activar/:id" exact component={Activate} /> 
								<Route path = "/habilidades" exact component = { Abilities } />
								<Route path = "/registro/empresa" exact component = { RegisterEnterprise } />
								<Route path = "/categorias" exact component = { Categories } />
								<Route path = "/recuperar" exact component={RecoverPassword} />
								<PrivateRoute path = "/inicio" exact component = { Home } />
								<PrivateRoute path = "/buscar" exact component = { Search } />
								<PrivateRoute path = "/ajustes" exact component = { Settings } />
								<PrivateRoute path = "/ajustes/seguridad" exact component = { SettingsSecurity } />
								<PrivateRoute path = "/ajustes/categorias" exact component = { CategoriesEdit } />
								<PrivateRoute path = "/ajustes/habilidades" exact component = { AbilitiesEdit } />
								<PrivateRoute path = "/ajustes/perfil" exact component = { ProfileEditCandidate } />
								<PrivateRoute path = "/ajustes/perfil/empresa" exact component = { ProfileEditEnterprise } />
								<PrivateRoute path = "/ajustes/password" exact component = { Password } />
								<PrivateRoute path = "/ajustes/bloqueos" exact component = { BlockedAccounts } />
								<PrivateRoute path="/ajustes/legalidad" exact component = {SettingsLegality} />
								{ /* Chat */ }
								<PrivateRoute path = "/chats" exact component = { Chats } />
								<PrivateRoute path = "/chat/:id/" exact component = { Chat } />
								<PrivateRoute path = "/chat/:id/:name" exact component = { Chat } />
								{ /* Subidas */ }
								<PrivateRoute path = "/subir/cv" exact component = { UploadSummary } />
								<PrivateRoute path = "/subir/video" exact component = { UploadVideo } />
								<PrivateRoute path = "/subir/videoCurriculum" exact component = {UploadVideoCurriculum} />
								{ /* Ofertas */ }
								<PrivateRoute path = "/oferta/:id" exact component = { Offer } />
								<PrivateRoute path = "/oferta/:id/editar" exact component = { OfferEdit } />
								<PrivateRoute path="/subir/oferta" exact component = { OfferAdd } />
								<PrivateRoute path = "/ofertas/" exact component = { MyOffers } />
								<PrivateRoute path = "/ofertas/candidatos" exact component = { Candidates } />
								<PrivateRoute path = "/postulaciones" exact component = { Postulations } />
								{ /* Videos */ }
								<PrivateRoute path = "/video/:id" exact component = { Video } />
								<PrivateRoute path="/video/:id/editar" exact component = { EditVideo } />
								{ /* Favoritos */ }
								<PrivateRoute path = "/favoritos" exact component = { Favorites } />
								{ /* Perfiles / Privados / Publicos */}
								<PrivateRoute path="/perfil/candidato" exact component = { ProfileCandidate } />
								<PrivateRoute path="/perfil/empresa" exact component = { ProfileEnterprise } />
								<PrivateRoute path="/empresa/:id" exact component = { PublicProfileBrand } />
								<PrivateRoute path="/candidato/:id" exact component = { PublicProfileCandidate } />
								{/* Suscripción */}
								<PrivateRoute path="/ajustes/suscripcion" exact component = {Suscripcion} />
              				</Switch>
            			</div>
          			</div>
        		</BrowserRouter>
      		</GlobalContext>
    	);
  	}
}

export default Routes;
