/**
 *  home.jsx
 *  @version: 1.0.0
 *  @author: DWIT MÉXICO - Kath, Sergio
 *  @description: Pantalla de ajustes
*/

import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Header from '../components/header/header';
import MediaQuery from 'react-responsive';
import { Consumer } from '../context';
import Contact from '../components/modals/contact';
import ModalDeleteAccount from '../components/modals/deleteAccount';

class Settings extends Component {

    constructor(props) {
        super(props);
        this.state = {
            userData: { id: 0, tipo: 0 },
        }
    }

    render() {
        let tipo = '';
        if (this.state.userData.tipo) { tipo = this.state.userData.tipo.toString(); }
        return(
            <div className="settings column ">
               <MediaQuery maxWidth = { 768 }>
                    <Header />
                    <div className="justify-center">
                        <div className="container column">  
                            <div className="white-space-16"></div>
                            <div className="row justify-center align-center">
                                <h5 className="weight-bold">
                                    Cuenta
                                </h5>
                            </div>
                            <div className="white-space-16"></div>
                            <div className="column">
                                <div className="basic-data column justify-center align-center">
                                    <div className="input-container align-center justify-center">
                                        <i className="fas fa-user-circle font-small color-darkBlue"></i>
                                        {
                                            tipo === "1" ? (<Link to="ajustes/perfil" className="input input-profile-noline">Editar Perfil</Link>) :
                                            (<Link to="ajustes/perfil/empresa" className="input input-profile-noline">Editar Perfil</Link>)
                                        }
                                    </div>
                                </div>
                                <div className="white-space-8"></div>
                                <div className="basic-data column justify-center align-center">
                                    <div className="input-container align-center justify-center">
                                        <i className="fas fa-lock font-small color-darkBlue"></i>
                                        <Link to="ajustes/password" className="input input-profile-noline">
                                            Contraseña
                                        </Link>
                                    </div>
                                </div>
                                {
                                    tipo === "2" ?
                                    (   
                                        <div className="column">
                                            <div className="white-space-8"></div>
                                            <div className="basic-data column justify-center align-center">
                                                <div className="input-container align-center justify-center">
                                                    <i className="fas fa-receipt font-small color-darkBlue"></i>
                                                        { <Link to="ajustes/suscripcion" className="input input-profile-noline">Suscripción</Link> }
                                                </div>
                                            </div>
                                        </div>
                                    ) : ( null )
                                }
                                <div className="white-space-16"></div>
                                <div className="row justify-center align-center">
                                    <h5 className="weight-bold">
                                        Seguridad y Privacidad
                                    </h5>
                                </div>
                                <div className="white-space-16"></div>
                                <div className="basic-data column justify-center align-center">
                                    <div className="input-container align-center justify-center">
                                        <i className="fas fa-shield-alt font-small color-darkBlue"></i>
                                        <div className="input input-profile-noline" onClick = { this.handleContact.bind(this) }>
                                            Enviar comentarios o reportar
                                        </div>
                                    </div>
                                </div>
                                <div className="white-space-16"></div>
                                <div className="row justify-center align-center">
                                    <h5 className="weight-bold">
                                        Información
                                    </h5>
                                </div>
                                <div className="white-space-16"></div>
                                <div className="basic-data column justify-center align-center">
                                    <div className="input-container align-center justify-center">
                                        <i className="fas fa-file-alt font-small color-darkBlue"></i>
                                        <Link to="/condiciones" className="input input-profile">
                                            Política de privacidad
                                        </Link>
                                    </div>
                                    <div className="input-container align-center justify-center">
                                        <i className="fas fa-file-alt font-small color-darkBlue"></i>
                                        <Link to="/condiciones" className="input input-profile">
                                            Terminos de uso
                                        </Link>
                                    </div>
                                    <div className="input-container align-center justify-center">
                                        <i className="fas fa-file-alt font-small color-darkBlue"></i>
                                        <Link to="/condiciones" className="input input-profile-noline">
                                            Políticas de cookies
                                        </Link>
                                    </div>
                                </div>
                                <div className="white-space-16"></div>
                                <div className="basic-data column justify-center align-center">
                                    <div className="input-container align-center justify-center">
                                        <i className="fas fa-user-times font-small color-darkBlue"></i>
                                        <a onClick={this.deleteAccount.bind(this)} className="input input-profile-noline">Eliminar Cuenta</a>
                                    </div>
                                </div>
                                <div className="white-space-32"></div>
                                <div className="row justify-center align-center">
                                    <p className="font-tiny weight-bold color-darkBlue cursor-pointer" onClick = { this.handleLogOut.bind(this.props, this) } >
                                        <i className="fas fa-sign-out-alt"></i>&nbsp; CERRAR SESIÓN
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Contact user = { this.state.userData } />
                    <ModalDeleteAccount userData = { this.state.userData } context = { this.props.context } />
                </MediaQuery>
            </div>
        );
    }

    componentDidMount() {
        this.load();
        this.props.context.mapLocation(window.location.pathname)
    }

    async load() {
        const user = await this.props.context.loadUser();
        if (user) {
            this.setState({ userData: user.id });
        }
        
    }

    deleteAccount() {
        const modal = document.getElementById('modal-delete-account');
        if (modal) {
            if (modal.classList.contains('hidden')) {
                modal.classList.remove('fadeOut');
                modal.classList.add('animated', 'fadeIn');
                modal.classList.remove('hidden');
            }
        }
    }

    handleLogOut(contextValue) {
        contextValue.props.context.logOut();
        window.location = "/login";
    }

    handleContact() {
        const modal = document.getElementById('modal-contact');
        if (modal) {
            if (modal.classList.contains('hidden')) {
                modal.classList.remove('fadeOut');
                modal.classList.add('animated', 'fadeIn');
                modal.classList.remove('hidden');
            }
        }
    }

}

export default Consumer(Settings);