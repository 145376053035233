/**
 *  splash.jsx
 *  @version: 1.0.0
 *  @author: DWIT MÉXICO - Sergio , kaths
 *  @description: LandingPage de Inicio
*/

import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Consumer } from '../context';
import MediaQuery from 'react-responsive';
import { HeaderWebLogin } from '../components/header/header';
import Login from '../components/modals/Login';
import ModalRegister from '../components/modals/register';

//  Images
import Logo from '../img/logo.png';
import SplashImgWeb from '../img/workers.jpg';
import Iso from '../img/isotipo.png';
import SplashImg from '../img/splash.jpg';

class Splash extends Component {

    constructor(props) {
        super(props);
        this.state = {
			user: { id: 0, tipo: 0 }
		}
    }     
    

    render() {
        return (
            <div className="splash justify-center">
               	<MediaQuery minDeviceWidth = { 768 }>
                <div className="view-web column">
					<div className="content-splash justify-center">
						<div className="justify-center column align-center">
							<HeaderWebLogin/>
							<div className=" background column align-center justify-center" style={{ backgroundImage: `url(${SplashImgWeb})`}}>
							<div className="overlay justify-center column align-center">
								<h1 className="color-white font-quadruple">Graba. Trabaja. Disfruta.</h1>
								<div className="row justify-center container-cards">
									<div className="column card-info-brands">
										<div className="row justify-center">
										<h3 className="card-title color-white text-center auto">EMPRESAS</h3>
										</div>
										<div className="white-space-24"></div>
										<div className="content column">
											<i className="fas fa-video color-lightBlue text-center font-huge"></i>
											<div className="white-space-16"></div>
											<p className="color-white font-tiny text-justify">Da a conocer tu empresa en videos de un minuto.Instalaciones, reuniones, opiniones del personal, y todo lo que se te ocurra.</p>
											<div className="white-space-16"></div>
											<i className="fas fa-calendar color-lightBlue text-center font-huge"></i>
											<div className="white-space-16"></div>
											<p className="color-white font-tiny text-justify">Cuelga ofertas de empleo explicando, en un minuto, qué tipo de perfil estás buscando, y para qué area de la empresa lo necesitas.</p>
											<div className="white-space-16"></div>
											<i className="fas fa-comments color-lightBlue text-center font-huge"></i>
											<div className="white-space-16"></div>
											<p className="color-white font-tiny text-justify">Busca candidatos que se adapten a tus necesidades, filtrando al máximo los resultados, y contacta con ellos a través del chat.</p>
										</div>
										
									</div>
									<div className="column card-info-candidates">
										<div className="row justify-center">
										<h3 className="card-title color-darkBluen text-center auto">CANDIDATOS</h3>
										</div>
										<div className="white-space-24"></div>
										<div className="content column">
											<i className="fas fa-video color-lightBlue text-center font-huge"></i>
											<div className="white-space-16"></div>
											<p className="color-darkBlue font-tiny text-justify">Cuenta tus habilidades, tu experiencia laboral, y tu personalidad, en videos de un minuto.</p>
											<div className="white-space-16"></div>
											<i className="fas fa-calendar color-lightBlue text-center font-huge"></i>
											<div className="white-space-16"></div>
											<p className="color-darkBlue font-tiny text-justify">Conoce cómo funciona cada empresa desde dentro. Su día a día, sus oficinas, o escucha la opinión de la gente que trabaja ahí.</p>
											<div className="white-space-16"></div>
											<i className="fas fa-comments color-lightBlue text-center font-huge"></i>
											<div className="white-space-16"></div>
											<p className="color-darkBlue font-tiny text-justify">Busca empresas y ofertas de empleo que se adapten a tu personalidad y a tu preferencias, y contacta con ellas a través del chat.</p>
										</div>
									</div>
								</div>
								<div className="more align-center">
                                    <div className="justify-center">
                                        <a href="#info">
                                            <p className="color-darkBlue weight-semi text-center font-regular">
                                                TODO ESTO, EN VIDEOS DE UN MINUTO
                                            </p>
                                        </a>
                                    </div>
                                    <div className="auto">
                                        <a href="#info">
                                            <i className="fas fa-chevron-down color-darkBlue"></i>
                                        </a>
                                    </div>
                                </div>
							</div>
							</div>
							<div className="content-data justify-center align-center column" id="info">
								<div className="container column ">
									<div className="white-space-24"></div>
									<h2>Busca personas, empresas, o freelances que se adapten a tus necesidades.</h2>
									<div className="white-space-16"></div>
									<h4 className="text-start">Candidatos</h4>
									<hr></hr>
									<div className="white-space-16"></div>
									<div className="justify-start interests-options wrap">
										
										<div className="photo responsive-img" >
											<div className="tittle align-center justify-center column ">
												<p className="color-white weight-semi">Marina Romero</p>
												<div className="white-space-8"></div>
												<p className="color-white weight-semi">25 años - Madrid</p>
												<p className="color-white weight-semi">Marketing - Publicidad</p>
											</div>
											<img  className="img-gallery" src="https://empresarias.camara.es/img/fotos/Conocenos.jpg" />
										</div>

										<div className="photo responsive-img" >
											<div className="tittle align-center justify-center column ">
												<p className="color-white weight-semi">Mis habilidades</p>
												
											</div>
											<img  className="img-gallery" src="https://nyaribisnis.com/wp-content/uploads/2019/03/Usaha-Paling-Menguntungkan-Yang-Bisa-Dicoba.jpg" />
										</div>

										<div className="photo responsive-img" >
											<div className="tittle align-center justify-center column ">
												<p className="color-white weight-semi">Esta es mi labor en</p>
												<p className="color-white weight-semi">la empresa</p>
											</div>
											<img  className="img-gallery" src="https://freedesignfile.com/upload/2018/08/Business-woman-using-cell-phone-in-the-office-selfie-03.jpg" />
										</div>

										<div className="photo responsive-img" >
											<div className="tittle align-center justify-center column ">
												<p className="color-white">¿Qué es lo que</p>
												<p className="color-white">mejor se me da?</p>
											</div>
											<img  className="img-gallery" src="https://s3.amazonaws.com/mentoring.redesign/s3fs-public/man-giving-presentation.jpg" />
										</div>
										
									</div>
									<div className="white-space-16"></div>
									<h4 className="text-start">Empresas</h4>
									<hr></hr>
									<div className="white-space-16"></div>
									<div className="justify-start interests-options wrap">
										
										<div className="photo responsive-img" >
											<div className="tittle align-center justify-center column ">
												<p className="color-white weight-semi">Buscamos</p>
												<p className="color-white weight-semi">Diseñador Grafico</p>
											</div>
											<img  className="img-gallery" src="https://atnetplanet.co.za/wp-content/uploads/2017/10/graphic-design-735x400.jpg" />
										</div>

										<div className="photo responsive-img" >
											<div className="tittle align-center justify-center column ">
												<p className="color-white weight-semi">Nuestras nuevas</p>
												<p className="color-white weight-semi">oficinas</p>												
											</div>
											<img  className="img-gallery" src="https://www.muypymes.com/wp-content/uploads/2019/05/regus-ejeprime.jpg" />
										</div>

										<div className="photo responsive-img" >
											<div className="tittle align-center justify-center column ">
												<p className="color-white weight-semi">Nuestro dia a dia</p>
											</div>
											<img  className="img-gallery" src="https://www.tetravx.com/wp-content/uploads/2018/11/iStock-876388422-2.jpg" />
										</div>

										<div className="photo responsive-img" >
											<div className="tittle align-center justify-center column ">
												<p className="color-white weight-semi">Conoce nuestro</p>
												<div className="white-space-8"></div>
												<p className="color-white weight-semi">departamento</p>
												<p className="color-white weight-semi">creativo</p>
											</div>
											<img  className="img-gallery" src="https://ak9.picdn.net/shutterstock/videos/7054909/thumb/1.jpg" />
										</div>
										
									</div>
								
								</div>
								<div className="white-space-32"></div>
								<div className="footer align-center justify-center ">
                            <div className="row align-center justify-arround"> 
                                <Link to="/condiciones" target="_blank">
                                    <h4 className="font-tiny color-white">
                                        Términos y condiciones
                                    </h4>
                                </Link>
                                <Link to="/condiciones" target="_blank">
                                    <h4 className="font-tiny color-white">
                                        Privacidad
                                    </h4>
                                </Link>
                                <Link to="/condiciones" target="_blank">
                                    <h4 className="font-tiny color-white">
                                        Cookies
                                    </h4>
                                </Link>
                                <a href="#info" className="auto" >
									<h4>
                                        Contacto
                                    </h4>
                                </a>
                                <div className="column social-media">
                                    <div className="row justify-arround">
                                        <a href="https://www.instagram.com/go_workers/?hl=es" target="_blank">
                                            <i className="fab fa-instagram color-white font-medium"></i>
                                        </a>
                                        <a href="https://www.facebook.com/Goworkers-1316702198503726/?modal=admin_todo_tour" target="_blank">
                                            <i className="fab fa-facebook-square color-white font-medium"></i>
                                        </a>
                                        <a href="https://twitter.com/GOWORKERS1" target="_blank">
                                            <i className="fab fa-twitter-square color-white font-medium"></i>
                                        </a>
                                    </div>
                                </div>
                                <p className="color-white"><i className="far fa-copyright font-tini color-whitse"></i> 2019 Goworkers</p>
                            </div>
                        </div>
						
							</div>
						</div>
					</div>
					<Login />					
					<ModalRegister />
                </div>
                </MediaQuery>
                <MediaQuery maxWidth = { 768 }>
                <div className="background justify-center" style={{ backgroundImage: `url(${SplashImgWeb})`}}>
					<div className="overlay justify-center">
						<div className="container column">
							<div className="white-space-64"></div>
							<div className="isotipo responsive-img justify-center align-center">
								<img src = { Iso } alt="G de Go Workers" title="G de Go Workers" />
							</div>
							<div className="white-space-16"></div>
							<div className="logo justify-center align-center">
								<img src = { Logo } alt="Logo Go Workers" title="Logo Go Workers" />
							</div>                
							<div className="white-space-64"></div>
							<div className="white-space-32"></div>
							<div className="slider justify-center align-center">
								<div className="content" data-flickity='{ "cellAlign": "left", "autoPlay": true }'>
									<div className="slide column">
										<p className="weight-bold color-white text-center">Candidatos</p>
										<div className="white-space-8"></div>
										<p className="color-white text-center">
										Explica tus habilidades en videos de un minuto, y encuentra un trabajo que se adapte a tu personalidad.
										</p>
									</div>
									<div className="slide column">
									<p className="weight-bold color-white text-center">Empresas</p>
									<div className="white-space-8"></div>
										<p className="color-white text-center">
										Da a conocer tu empresa en videos de un minuto, y contrata a los mejores empleados.
										</p>
									</div>									
								</div>
							</div>
							<div className="btn-containers column">
								<div className="justify-center">
									<Link to="/login" className="btn btn-large btn-full btn-lightBlue text-center color-darkBlue weight-bold">
										INICIAR SESIÓN
									</Link>
								</div>
								<div className="btn-container justify-center">
									<Link to="/registro" className="btn btn-large btn-full btn-darkBlue text-center color-white weight-bold">
										REGÍSTRATE
									</Link>
								</div>								
							</div>
						</div>
					</div>
                </div>
            	</MediaQuery>
        	</div>
    	);
	}

	componentDidMount() { 
		this.load(); 
		this.loadFlickity();
	}

	async load() {
        const user = await this.props.context.loadUser();
        if (user) {
			this.setState({user: user.id});
        	this.validateUser();
		}
    }

	validateUser() {
        if (this.state.user && this.state.user.id) { window.location = '/inicio'; }		
	}
	
	loadFlickity() {
		const script = document.createElement('script');
		script.type = 'text/javascript';
		script.src = 'https://unpkg.com/flickity@2/dist/flickity.pkgd.min.js';
		document.body.append(script);
	}

}



export default Consumer(Splash);