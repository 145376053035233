/**
 *  upload-video.jsx
 *  @version: 1.0.0
 *  @author: DWIT MÉXICO - katherine
 *  @description: Página de subida de vídeo curriculum
*/

import React, { Component } from 'react';
import Header from '../components/header/header';
import { Consumer } from '../context';
import ModalAlert from '../components/modals/alert';
import moment from 'moment-timezone';
import Request from '../core/httpClient';

const req = new Request();

class UploadSummaryCurriculum extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userData: { id: 0, tipo: 0 }
        }
    }

    render() {

        let tipo = '';
        if(this.state.userData.tipo) {
            tipo = this.state.userData.tipo.toString();
        }
        
        return(
            <div className="upload-summary column">
                <Header back = { tipo === "1" ?  "/perfil/candidato" : "/perfil/empresa"}  />
                <div className="content upload-video column justify-center align-center">
                    <div className="card-container container column justify-center align-center">
                        <div className="white-space-24"></div>
                        <div className="icon justify-center">
                            <i className="fas fa-upload color-white fa-2x"></i>
                        </div>
                        <div className="white-space-16"></div>
                        <div className="btn-container justify-center">
                            <button className="btn btn-radius btn-darkBlue weight-bold color-white" onClick = { this.uploadFile.bind(this) }>
                                SELECCIONAR VÍDEO
                            </button>
                            <input type="file" name="vd-src" id="vd-src" className="hidden" onChange = { this.handleChange.bind(this) } accept="video/*"/>
                        </div>
                        <div className="white-space-24"></div>
                        <div className="column progress-bar hidden" id="myProgress">
                            <div className="taskbar" id="myBar"></div>
                        </div>
                        <div className="white-space-16"></div>
                        <div className="video-wrapper column hidden" id="video-wrapper">
                            <div className="white-space-16"></div>
                            <p className="text-center font-tiny color-white weight-semi">
                                VISTA PREVIA
                            </p>
                            <div className="white-space-8"></div>
                            <video src = { this.state.video } controls></video>
                        </div>
                        <div className="justify-center">
                            <h5 className="color-white">
                                { this.state.message }
                            </h5>
                        </div>
                        <div className="white-space-48"></div>
                    </div>
                </div>
                <div className="btn-container justify-center">
                    <button className="btn-full btn-lightBlue btn-large btn-bottom btn-bottom-light weight-bold" type="button" onClick = { this.handleSubmit.bind(this) }>
                        PUBLICAR VÍDEO
                    </button>
                </div>
                <ModalAlert title="VÍDEO PUBLICADO" description="" />
            </div>
        );
    }

    componentDidMount() {
        this.load();
        this.props.context.mapLocation(window.location.pathname);
    }

    async load() {
        const user = await this.props.context.loadUser();
        if(user) {
            this.setState({userData: user.id});
        }
    }

    uploadFile() {
        const file = document.getElementById("vd-src");
        if (file) {
            file.click();
            document.getElementById('myProgress').classList.remove('hidden');
        }
    }

    async handleChange(event) {
        if (event.target.files.length > 0) {
            const video = event.target.files[0];
            const reader = new FileReader();
            reader.onload = () => {
                const media = new Audio(reader.result);
                media.onloadedmetadata = function () {
                    if(media.duration < 4) {
                        this.setState({message: 'El video debe ser mayor a 4 segundos'});
                        return;
                    }
                    if (media.duration > 60) {
                        this.setState({message: 'El video debe ser menor a 60 segundos'});
                        return;
                    }
                    if(media.duration >= 4 && media.duration <= 60) {
                        this.setState({ message: 'Vídeo seleccionado' });
                        let videoWrapper = document.getElementById('video-wrapper');
                        if (videoWrapper.classList.contains('hidden')) {
                            videoWrapper.classList.remove('hidden');
                        }
                    }
                    if (media.duration) {
                        this.setState({ duracion: media.duration });
                    }
                }.bind(this);
                this.setState({ video: reader.result });
            }
            reader.readAsDataURL(video);
        }
    }

    handleSubmit() {
     
        this.uploadFirebase({ thisvideo: this.state.video, duracion: this.state.duracion});
     
    }

    async uploadFirebase() {
        const storageRef = window.firebase.storage().ref();
        let fileName = `${this.state.userData.tipo}/${this.state.userData.id}/${moment().format('YYYYMMDDhhmmss')}`;
        const ref = storageRef.child(`videos/${fileName}`);

        // Obtener ruta
        const videoUrl = ref.putString(this.state.video, 'data_url');
        videoUrl.on(window.firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
            (snapshot) => {
                // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                this.setState({ message: 'Subida: ' + progress.toFixed(0) + '%' });
                this.progressUpload(progress.toFixed(0));
                switch (snapshot.state) {
                    case window.firebase.storage.TaskState.PAUSED: // or 'paused'
                        //console.log('Upload is paused');
                        break;
                    case window.firebase.storage.TaskState.RUNNING: // or 'running'
                        //console.log('Upload is running');
                        break;
                    default:
                        break;
                }
            },
            (error) => {
                // A full list of error codes is available at
                // https://firebase.google.com/docs/storage/web/handle-errors
                switch (error.code) {
                    case 'storage/unauthorized':
                        console.log("User doesn't have permission to access the object");
                        break;
                    case 'storage/canceled':
                        console.log("User canceled the upload");
                        break;
                    case 'storage/unknown':
                        console.log("Unknown error occurred, inspect error.serverResponse");
                        break;
                    default:
                        break;
                }
            },
            () => {
                // Upload completed successfully, now we can get the download URL
                videoUrl.snapshot.ref.getDownloadURL().then(async (downloadURL) => {
                    const data = {
                        idUsuario: this.state.userData.id,
                        url: downloadURL                        
                    }
                    const res = await req.post('/videos/update/videocurriculum', data);
                    this.setState({ message: res.message });
                    if (res.update) {
                        document.getElementById('modal-alert').classList.remove('hidden');
                        document.getElementById('modal-alert').classList.add('fadeIn');
                        setTimeout(() => {
                            window.location = "/perfil/candidato"
                        }, 1500);
                    }
                });
            });
    }

    progressUpload(porcentaje) {
        var elem = document.getElementById("myBar");
        var width = porcentaje;
        elem.style.width = width + '%';
        elem.innerHTML = width * 1 + '%';
    }

}

export default Consumer(UploadSummaryCurriculum);