/**
 *  login.jsx
 *  @version: 1.0.0
 *  @author: DWIT MÉXICO - Sergio
 *  @description: Página de Login
*/

import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { HeaderSingle } from "../components/header/header";
import Request from "../core/httpClient";
import { Consumer } from "../context/";
import WOW from 'wowjs';

class Login extends Component {
	constructor(props) {
		super(props);
		this.state = {
			user: { id: 0, tipo: 0 }
		}
	}	

	render() {
		return (
			<div className="login column">
				<HeaderSingle />
				<div className="white-space-32" />
				<div className="justify-center">
					<div className="container column">
						<h2 className="text-center wow fadeIn">
							INICIO DE SESIÓN
						</h2>
						<div className="white-space-32" />
						<form className="column" onSubmit = { this.hanldeSubmit.bind(this) }>
							<div className="white-space-8" />
							<div className="input-container align-center">
								<i className="fas fa-at font-small" />
								<input type="email" name="email" placeholder="Correo electrónico" className="input" minLength="6" maxLength="60" />
							</div>
							<div className="white-space-8" />
							<div className="input-container align-center">
								<i className="fas fa-key font-small" />
								<input type="password" name="password" placeholder="Contraseña" className="input" minLength="8" maxLength="16" />
							</div>
							<div className="white-space-16" />
							<div className="btn-container justify-center">
								<button className="btn btn-lightBlue weight-bold font-regular color-darkBlue">
									{
										this.state.loading ?
											( <i className="fas fa-spin fa-spinner color-darkBlue"></i> ) :
											( 'INICIAR SESIÓN' )
									}
								</button>
							</div>
							<div className="white-space-16" />
							<div className="btn-container justify-center">
								<button className="btn btn-pink weight-semi color-white" id="btn-facebook">
									<i className="fab fa-facebook" />
									&nbsp; INGRESAR CON FACEBOOK
								</button>
							</div>
							<div className="white-space-16" />
							{
								this.state.message ? 
								(
									<div className="column">
										<p className="text-center font-tiny color-red weight-semi"> {this.state.message} </p>
										<div className="white-space-8"></div>
									</div>
								)
								: ( null )
							}
							<div className="justify-center">
								<Link to="/recuperar" className="text-center">
									¿Olvidaste tu contraseña?
								</Link>
							</div>
							<div className="white-space-8" />
						</form>
					</div>
				</div>
				<div className="white-space-32" />
			</div>
		);
	}

	componentDidMount() {
		new WOW.WOW({
			live: false
		}).init();
		this.load();
		this.validateUser();
	}

	async load() {
		const user = await this.props.context.loadUser();
		if (user) { this.setState({ user: user.id }); }
	}

	validateUser() {
		if (this.state.user && this.state.user.id) {
			window.location = '/inicio';
		}
	}

	async hanldeSubmit(event) {
		event.preventDefault();
		this.setState({ loading: true });
		const form = event.target;
		const data = { Email: form.email.value, Password: form.password.value };
		const request = new Request();
		const response = await request.post("/users/login", data);
		if (response) {
			if (response.error) {
				this.setState({ message: response.message });
			} else {
				if (response.user) {
					this.props.context.logIn({ id: response.user, auth: true });
					window.localStorage.removeItem('gow_ftab');
					window.localStorage.removeItem('gow_htab');
					const user = await this.props.context.loadUser();
					if (user.id.first_login && user.id.tipo === 1 && user.id.freelancer !== 1) {
						this.props.history.push("/inicio");
						window.localStorage.setItem("gwt",true);
					} else {
						if (user.id.first_login && user.id.tipo === 1 && user.id.freelancer === 1) {
							this.props.history.push("/inicio");
							window.localStorage.setItem("gwt", true);
						} else {
							if (user.id.first_login && user.id.tipo === 2) {
								this.props.history.push("/inicio");
								window.localStorage.setItem("gwt", false);
							} else {
								this.setState({ message: response.message });
							}
						}
					}
				}
			}
		}
		this.setState({ loading: false, message: response.message });
	}

}

export default withRouter(Consumer(Login));
