/**
 *  conformation.jsx
 *  @version: 1.0.0
 *  @author: DWIT MÉXICO - Kath
 *  @description: MODAL EDUTAR VIDEO
*/


import React, { Component } from 'react';
import { Consumer } from '../../context';
import Request from '../../core/httpClient';
const request = new Request();


class EditVideo extends Component {

	constructor(props) {
        super(props);
        this.state = {
            userData: { id: 0, tipo: 0 },
            id_video: '', video: '', title: '',
            updateVideo: false
        }
    }

    render() {
        return (
            <div className="modal modal-confirm column justify-center align-center hidden" id="modal-edit-video">
                <div className="container modal-content align-center column" >
					<a className="btn auto" onClick = { this.handleClose.bind(this) }>
					<i className="fas fa-times font-medium" id="close-modal"></i>
					</a>
                    <div className="upload-summary column">
                    <div className="content upload-video column justify-center align-center">
                        <div className="card-container container column justify-center align-center">
                            <div className="white-space-8"></div>
                            <div className="white-space-32"></div>
                            <input type="text" autoComplete="off" required name="title" className="input" minLength="4" maxLength="60" placeholder="Título del vídeo" id="title2" value={this.state.title} onChange={event => this.setState({ title: event.target.value })} />
                            <div className="white-space-16"></div>
                            <div className="video-wrapper column" id="video-wrapper">
                                <h4 className="text-center color-darkBlue weight-bold">
                                    VISTA PREVIA
                                </h4>
                                <div className="white-space-16"></div>
                                <video src={this.state.video} controls></video>
                            </div>
                            <div className="justify-center">
                                <h5 className="color-white">
                                    {this.state.message}
                                </h5>
                            </div>
                            <div className="white-space-24"></div>
                        </div>
                    </div>
                    <div className="btn-container justify-center">
                        <button className="btn-full btn-lightBlue btn-large btn-bottom btn-bottom-light weight-bold" id="btn-bottom" type="button" onClick={this.handleSubmit.bind(this)}>
                        {
                            this.state.loading ? 
                            <i className="fas fa-spinner fa-spin"></i> 
                            : 
                            this.state.updateVideo ? 'VIDEO ACTUALIZADO' : 
                            'GUARDAR CAMBIOS'
                        }
                            
                        </button>
                    </div>
                  
                </div>
    
                  </div>
            </div>
        );
	}
		
    handleClose() {
		let modalAdd = document.getElementById('modal-edit-video');
		modalAdd.classList.add('fadeOut');
		setTimeout(() => {
			modalAdd.classList.add('hidden');
		}, 500);
    }

  
    componentDidUpdate(prevProps) {
        if(this.props.id_video !== prevProps.id_video){
            this.setState({id_video:this.props.id_video});
            console.log(this.state.id_video);
            this.load();
        }
    }

    async load() {

        const user = await this.props.context.loadUser();
        if (user) {
            this.setState({ userData: user.id });
            this.validateVideo();
        }
    }

    async validateVideo() {
        this.setState({ loading: true });
        if (this.state.userData) {
            const id_video = this.state.id_video;
            const data = { idVideo: parseInt(id_video), idUsuario: this.state.userData.id };
            const response = await request.post("/videos/validate", data);
            if (response && !response.error) {
                if (response.redirect) {
                    alert("sin video");
                } else {
                    console.log(response);
                    this.setState({
                        id_video: response.video.id_video, video: response.video.video_url ,title: response.video.titulo, loading: false
                    });
                }
            } else {
                this.setState({ network: true });
            }
        } else {
            this.validateVideo();
        }
    }

    async handleSubmit() {
        let title = '';
        title = document.getElementById('title2').value; 
        this.setState({ loading: true });
        if (title.length > 6) {
            let data = { idVideo: parseInt(this.props.id_video), idUsuario: this.state.userData.id, titulo: title };
            const response = await request.post("/videos/update", data);
            if (response.error) {
                this.setState({ message: response.message });
                alert(response.message);
            } else {
                console.log(response);
                if (response.update) {
                    this.setState({updateVideo: true});
                    this.setState({ loading: false });
                    if (this.state.userData.tipo === 1) {   
                        setTimeout(() => {
                            window.location = "/perfil/candidato"
                        }, 2000);
                    } else {
                        setTimeout(() => {
                            window.location = "/perfil/empresa"
                        }, 2000);
                    }
                }
            }
        } else {
            alert("Escribe un título adecuado");
            this.setState({ loading: false });
        }
    }
	
}

export default Consumer(EditVideo);