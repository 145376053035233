import React from 'react';

const BottomControls= (props) => (
    <div className="bottom-controls justify-center">
        <form className="container container-controls" onSubmit={props.submit.bind(this)}>
            <div className="left">
                <input type="text" autoComplete="off" name="mensaje" className="input" placeholder="Escribe un mensaje" autoComplete="none"/>
            </div>
            <div className="right auto align-center">
                <i className="fas fa-file-image color-purple font-regular icon-sent-image" onClick={props.sendImage.bind(this)}></i>
                <button type="submit" className="btn-send">
                    <i className="fab fa-telegram-plane color-pink font-regular icon-sent"></i>
                </button>
            </div>
        </form>
    </div>
);

export default BottomControls;