/**
 *  users.js
 *  @version: 1.0.0
 *  @author: DWIT MÉXICO - Sergio
 *  @description: Manejador de ejecuciones del Context
 */

import Request from '../core/httpClient';

const req = new Request();

export async function loadUser() {
	let user = window.localStorage.getItem("goworkers");
	if ( user ) {
		user = JSON.parse(user);
		const res = await req.post('/users/tokuser', {id: user.id});
		if(res.data) {
			user.id = JSON.parse(res.data);
		} else {
			user.id = {id: 0, tipo: 0}
		}
		this.setState({user: user});
		return user;
	}
};

export function saveRegister(data) {
	window.localStorage.setItem("registertype", data.Tipo);
	this.setState({registerData: data});
};

export function logIn(data) {
	window.localStorage.setItem("goworkers", JSON.stringify(data));
	this.setState({ user: data });
};

export function logOut() {
	window.localStorage.setItem("goworkers", '{"auth": false}');
	window.localStorage.removeItem("goworkers");
	window.localStorage.removeItem("gwt");
	this.setState({ user: { auth: false } });
};

export function getList(data) {
	window.localStorage.setItem("list", JSON.stringify(data));
	this.setState({ list: data });
};

export function interestUpdated(updated) {
	this.setState({ updated_interest: updated });
}

export function variableVideo(id) {
	window.localStorage.setItem("idVideo", JSON.stringify(id));
	this.setState({ idVideo: id });
}

export function setDeleteItem(id) {
	//window.localStorage.setItem("idDelete", JSON.stringify(id));
	this.setState({ idDelete: id });
}

export function setEditVideo(id) {
	this.setState({ idEditVideo: id });
}

export function setOfferCandidates(data) {
	window.localStorage.setItem("offerData", JSON.stringify(data));
	this.setState({ offerData: data });
}