/**
 *  fav-job.jsx
 *  @version: 1.0.0
 *  @author: DWIT MÉXICO - Sergio
 *  @description: Card de favoritos de Ofertas
*/

import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Consumer } from '../../context';
import Request from "../../core/httpClient";
import shave from 'shave';
const request = new Request();

class CardFavoriteJob extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: { id: 0, tipo: 0 }
        }
    }

    passVariable() {
        this.props.history.push(`/oferta/${this.props.oferta.id_oferta}`);
    }

    async deleteFavorite() {
        const user = await this.props.context.loadUser();
        if (user) { this.setState({ user: user.id }); }

        const data = {
            tipo_user: user.id.tipo,
            id_user: user.id.id,
            tipo: 3,
            id_elemento: this.props.oferta.id_oferta
        };

        const res = await request.post('/favorites/set', data);
        if (res.error) {
            console.log(res.message);
        } else {
            this.setState({ fav: !this.state.fav });
        }
        this.props.load();
    }

    render() {
        let titulo = '', nombre = '', ciudad = '';
        if (this.props.oferta) {
            titulo = this.props.oferta.titulo;
            nombre = this.props.oferta.nombre;
            ciudad = this.props.oferta.ciudad;
            shave('.card-name', 22);
        }

        return (
            <div className="card-job card-job-fav" >
                <div className="head" onClick={this.passVariable.bind(this)}>
                    <div className="logo responsive-img align-center">
                        <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/2/2f/Google_2015_logo.svg/1200px-Google_2015_logo.svg.png" alt="HP Logo" title="HP Logo" />
                    </div>
                </div>
                <div className="info column">
                    <div className="title column">
                        <h4 className="font-regular padding card-name">
                            {titulo}
                        </h4>
                        <p className="padding color-darkgray">
                            {nombre}
                        </p>
                    </div>
                    <div className="align-center padding">
                        <i className="far fa-compass font-mini"></i>&nbsp;
                        <p className="font-mini">
                            {ciudad}
                        </p>
                    </div>
                    <div className="white-space-8"></div>
                    <div className="align-center padding">
                        <i className="fas fa-euro-sign font-mini"></i>&nbsp;
                        <p className="font-mini">
                            $12k / Mes
                        </p>
                    </div>
                    <div className="btn-container">
                        <div className="contact justify-center">
                            <button className="btn-play weight-semi" onClick={this.passVariable.bind(this)}>
                                <i className="fas fa-reply"></i>&nbsp; VER
                            </button>
                        </div>
                        <div className="contact-remove justify-center">
                            <button className="btn-play" onClick={this.deleteFavorite.bind(this)}>
                                <i className="fas fa-times color-white"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    componentDidMount() {
        shave('.card-name', 22);
    }

}

export default withRouter(Consumer(CardFavoriteJob));