/**
 *  profile-influencer.jsx
 *  @version: 1.0.0
 *  @author: DWIT MÉXICO - kath
 *  @description: PERFIL CANDIDATO VISTO DESDE EMPRESA
*/

import React, { Component } from 'react';
import Header from '../components/header/header';
import HeaderWebHomeLoggedIn from '../components/header/header-desktop';
import MediaQuery from 'react-responsive';
import LeftBar from '../components/left-bar/left-bar';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import CardMyVideo from '../components/cards/my-videos';
import CardMyOffers from '../components/cards/my-offers';
import ImageLoader from 'react-loading-image';
import LoadingImage from '../img/loading.gif';
import HolderImage from '../img/user.png';
import ModalConfirmDeleteVideo from '../components/modals/deleteVideo';
import ModalConfirmDeleteOffer from '../components/modals/deleteOffer';
import ModalOfferAdd from '../components/modals/offerAdd';
import ModalVideoEdit from '../components/modals/editVideo';
import ModalVideoAdd from '../components/modals/AddVideo';
import ModalEditOffer from '../components/modals/editOffer';
import { Consumer } from '../context';
import Request from "../core/httpClient";
const request = new Request();

class ProfileEnterprise extends Component {

    constructor(props) {
        super(props);
        this.state = {
            candidate: '', 
            userData: { id: 0, tipo: 0 }, 
            videos: [], 
            items: [], 
            numItems: 1, 
            loadingState: false, 
            first: true, 
            modalTitle: '', 
            modalDescription: '', 
            addType: 1,
            cualidades: [], 
            ofertas: [], 
            numItemsOffer: 1, 
            itemsOffer: [], 
            numItemsE: 11, 
            firstE: true,
            itemsE: [],
            numItemsOfferE: 11,
            itemsOfferE: [],
            id_video: 0,
            id_oferta: 0
        };
    }

    setEditID(id_video) {
        this.setState({id_video});
    }

    setEditOfferID(id_oferta) {
        this.setState({id_oferta});
    }


    render() {
        let company = '', image = '', cualidades = [];
        if (!this.state.loading) {
            if (this.state.company) {
                company = this.state.company;
                company.imagen ? image = company.imagen : image = HolderImage;
            }
        }
        
        if (this.state.cualidades) {
            cualidades = this.state.cualidades;
        }

        return (
            <div className="profile-candidate column align-center">
                <Helmet>
                    <title>WAALA - Perfil</title>
                    <meta name="robots" content="noindex" />
                    <meta property="og:title" content="Go Workers - Perfil" />
                    <meta property="og:type" content="website" />
                </Helmet>
                <MediaQuery minDeviceWidth = { 768 }>
                    <div className="view-web column">
                        <HeaderWebHomeLoggedIn />
                        <div className="row">
                            <LeftBar active = "menuProfile" />
                            <div className="column">
                                <div className="row justify-center">
                                    <div className="container-profile column align-center justify-center">
                                        <div className="white-space-32"></div>
                                        <div className="header-profile">
                                            <div className="column profile-column-public justify-center align-center">
                                                <div className="responsive-img justify-start align-start">
                                                    <img src={image} alt="" className="profile-image-public" />
                                                </div>
                                            </div>
                                            <div className="column justify-center align-center column-info">
                                                <div className="white-space-16"></div>
                                                <div className="row">
                                                    <h2 className="weight-bold">{company.nombre}</h2>      
                                                    <Link to="/ajustes/perfil/empresa">
                                                    <i className="fas fa-cog icon-config"></i>
                                                    </Link>                                             
                                                </div>                           
                                                <div className="white-space-16"></div>
                                                <div className="row">
                                                    <i className="fas fa-medal font-Large color-darkBlue icon-header"></i>
                                                    <p className="text-start font-regular text-abilities">{company.sector}</p>                      
                                                </div>
                                                <div className="white-space-16"></div>
                                                <div className="row">
                                                    <i className="fas fa-map-marker-alt font-Large color-darkBlue icon-header icon-ubication"></i>
                                                    <p className="text-start font-regular">{company.ciudad}</p>
                                                </div>
                                                <div className="white-space-24"></div>
                                            </div>
                                        </div>
                                        <div className="white-space-8"></div>
                                        <div className="conteiner-labels column">
                                            <div className="white-space-8"></div>
                                            <div className="row row-labels justify-center wrap">
                                                {
                                                    cualidades.map((item, index) =>
                                                        <div className="user-label auto" key={index}>
                                                            <h5 className="font-mini">
                                                                {item.name}
                                                            </h5>
                                                        </div>
                                                    )
                                                }
                                            </div>
                                        </div>  
                                        <div className="white-space-8"></div>
                                        <div className="tabs-menu-profile justify-center">
                                            <div className="videos-tab column active-tab" id="my-videos-tab">
                                                <div className="icon justify-center">
                                                    <i className="fas fa-video color-white"></i>
                                                </div>
                                                <div className="title justify-center">
                                                    <button type="button" className="btn btn-square text-center weight-semi font-mini color-white" onClick = { this.handleVideos.bind(this) }>
                                                        Vídeos
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="offers-tab column" id="my-offers-tab">
                                                <div className="icon justify-center">
                                                    <i className="fas fa-user-tie color-white"></i>
                                                </div>
                                                <div className="title justify-center">
                                                    <button type="button" className="btn btn-square text-center color-white weight-semi font-mini" onClick={this.handleOffers.bind(this) }>
                                                        Ofertas
                                                    </button>
                                                </div>
                                            </div>
                                        </div>       
                                        <div className="white-space-16"></div>
                                        <div className="my-offers justify-center" id="my-videos">
                                            <div className="vc row list-videos wrap" id="os" onScroll={this.scrollItemsE.bind(this)}>
                                                {
                                                    this.state.loadingState ?
                                                    <div className="justify-center align-center">
                                                        <i className="text-center fas fa-spin fa-spinner"></i>
                                                    </div> : ""
                                                }
                                                {
                                                    this.state.emptyVideo ?
                                                        <div className="column align-center">
                                                            <div className="white-space-8"></div>
                                                            <h3 className="text-center">
                                                                No existen videos.
                                                            </h3>
                                                        </div>
                                                    :
                                                    this.state.firstE ? this.state.itemsE.map(
                                                        (item) =>
                                                            <CardMyVideo video={item} desktop={true} key={item.id_video} setEditID={this.setEditID.bind(this)}/>
                                                    ) :
                                                    this.displayItemsE()
                                                }
                                            </div>
                                        </div>
                                    <div className="white-space-16"></div>
                                    {
                                        this.state.loadingScrollE ? <i className="fas fa-spin fa-spinner"></i> : ""
                                    }
                                    <div className="my-offers justify-center hidden" id="my-offers">
                                        <div className="column vc list-cards" id="os" onScroll={this.scrollItemsE.bind(this)}>
                                            {
                                                this.state.loadingState ?
                                                    <div className="justify-center">
                                                        <i className="fas fa-spin fa-spinner"></i>
                                                    </div> : ""
                                            }
                                            {
                                                this.state.emptyOffer ?
                                                    <div className="column align-center">
                                                        <div className="white-space-8"></div>
                                                        <h3 className="text-center">
                                                            No existen ofertas.
                                                        </h3>
                                                    </div>
                                                :
                                                    this.state.firstE ? this.state.itemsOfferE.map((item, index) =>
                                                            <CardMyOffers oferta={item} key={index} desktop={true} setEditOfferID={this.setEditOfferID.bind(this)}/>
                                                    ) :
                                                    this.displayItemsE()
                                            }
                                        </div>
                                    </div>
                                    <div className="white-space-8"></div>
                                    {
                                        this.state.loadingScrollOE ? <i className="fas fa-spin fa-spinner"></i> : ""
                                    }
                                    </div>
                                </div>
                                <ModalOfferAdd></ModalOfferAdd>
                                <ModalVideoAdd></ModalVideoAdd>
                                <ModalVideoEdit id_video={this.state.id_video}></ModalVideoEdit>
                                <ModalEditOffer id_oferta={this.state.id_oferta}></ModalEditOffer>
                            </div>
                        </div>
                    </div>
                </MediaQuery>                
                <MediaQuery maxWidth = { 768 }>
                    <Header />
                    <div className="container column ">
                        <div className="candidate-cards column justify-start align-center">
                            <div className="image row">
                                <div className="column justify-center align-center">
                                    <div className="justify-center">
                                        <h4 className="weight-bold text-center font-regular name">
                                            {company.nombre}
                                        </h4>
                                    </div>
                                    <div className="white-space-4"></div>
                                    <div className="justify-center">
                                        <h5 className="text-center weight-semi font-tiny age">
                                            {company.sector}
                                        </h5>
                                    </div>
                                    <div className="white-space-4"></div>
                                    <div className="justify-center">
                                        <h5 className="text-center weight-semi font-tiny city">
                                            {company.ciudad}
                                        </h5>
                                    </div>
                                </div>
                                <div className="profile-container column">
                                    <div className="align-center justify-center">
                                        <div className="responsive-img justify-center align-center">
                                            <ImageLoader src={image} alt="Imagen del candidato" title="Imagen del candidato" className="image-profile"
                                            loading={() => <img className="image-profile" alt="Imagen del candidato" title="Imagen del candidato" src={LoadingImage} />} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="tabs-container justify-center">
                                <div className="column">
                                    <button type="button" className="btn btn-square text-center weight-semi font-mini active-tab" onClick = { this.handleVideos.bind(this) } id="my-videos-tab">
                                        <i className="fas fa-video"></i> Vídeos
                                    </button>
                                </div>
                                <div className="column">
                                    <button type="button" className="btn btn-square text-center weight-semi font-mini" onClick={this.handleOffers.bind(this) } id="my-offers-tab">
                                        <i className="fas fa-file-invoice-dollar"></i> Ofertas
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="white-space-4"></div>
                        <div className="conteiner-labels column">
                            <div className="soft-skills" data-flickity='{ "cellAlign": "left", "contain": true, "autoPlay": true, "pageDots": false }'>
                                {
                                    cualidades.map((item, index) =>
                                        <div className="user-label auto" key={index}>
                                            <h5 className="font-mini">
                                                {item.name}
                                            </h5>
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                    <div className="my-offers justify-center profile-private" id="my-videos">
                        <div className="vc container column" ref="iScroll" id="os" onScroll={this.scrollItems.bind(this)}>
                            {this.state.loadingState ?
                                <div className="justify-center">
                                    <i className="fas fa-spin fa-spinner"></i>
                                </div> : ""}
                            {this.state.emptyVideo ?
                                <div className="column align-center">
                                    <div className="white-space-8"></div>
                                    <h3 className="text-center">
                                        No existen videos.
                                </h3>
                                </div>
                                :
                                this.state.first ? this.state.items.map((item) =>
                                    <CardMyVideo video={item} key={item.id_video} />
                                ) :
                                this.displayItems()}
                        </div>
                    </div>
                    {
                        this.state.loadingScroll ? <i className="fas fa-spin fa-spinner"></i> : ""
                    }
                    <div className="my-offers justify-center hidden profile-private" id="my-offers">
                        <div className="container column" ref="iScroll" id="os" onScroll={this.scrollItems.bind(this)}>
                            {this.state.loadingState ?
                                <div className="justify-center">
                                    <i className="fas fa-spin fa-spinner"></i>
                                </div> : ""}
                            {this.state.emptyOffer ?
                                <div className="column align-center">
                                    <div className="white-space-8"></div>
                                    <h3 className="text-center">
                                        No existen ofertas.
                                    </h3>
                                </div>
                                :
                                this.state.first ? this.state.itemsOffer.map((item, index) =>                                    
                                    <CardMyOffers oferta={item} key={index} />
                                ) :
                                this.displayItems()}
                        </div>
                    </div>
                    {
                        this.state.loadingScrollO ? <i className="fas fa-spin fa-spinner"></i> : ""
                    }
                    <div className="white-space-64"></div>
                    {
                        this.state.addType === 1 ?
                        (
                            <div className="btn-container justify-center btn-container-candidate fixed-contact">
                                <Link to="/subir/video" className="btn btn-lightBlue btn-contact weight-bold font-regular text-center color-darkBlue">
                                    <i className="fas fa-video"></i> Añadir vídeo
                                </Link>
                            </div>
                        )
                        :
                        (
                            <div className="btn-container justify-center btn-container-candidate fixed-contact">
                                <Link to="/subir/oferta" className="btn btn-lightBlue btn-contact weight-bold font-regular text-center color-darkBlue">
                                    <i className="fas fa-file"></i> Nueva oferta
                                </Link>
                            </div>
                        )
                    }
                </MediaQuery>
                <ModalConfirmDeleteVideo title = "Eliminar" description = "" load={this.getCandidate.bind(this)} />
                <ModalConfirmDeleteOffer title="Eliminar" description="" load={this.getCandidate.bind(this)} />
            </div>
        );
    }

    componentDidMount() {
        this.getCandidate();
        this.props.context.mapLocation(window.location.pathname);
    }

    scrollItems(event) {
        if (event.target.scrollTop + event.target.clientHeight >= event.target.scrollHeight) {
            this.loadMoreItems();
            this.setState({ first: false });
        }
    }

    scrollItemsE(event) {
        if (event.target.scrollTop + event.target.clientHeight >= event.target.scrollHeight) {
            this.loadMoreItemsE();
            this.setState({ firstE: false });
        }
    }

    displayItems() {
        var items = [];
        let videosTab = document.getElementById('my-videos-tab');
        if (videosTab.classList.contains('active-tab')) {
            for (let i = 0; i < this.state.numItems + 1; i++) {
                if (this.state.videos[i]) {
                    items.push(                        
                        <CardMyVideo video={this.state.videos[i]} key={this.state.videos[i].id_video} />
                    );
                }
            }
        } else {
            for (let i = 0; i < this.state.numItemsOffer + 1; i++) {
                if (this.state.ofertas[i]) {
                    items.push(                        
                        <CardMyOffers oferta={this.state.ofertas[i]} key={this.state.ofertas[i].id_oferta} />
                    );
                }
            }
        }
        return items;
    }

    loadMoreItems() {
        let videosTab = document.getElementById('my-videos-tab');
        if (videosTab.classList.contains('active-tab')) {
            
            if (this.state.numItems <= this.state.videos.length) {
                this.setState({ loadingScroll: true });
                setTimeout(() => {
                    this.setState({ numItems: this.state.numItems + 2, loadingScroll: false });
                }, 3000);
            }
        } else {
            if (this.state.numItemsOffer <= this.state.ofertas.length) {
                this.setState({ loadingScrollO: true });
                setTimeout(() => {
                    this.setState({ numItemsOffer: this.state.numItemsOffer + 2, loadingScrollO: false });
                }, 3000);
            }
        }
    }

    displayItemsE() {
        var items = [];
        let videosTab = document.getElementById('my-videos-tab');
        if (videosTab.classList.contains('active-tab')) {
            for (let i = 0; i < this.state.numItemsE + 1; i++) {
                if (this.state.videos[i]) {
                    items.push(
                        <CardMyVideo video={this.state.videos[i]} desktop={true}  setEditID={this.setEditID.bind(this)} key={this.state.videos[i].id_video}/>
                    );
                }
            }
        } else {
            for (let i = 0; i < this.state.numItemsOfferE + 1; i++) {
                if (this.state.ofertas[i]) {
                    items.push(
                        <CardMyOffers oferta={this.state.ofertas[i]} desktop={true} setEditOfferID={this.setEditOfferID.bind(this)} key={this.state.ofertas[i].id_oferta}/>
                    );
                }
            }
        }
        return items;
    }

    loadMoreItemsE() {
        let videosTab = document.getElementById('my-videos-tab');
        if (videosTab.classList.contains('active-tab')) {

            if (this.state.numItemsE <= this.state.videos.length) {
                this.setState({ loadingScrollE: true });
                setTimeout(() => {
                    this.setState({ numItemsE: this.state.numItemsE + 12, loadingScrollE: false });
                }, 3000);
            }
        } else {
            if (this.state.numItemsOfferE <= this.state.ofertas.length) {
                this.setState({ loadingScrollOE: true });
                setTimeout(() => {
                    this.setState({ numItemsOfferE: this.state.numItemsOfferE + 12, loadingScrollOE: false });
                }, 3000);
            }
        }
    }

    async getCandidate() {
        const user = await this.props.context.loadUser();
        if (user) {
            this.setState({ userData: user.id });
        }
        const data = { user: user.id.id, tipo: user.id.tipo };
        const response = await request.post("/candidates/get", data);
        if (response && !response.error) {
            if (response.empty) {
                this.setState({ empty: true, loading: false });
            } else {
                this.setState({ company: response.candidate, loading: false });
                this.loadFlickity();
                if (response.candidate.habilidades) {
                    const cualidades = JSON.parse(response.candidate.habilidades);
                    this.setState({ cualidades });
                }
            }
        } else {
            this.setState({ error: response.error, loading: false });
        }

        this.setState({ loadingState: true });
        const dataVideo = { id: user.id.id, tipo: user.id.tipo };
        const videosData = await request.post('/users/videos', dataVideo);
        if (videosData) {
            this.setState({ message: videosData.message });
            if (videosData.empty) {
                this.setState({ emptyVideo: videosData.empty, loadingState: false });
            } else {
                this.setState({ videos: videosData.video, emptyVideo: false });
                const dataVideos = videosData.video.slice(0, 2).map((item) => item);
                const twelveVideos = videosData.video.slice(0, 12).map((item) => item);
                this.setState({ items: dataVideos, emptyVideo: false, loadingState: false, itemsE: twelveVideos });
            }
        }

        const dataOffer = { id: user.id.id };
        const offersData = await request.post('/offers/getAllById', dataOffer);
        if (offersData) {
            this.setState({ message: offersData.message });
            if (offersData.empty) {
                this.setState({ emptyOffer: offersData.empty, loadingState: false });
            } else if (offersData.offers) {
                this.setState({ ofertas: offersData.offers, emptyOffer: false });
                const dataOffers = offersData.offers.slice(0, 2).map((item) => item);
                const twelveOffers = offersData.offers.slice(0, 12).map((item) => item);
                this.setState({ itemsOffer: dataOffers, itemsOfferE: twelveOffers });
            }
        }
    }

    loadFlickity() {
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = 'https://unpkg.com/flickity@2/dist/flickity.pkgd.min.js';
        document.body.append(script);
    }

    handleVideos() {
        let myVideos = document.getElementById('my-videos');
        let myOffers = document.getElementById('my-offers');
        let videosTab = document.getElementById('my-videos-tab');
        let offersTab = document.getElementById('my-offers-tab');
        if (offersTab.classList.contains('active-tab')) {
            offersTab.classList.remove('active-tab');
            videosTab.classList.add('active-tab');
            this.setState({ first: true, items: [], itemsOffer: [], numItems: 1, videos: [], ofertas: [], numItemsOffer: 1, numItemsE: 11, itemsE: [], itemsOfferE: [] });
            this.getCandidate();
        }
        myOffers.classList.add('hidden');
        myVideos.classList.remove('hidden');
        this.setState({ addType: 1 });
    }

    handleOffers() {
        let myVideos = document.getElementById('my-videos');
        let myOffers = document.getElementById('my-offers');
        let videosTab = document.getElementById('my-videos-tab');
        let offersTab = document.getElementById('my-offers-tab');
        if (videosTab.classList.contains('active-tab')) {
            videosTab.classList.remove('active-tab');
            offersTab.classList.add('active-tab');
            this.setState({ first: true });
        }
        myVideos.classList.add('hidden');
        myOffers.classList.remove('hidden');
        this.setState({ addType: 2 });
    }
}

export default Consumer(ProfileEnterprise);