/**
 *  messages.jsx
 *  @version: 1.0.0
 *  @author: DWIT MÉXICO - Sergio
 *  @description: Componente de de la vista contenedora de mensajes de chat
 */

import React, { Component } from "react";
import socket from '../../core/socket';

class Messages extends Component {
  constructor(props) {
    super(props);
    this.state = {

    }
  }

  componentDidUpdate(prevProps, prevState) {
      var last = document.getElementById("chat_messages_last");
      if(last) {
        last.scrollIntoView({ behavior: 'smooth' });
        setTimeout(()=>{
            socket.emit('visto', {room: this.props.room, id: this.props.recibe.id});
        },500);
      };
  }

  render() {
    return (
      <div className="column" id="chat_messages">
        {this.props.messages.map((element, index) => {
          const lastScroll = index === (this.props.messages.length - 1)? 'chat_messages_last' : undefined;
          if (this.props.sId === element.id) {
            return (
              <React.Fragment key={index}>
                <div className="white-space-16" />
                <div className="message-me justify-end" id={lastScroll}>
                  <p className="auto text-left">
                    {element.imagen == 1? 
                        <a href={element.mensaje} target="_blank" rel="noopener noreferrer" className="responsive-img auto justify-center align-center">
                          <img src={element.mensaje} className="cover-img auto" alt="Imagen enviada" title="Imagen enviada"></img>
                        </a>
                        :element.mensaje
                    }
                  </p>
                </div>
              </React.Fragment>
            );
          } else {
            return (
              <React.Fragment key={index}>
                <div className="white-space-16" />

                <div className="message-user justify-start" id={lastScroll}>
                  <p className="auto text-right">
                    {element.imagen == 1? 
                        <a href={element.mensaje} target="_blank" rel="noopener noreferrer" className="auto responsive-img justify-center align-center">
                            <img src={element.mensaje} className="auto cover-img" alt="Imagen enviada" title="Imagen enviada" />
                        </a>
                        :element.mensaje
                    }
                  </p>
                </div>
              </React.Fragment>
            );
          }
        })}
        <div className="white-space-8" />
      </div>
    );
  }
}

export default Messages;
