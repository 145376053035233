/**
 *  chats.jsx
 *  @version: 1.0.0
 *  @author: DWIT MÉXICO - Kath
 *  @description: cuentas bloqueadas
*/

import React, { Component } from 'react';
import { HeaderBack } from '../components/header/header';

class Blocked extends Component {

    render() {
        return(
            <div className="settings blocked column justify-center align-center">
                <HeaderBack />
                <div className="container justify-center">
                    <div className="column blocked-container">
                        <div className="row blocked-list">
                            <div className="column blocked-column justify-start align-start">
                                <div className="image column align-center justify-center">
                                    <div className="responsive-img justify-start align-center">
                                        <img src="https://blogs-images.forbes.com/brianrashid/files/2017/06/Forbes-Pic.jpg" alt="Imagen de perfil" title="Imagen de perfil" className="image-cover" />
                                    </div>
								</div>
                            </div>
                            <div className="column justify-center align-start">
                                <div className="row">
                                     <h4>
                                        Catalina Aristizábal
                                    </h4>
                                </div>
                                <div className="row justify-end">
                                     <div className="column">
                                        <h5 className =" color-purple">
                                            Cuenta bloqueada
                                        </h5>
                                     </div>
                                   
                                </div>
                            </div>
                        </div>
                        <hr />                       
                    </div>
                </div>
            </div>
        );
    }

}

export default Blocked;