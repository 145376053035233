/**
 *  company.jsx
 *  @version: 1.0.0
 *  @author: DWIT MÉXICO - Sergio
 *  @description: Card de Empresas
*/

import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Consumer } from '../../context';
import shave from 'shave';

class CardCompany extends Component {

    passVariable() {
        this.props.history.push(`/empresa/${this.props.empresa.id_empresa}`);
    }

    render() {
        let nombre = '', ciudad = '', imagen = '', sector = '';
        if (this.props.empresa) {
            nombre = this.props.empresa.nombre;
            ciudad = this.props.empresa.ciudad;
            imagen = this.props.empresa.imagen;
            sector = this.props.empresa.sector;
            shave('.card-name', 22);
        }

        return (
            <div className="card-company" onClick = { this.passVariable.bind(this) }>
                <div className="head">
                    <div className="logo responsive-img align-center">
                        <img src={imagen} alt="Logo Empresa" title="Logo" />
                    </div>
                </div>
                <div className="info column">
                    <div className="title justify-center column">
                        <h4 className="font-regular text-center card-name">
                            {nombre}
                        </h4>
                    </div>
                    <div className="white-space-8"></div>
                    <div className="align-center padding">
                        <i className="far fa-compass font-mini"></i>&nbsp;
                        <p className="font-mini">
                            {ciudad}
                        </p>
                    </div>
                    <div className="white-space-8"></div>
                    <div className="align-center padding">
                        <i className="far fa-object-group font-mini"></i>&nbsp;
                        <p className="font-mini sector">
                            { sector }
                        </p>
                    </div>
                    <div className="white-space-8"></div>
                </div>
            </div>
        );
    }

    componentDidMount() {
        shave('.card-name', 22);
    }

}

export default withRouter(Consumer(CardCompany));