/**
 *  abilities.jsx
 *  @version: 1.0.0
 *  @author: DWIT MÉXICO - Sergio
 *  @description: Página de selección de habilidades
*/

import React, { Component } from 'react';
import Header from '../components/header/header';
import { Consumer } from '../context';
import MediaQuery from 'react-responsive';
import Request from '../core/httpClient';
import { Link } from 'react-router-dom';
const req = new Request();

class AbilitiesEdit extends Component {

    constructor(props) {
        super(props);
        this.state = {
            list: [], habilidades: [], listaIntereses: [], intereses: [], user: { id: 0, tipo: 0 }
        }
    }

    render() {
        return (
            <div className="abilities column">
                  <MediaQuery minDeviceWidth = { 768 }>
                    <div className="view-web column">
                        <header className="header-web-interest justify-center">
                            <div className="container">
                                <div className="header-search align-center">
                                    <div className="row justify-center align-center">
                                        <div className="column auto">
                                            <Link to={this.state.user.tipo.toString() === "1" ? "/ajustes/perfil/": "/ajustes/perfil/empresa"}>
                                                <i className="fas fa-angle-left fa-1x color-white back-icon font-regular"></i>
                                            </Link>
                                        </div>
                                        <div className="column">
                                            <h2 className="color-white font-regular">
                                                Intereses y cualidades
                                            </h2>
                                        </div>
                                        <div className="column">
                                        <div className="btn-container auto btn-splash justify-end">
                                            <button type="button" className="btn-web btn-border-white weight-bold font-small text-center color-white auto" onClick = { this.updateData.bind(this) }>
                                                Actualizar
                                            </button>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </header>
                        <div className="column justify-center align-center">
                            <div className="white-space-24"></div>
                            <div className="container row justify-center">

                                {this.state.user.tipo === 1 ?
                                <div className="justify-start">
                                    <div className="column">
                                        <h2 className="text-center">
                                                Selecciona tus intereses
                                            </h2>
                                            <div className="white-space-16"></div>
                                        <div className="conteiner-labels column">
                                           
                                            <div className="interests-options  wrap justify-arround">
                                                {this.state.listaIntereses.map((item, index) => 
                                                   
                                                    <div className={`photo responsive-img ${this.state.intereses.find((interes) => interes.name === item.name) ? 'active-option' : null}`} key={index} onClick={this.interestClick.bind(this, item.name)}>
                                                    <div className="tittle align-center justify-center ">
                                                    <h4 className="color-white font-small text-center">
                                                    {item.name}</h4>
                                                    </div>
                                                    <img className="img-gallery" src={item.image} alt="Imagen del influencer" title="Imagen del influencer" />
                                                    </div>
                                                )}
                                            </div>
                                            <div className="white-space-64"></div>
                                        </div>
                                    </div>
                                </div> : null }                          
                           
                                <div className="justify-center column-qualities">
                                    <div className="column">
                                        <h2 className="text-center">
                                            Selecciona tus {this.state.type === 1 ? "habilidades" : "cualidades"}
                                        </h2>
                                        <div className="white-space-16"></div>
                                        <div className="skill-list column">
                                            {this.state.list.map((data, index) =>
                                            <label className="checkbox-container" key={index}>{data.name}
                                                <input type="checkbox" id={index} onClick={this.handleClick.bind(this, data.name, index)} {...this.state.habilidades.find(inter => inter.name === data.name) ? document.getElementById(index).checked = true : null} />
                                                <span className="checkmark"></span>
                                            </label>)}
                                        </div>
                                        <div className="white-space-16"></div>
                                        <h2 className="text-center">
                                            Nivel de experiencia
                                        </h2>
                                        <div className="white-space-16"></div>
                                        <select id="experience" className="input">
                                            {this.state.experiencia ? document.getElementById(this.state.experiencia).selected = true : null}
                                            <option id="0-2" value="0-2">0-2 Años</option>
                                            <option id="2-5" value="2-5">2-5 Años</option>
                                            <option id="5+" value="5+">5 y +</option>
                                        </select>
                                    </div>
                                </div>
                             </div>
                        </div>
                    </div>
                </MediaQuery>
                <MediaQuery maxWidth = { 768 }>
                <Header back = { this.state.user.tipo.toString() === "1" ? "/ajustes/perfil": "/ajustes/perfil/empresa"} />
                <div className="white-space-32"></div>
                <div className="justify-center">
                    <div className="container column">
                        <h2 className="text-center">
                            {this.state.user.tipo === 1 ? "Selecciona tus habilidades" : "¿Cómo definirías tu empresa?"}
                        </h2>
                        <div className="white-space-16"></div>
                        <div className="skill-list column">
                            {this.state.list.map((data, index) =>
                            <label className="checkbox-container" key={index}>{data.name}
                                <input type="checkbox" id={index} onClick={this.handleClick.bind(this, data.name, index)} {...this.state.habilidades.find((hab) => hab.name === data.name) ? document.getElementById(index).checked = true : null} />
                                <span className="checkmark"></span>
                            </label>)}
                        </div>
                        <div className="white-space-16"></div>
                        <h2 className="text-center">
                            {this.state.user.tipo === 1 ? "Selecciona tu experiencia" : "Antigüedad"}
                        </h2>
                        <div className="white-space-16"></div>
                        <select id="experience" className="input">
                            {this.state.experiencia ? document.getElementById(this.state.experiencia).selected = true : null}
                            <option id="0-2" value="0-2">0-2 Años</option>
                            <option id="2-5" value="2-5">2-5 Años</option>
                            <option id="5+" value="5+">5 y +</option>
                        </select>
                    </div>
                </div>
                {this.state.user.tipo === 1 ?
                <div className="justify-center">
                    <div className="container column">
                        <div className="white-space-8"></div>
                        <div className="conteiner-labels column">
                            <div className="white-space-16"></div>
                            <h2 className="text-center">
                                Intereses
                            </h2>
                            <div className="white-space-16"></div>
                            <div className="soft-skills wrap justify-arround">
                                {this.state.listaIntereses.map((item, index) => 
                                    <div className={`user-label auto ${this.state.intereses.find((interes) => interes.name === item.name) ? 'active-label' : null}`} key={index} onClick={this.interestClick.bind(this, item.name)}>
                                    <h5 className="font-mini">
                                        {item.name}
                                    </h5>
                                </div>
                                )}
                            </div>
                            <div className="white-space-64"></div>
                        </div>
                    </div>
                </div> : null}
                <div className="btn-container justify-center" id="btn-top">
                    <button type="button" className="btn btn-lightBlue btn-square color-darkBlue weight-bold text-center" onClick = { this.updateData.bind(this) }>
                        ACTUALIZAR
                    </button>
                </div>
                </MediaQuery>
            </div>
        );
    }

    componentDidMount() {
        this.loadListData();
        //MapLocation
		this.props.context.mapLocation(window.location.pathname);
    }

    async loadListData() {
        const user = await this.props.context.loadUser();
        if (user) { this.setState({ user: user.id }); }

        const res = await req.get(`/habilidades/${user.id.tipo.toString() === '1' ? 'candidatos' : 'empresas'}/${user.id.id}`);
        if (res.error) {
            console.log("Message: ", res.message);
        } else {
            if (res.habilidades) {
                if (user.id.tipo === 1) {
                    this.setState({ list: res.habilidades[2].habilidades });
                    this.setState({ listaIntereses: res.habilidades[4].intereses }); 
                } else {
                    this.setState({ list: res.habilidades[3].cualidades });
                }
            }

            if (res.selected) { this.setState({ habilidades: res.selected }); }

            if (res.intereses) { this.setState({ intereses: res.intereses }); }

            if (res.experiencia) { this.setState({ experiencia: res.experiencia }); }
        }
    }

    handleClick(data, index) {
        const newData = document.getElementById(index).checked;
        let habilidades = this.state.habilidades;
        const idHabilidad = habilidades.findIndex(item => item.name === data);
        if (newData === false && idHabilidad >= 0) {
            habilidades = habilidades.filter((item, index) => index !== idHabilidad);
        } else {
            habilidades.push({ name: data });
        }
        this.setState({ habilidades });
    }

    interestClick(interes) {
        let firstListData = this.state.intereses;
        const idInteres = firstListData.findIndex(data => data.name === interes);
        if (idInteres >= 0) {
            firstListData = firstListData.filter((item, index) => index !== idInteres);
        } else {
            firstListData.push({ name: interes });
        }
        this.setState({ intereses: firstListData });
    }

    async updateData() {
        if (this.state.habilidades.length <= 0) {
            alert("SELECCIONA POR LO MENOS UNA OPCIÓN");
            this.setState({ message: 'SELECCIONA POR LO MENOS UNA OPCIÓN', loading: false });
            return;
        }

        let experiencia = '', data = '';
        const exp = document.getElementById('experience');
        if (exp) { experiencia = exp.value; }
        if (this.state.user.tipo === 1) {
            data = {
                habilidades: JSON.stringify(this.state.habilidades),
                intereses: JSON.stringify(this.state.intereses),
                experiencia,
                id: this.state.user.id,
                tipo: this.state.user.tipo
            }
        } else {
            data = {
                habilidades: JSON.stringify(this.state.habilidades),
                experiencia,
                id: this.state.user.id,
                tipo: this.state.user.tipo
            }
        }
        
        const res = await req.post(`/habilidades/update/${data.tipo.toString() === '1' ? 'candidato' : 'empresa'}`, data);
        this.setState({ message: res.message });
        this.props.context.interestUpdated(true);

        if (res.updated) {
            setTimeout(() => {
                this.props.history.push(`/ajustes/perfil/${data.tipo.toString() === '1' ? '' : 'empresa'}`)
            }, 2000)
        }
    }

}

export default Consumer(AbilitiesEdit);