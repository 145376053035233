/**
 *  candidates.jsx
 *  @version: 1.0.0
 *  @author: DWIT MÉXICO - Sergio
 *  @description: Card de Candidatos
*/

import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import HolderImage from '../../img/user.png';
import { Consumer } from '../../context';
import shave from 'shave';

class CardCandidates extends Component {

    passVariable() {
        this.props.history.push(`/candidato/${this.props.candidato.id_candidato}`);
    }

    render() {
        let nombre = '', ciudad = '', imagen = '', funcion = '';
        if (this.props.candidato) {
            nombre = this.props.candidato.nombre;
            ciudad = this.props.candidato.ciudad;
            funcion = this.props.candidato.funcion;
            shave('.card-name', 22);
            if (this.props.candidato.imagen) {
                imagen = this.props.candidato.imagen;
            } else {
                imagen = HolderImage;
            }
        }

        return (
            <div className="card-company card-candidate" onClick={this.passVariable.bind(this)}>
                <div className="head">
                    <div className="logo responsive-img align-center">
                        <img src={imagen} alt="HP Logo" title="HP Logo" className="cover-img" />
                    </div>
                </div>
                <div className="info column">
                    <div className="title column">
                        <h4 className="font-regular padding card-name">
                            {nombre}
                        </h4>
                    </div>
                    <div className="white-space-8"></div>
                    <div className="align-center padding">
                        <i className="far fa-compass font-mini"></i>&nbsp;
                        <p className="font-mini">
                            {ciudad}
                        </p>
                    </div>
                    <div className="white-space-8"></div>
                    <div className="align-center padding">
                        <i className="far fa-object-group font-mini"></i>&nbsp;
                        <p className="font-mini">
                            {funcion}
                        </p>
                    </div>
                    <div className="white-space-8"></div>
                </div>
            </div>
        );
    }

    componentDidMount() {
        shave('.card-name', 22);
    }

}

export default withRouter(Consumer(CardCandidates));