/**
 *  upload-summary.jsx
 *  @version: 1.0.0
 *  @author: DWIT MÉXICO - Sergio
 *  @description: Subida de CV
*/

import React, { Component } from 'react';
import Request from "../core/httpClient";
import Header from '../components/header/header';
import { Consumer } from '../context';
const request = new Request();

class UploadSummary extends Component {

    constructor(props) {
        super(props);
        this.state = {
            userData: { id: 0, tipo: 0 }, file: ''
        }
    }

    render() {
        return(
            <div className="upload-summary column">
                <Header  back = "/perfil/candidato" />
                <form className="content column justify-center align-center" onSubmit = { this.handleSubmit.bind(this) }>
                    <div className="icon justify-center">
                        <i className="fas fa-upload color-white fa-2x"></i>
                    </div>
                    <div className="white-space-16"></div>
                    <div className="btn-container justify-center">
                        {
                            this.state.loading ? 
                            (
                                <div className="justify-center">
                                    <i className="fas fa-spin fa-spinner color-white"></i>
                                </div>
                            )
                            :
                            (
                                this.state.file ?
                                (
                                    <div className="column">
                                        <div className="justify-center">
                                            <p className="color-white">
                                                { this.state.file.name }
                                            </p>
                                        </div>
                                        <div className="white-space-8"></div>
                                        <div className="justify-center">
                                            <button type="submit" className="btn btn-radius btn-large btn-darkBlue weight-bold color-white">
                                                CARGAR CV
                                            </button>
                                        </div>
                                        <div className="white-space-8"></div>
                                        <div className="justify-center">
                                            <div className="divider"></div>
                                        </div>
                                        <div className="white-space-8"></div>
                                        <div className="justify-center">
                                            <p className="color-white font-mini" onClick = { this.handleRemplace.bind(this) }>
                                                Cambiar documento
                                            </p>
                                        </div>
                                    </div>
                                )
                                :
                                (
                                    <button type="button" className="btn btn-radius btn-large btn-darkBlue weight-bold color-white" onClick={this.handleFile.bind(this)}>
                                        SELECCIONAR CV
                                    </button>
                                )
                            )
                        }
                        <input type="file" name="cv" className="hidden" id="input-cv" accept="application/msword, text/plain, application/pdf" onChange = { this.handleChange.bind(this) } />
                    </div>
                </form>
            </div>
        );
    }

    componentDidMount() {
        this.load();
        this.props.context.mapLocation(window.location.pathname);
    }

    async load() {
        const user = await this.props.context.loadUser();
        if (user) { this.setState({ userData: user.id }); }
    }

    handleFile() {
        document.getElementById('input-cv').click();
    }

    handleRemplace() {
        this.setState({ file: '' });
    }

    handleChange(event) {
        const file = event.target;
        if (file.files[0].type === 'application/msword' || file.files[0].type === 'text/plain' || file.files[0].type === 'application/pdf' ) {
            this.setState({ file: file.files[0] });
        } else {
            this.setState({ file: false });
            alert("TIPO DE DOCUMENTO NO ADMITIDO");
        }
    }

    async handleSubmit(event) {
        event.preventDefault();
        this.setState({ loading: true });
        const form = event.target;
        const storageRef = window.firebase.storage().ref();
        let dateTime = new Date();
        let fileName = dateTime.getFullYear() + "-" + (dateTime.getMonth() + 1) + "-" + dateTime.getDate() + "-" + dateTime.getHours() + "/" + dateTime.getMinutes() + ":" + dateTime.getSeconds() + ":" + dateTime.getMilliseconds();
        const ref = storageRef.child(`resumes/${fileName}`);
        const resumeURL = await ref.put(form.cv.files[0]).then(async snapshot => {
            const url = await snapshot.ref.getDownloadURL().then(downloadURL => {
                return downloadURL;
            });
            return url
        });
        const data = { id: this.state.userData.id, url: resumeURL };
        const response = await request.post("/candidates/upload/cv", data);
        if (response.error) {
            this.setState({ message: response.message });
        } else {
            if (response.update) {
                this.setState({ message: response.message, loading: false });
                setTimeout(() => {
                    if (this.state.userData.tipo.toString() === "1") {
                        this.props.history.push('/perfil/candidato');
                    } else {
                        this.props.history.push('/perfil/empresa');
                    }
                }, 1500);
            }
        }
    }

}

export default Consumer(UploadSummary);