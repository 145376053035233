/**
 *  my-offers.jsx
 *  @version: 1.0.0
 *  @author: DWIT MÉXICO - Sergio
 *  @description: Card de Ofertas de empresa
*/

import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Consumer } from '../../context';
import shave from 'shave';

class CardMyOffers extends Component {

    render() {
        let titulo = '', ciudad = '', candidatos = 0, salario = '';
        if (this.props.oferta) {
            titulo = this.props.oferta.titulo;
            ciudad = this.props.oferta.ciudad;
            candidatos = this.props.oferta.candidatos;
            salario = this.props.oferta.salario;
            shave('.card-name', 22);
        }

        return (
            <div className="card-company card-my-offers column">
                <div className="row">
                    <div className="info column">
                        <div className="title column">
                            <h4 className="font-regular padding card-name">
                                {titulo}
                            </h4>
                        </div>
                        <div className="white-space-8"></div>
                        <div className="align-center padding">
                            <i className="far fa-compass font-mini"></i>&nbsp;
                            <p className="font-mini">
                                {ciudad}
                            </p>
                        </div>
                        <div className="white-space-8"></div>
                        <div className="align-center padding">
                            <i className="fas fa-euro-sign font-mini"></i>&nbsp;
                            <p className="font-mini">
                                { salario ? salario : 'A convenir' }
                            </p>
                        </div>
                    </div>
                    <div className="quick justify-center align-center column">
                        <div className="column">
                            <h4 className="text-center"> { candidatos } </h4>
                            <p className="text-center"> Candidatos </p>
                        </div>
                    </div>
                </div>
                <div className="white-space-8"></div>                
                <div className="justify-center">
                { this.props.desktop ?  

                       <button className="btn btn-lightBlue font-tiny weight-bold color-darkBlue text-center" onClick={this.handleEditOfferDesk.bind(this)} >
                       <i className="fas fa-pen"></i>&nbsp; EDITAR
                        </button>
                        :  
                        <button className="btn btn-lightBlue font-tiny weight-bold color-darkBlue text-center" onClick={this.handleEditOffer.bind(this)} >
                        <i className="fas fa-pen"></i>&nbsp; EDITAR
                        </button>
                    }
                    
                    <button className="btn btn-darkBlue font-tiny weight-bold color-white" onClick = { this.handleDeleteOffer.bind(this) } >
                        <i className="fas fa-times"></i>&nbsp; ELIMINAR
                    </button>
                </div>
            </div>
        );
    }

    componentDidMount() {
        shave('.card-name', 22);
    }

    handleEditOffer() {
        this.props.history.push(`/oferta/${this.props.oferta.id_oferta}/editar/`);
    }

    handleEditOfferDesk() {
        let modalNewsLetter = document.getElementById('modal-edit-offer');  
        modalNewsLetter.classList.remove('fadeOut');
        modalNewsLetter.classList.add('animated', 'fadeIn','faster');
        modalNewsLetter.classList.remove('hidden');
        this.props.setEditOfferID(this.props.oferta.id_oferta);
        shave('.card-name', 22);
    }

    handleDeleteOffer() {
        let modal = document.getElementById('modal-delete-offer');
        if (modal && modal.classList.contains('hidden')) {
            modal.classList.remove('hidden', 'fadeOut');
            modal.classList.add('fadeIn');
        }
        this.props.context.setDeleteItem(this.props.oferta.id_oferta);
    }
}

export default withRouter(Consumer(CardMyOffers));