import React, { Component } from 'react';
import Header from '../components/header/header';
import MediaQuery from 'react-responsive';
import { Link } from 'react-router-dom';
import ModalVideoAdd from '../components/modals/AddVideo';
import ModalOfferAdd from '../components/modals/offerAdd';
import Request from '../core/httpClient';
import { Consumer } from '../context';

const req = new Request();

class CategoriesEdit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            firstList: [],
            secondList: [],
            categoria: '',
            user: { id: 0, tipo: 0 }
        }
    }

    componentDidMount() {
        this.load();
    }

    async load() {
        const user = await this.props.context.loadUser();
        if (user) {
            this.setState({ user: user.id });
        }

        const res = await req.get(`/areas/${user.id.tipo.toString() === '1' ? 'candidatos' : 'empresas'}/${user.id.id}`);
        if (res.error) {
            console.log("Erro code: ", res.message);
        } else {
            if (res.categorias) {
                if (user.id.tipo === 1) {
                    this.setState({ firstList: res.categorias[0].funciones });
                } else if (user.id.tipo === 2) {
                    this.setState({ firstList: res.categorias[1].sectores });
                }
            }
            if (res.selected) {
                this.setState({ categoria: res.selected });
            }
        }
    }

    /*handleClick(categoria) {
        let firstListData = this.state.categorias;
        const idCat = firstListData.findIndex(data => data.name === categoria);
        if (idCat >= 0) {
            firstListData = firstListData.filter((categoria, index) => index !== idCat);
        } else {
            firstListData.push({ name: categoria });
        }
        this.setState({ categorias: firstListData });
    }*/

    handleClick(categoria) {
        this.setState({ categoria });
    }

    async categoriesEdit() {
        this.setState({ loading: true });
        if (this.state.categoria === '') {
            alert("SELECCIONA UNA OPCIÓN");
            this.setState({ message: 'SELECCIONA UNA OPCIÓN', loading: false });
            return;
        } else {
            const data = {
                categoria: this.state.categoria,
                id: this.state.user.id,
                tipo: this.state.user.tipo
            }
            const res = await req.post(`/areas/update/${data.tipo.toString() === '1' ? 'candidato' : 'empresa'}`, data);
            this.setState({ message: res.message });
            this.props.context.interestUpdated(true);
            if (res.updated) {
                this.setState({ loading: false });
                setTimeout(() => {
                    this.props.history.push(`/ajustes/perfil/${data.tipo.toString() === '1' ? '' : 'empresa'}`)
                }, 2000)
            }
        }
    }

    render() {
        return (
            <div className="interests column">
                <MediaQuery minDeviceWidth = { 766 }>
                    <div className="view-web column">
                        <header className="header-web-interest justify-center">
                            <div className="container">
                                <div className="header-search align-center">
                                    <div className="row justify-center align-center">
                                        <div className="column auto">
                                            <Link to={this.state.user.tipo.toString() === "1" ? "/ajustes/perfil" : "/ajustes/perfil/empresa"}>
                                                <i className="fas fa-angle-left fa-1x color-white back-icon font-regular"></i>
                                            </Link>
                                        </div>
                                        <div className="column">
                                            <h2 className="color-white font-regular">{this.state.user.tipo.toString() === "1" ?  'Funciones' : "Sectores"}</h2>
                                        </div>
                                        <div className="column">
                                        <div className="btn-container auto btn-splash justify-end">
                                            <button type="button" className="btn-web btn-border-white weight-bold font-small text-center color-white auto" onClick = {this.categoriesEdit.bind(this)}>
                                                Actualizar
                                            </button>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </header>
                        <div className="column justify-center align-center">
                            <div className="container row">
                                <div className="column">
                                    <div className="white-space-24"></div>
                                    <h2 className="text-center">
                                        Selecciona {this.state.user.tipo.toString() === "1" ? "tu área de especialidad" : "tu sector"}
                                    </h2>
                                    <div className="white-space-16"></div>
                                    <div className="justify-start wrap interests-options">
                                            {
                                            this.state.firstList.map((categoria, index) => {
                                                return (
                                                    <div className={`interests-element column justify-center align-center ${this.state.categoria === categoria.name ? 'active' : null}`} key={index} onClick={this.handleClick.bind(this, categoria.name)}>
                                                        <div className="icon justify-center">
                                                            <i className={`fas fa-${categoria.icon} fa-2x color-darkBlue`}></i>
                                                        </div>
                                                        <div className="white-space-8"></div>
                                                        <p className="text-center">
                                                            {categoria.name}
                                                        </p>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                             </div>
                        </div>
                    </div>
                </MediaQuery>
                <MediaQuery maxWidth = { 768 }>
                    <Header back={this.state.user.tipo.toString() === "1" ? "/ajustes/perfil" : "/ajustes/perfil/empresa"} />
                    <div className="white-space-32"></div>
                    <div className="justify-center">
                        <div className="container column">
                            <h4 className="text-center weight-bold">
                                Selecciona {this.state.user.tipo === 1 ? "tu área de actividad" : "tu sector"}
                            </h4>
                            <div className="white-space-16"></div>
                            <div className="interests-container wrap justify-between">
                                {
                                    this.state.firstList.map((categoria, index) => {
                                        return (
                                            <div className={`interests-element column justify-center align-center ${this.state.categoria === categoria.name ? 'active' : null}`} key={index} onClick={this.handleClick.bind(this, categoria.name)}>
                                                <div className="icon justify-center">
                                                    <i className={`fas fa-${categoria.icon} fa-2x color-darkBlue`}></i>
                                                </div>
                                                <div className="white-space-8"></div>
                                                <p className="text-center">
                                                    {categoria.name}
                                                </p>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                            <div className="white-space-24"></div>
                            <div className="btn-container justify-center" id="btn-top">
                                <button type="button" className="btn btn-lightBlue btn-square color-darkBlue weight-bold text-center" onClick={this.categoriesEdit.bind(this)}>
                                    ACTUALIZAR
                                </button>
                            </div>
                        </div>
                    </div>
                </MediaQuery>
                <ModalVideoAdd></ModalVideoAdd>
            <ModalOfferAdd></ModalOfferAdd>
            </div>
        );
    }
}

export default Consumer(CategoriesEdit);