/**
 *  bottombar.jsx
 *  @version: 1.0.0
 *  @author: DWIT MÉXICO - Kath
 *  @description: Menu Lateral ajustes
*/

import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Consumer } from "../../context";
import ModalDeleteAccount from '../../components/modals/deleteAccount';

class LeftBar extends Component {    
    
    constructor(props) {
        super(props);
        this.state = {
            userData: { id: 0, tipo: 0 },
        }
    }

    render() {
        let active = '';
        if (this.props.option) { active = this.props.option ; }
        let tipo = '';
        if (this.state.userData.tipo) { tipo = this.state.userData.tipo.toString(); }
    
        return (
            <div className="column container-main-left align-start">
            <div className="left-main column">
                <Link to = { tipo === "1" ? "/ajustes/perfil" : "/ajustes/perfil/empresa" } className="link">
                    <div className = { active === "1" ? "row element-main-active justify-start align-center": "row element-main justify-start align-center" }>
                        <div className="row">
                        <div className="icons-column justify-center aling-center">
                            <i className="fas fa-user icon-main font-regular text-tittle"></i>
                        </div>
                        <div className="tittle-column align-start justify-start">
                            <h5 className="weight-bold text-tittle tittle-main">
                                Mi perfil
                            </h5>
                        </div>
                        </div>
                    </div>
                </Link>
                <Link to="/ajustes/password" className="link">
                    <div className = { active === "2" ? "row element-main-active justify-start align-center": "row element-main justify-start align-center" }>
                       <div className="row">
                        <div className="icons-column justify-center aling-center">
                            <i className="fas fa-key icon-main font-regular text-tittle"></i>
                        </div>
                        <div className="tittle-column align-start justify-start">
                            <h5 className="weight-bold tittle-main text-tittle">
                                Contraseña
                            </h5>
                        </div>
                        </div>
                    </div>
                </Link>         
                    <Link to="/ajustes/suscripcion" className="link">
                        <div className = { active === "3" ? "row element-main-active justify-start align-center": "row element-main justify-start align-center" }>
                            <div className="icons-column justify-center aling-center">
                                <i className="fas fa-receipt icon-main font-regular text-tittle"></i>
                            </div>
                            <div className="tittle-column align-start justify-start">
                                <h5 className="weight-bold tittle-main text-tittle">
                                    Suscripción
                                </h5>
                            </div>
                        </div>
                    </Link>                
                <Link to="/ajustes/seguridad" className="link">
                    <div className = { active === "4" ? "row element-main-active justify-start align-center": "row element-main justify-start align-center" }>
                        <div className="icons-column justify-center aling-center">
                            <i className="fas fa-globe icon-main font-regular text-tittle"></i>
                        </div>
                        <div className="tittle-column align-start justify-start">
                            <h5 className="weight-bold tittle-main text-tittle">
                                Seguridad y Privacidad
                            </h5>
                        </div>
                    </div>
                </Link>
                <Link to="/ajustes/legalidad" className="link">
                    <div className= { active === "5" ? "row element-main-active justify-start align-center": "row element-main justify-start align-center" }>    
                        <div className="icons-column justify-center aling-center">
                            <i className="fas fa-user-tie icon-main font-regular text-tittle"></i>
                        </div>
                        <div className="tittle-column align-start justify-start">
                            <h5 className="weight-bold tittle-main text-tittle">
                                Legalidad
                            </h5>
                        </div>
                    </div>
                </Link>
                
                    <div className= { active === "6" ? "row element-main-active justify-start align-center": "row element-main justify-start align-center" } onClick={this.deleteAccount.bind(this)} >
                        <div className="icons-column justify-center aling-center">
                            <i className="fas fa-user-times icon-main font-regular text-tittle"></i>
                        </div>
                        <div className="tittle-column align-start justify-start">
                            <h5 className="weight-bold tittle-main text-tittle">
                                Eliminar Cuenta
                            </h5>
                        </div>
                    </div>
            
                <div className= "row element-main justify-start align-center" onClick={ this.handleLogOut.bind(this.props, this) }>
                    <div className="icons-column justify-center aling-center">
                        <i className="fas fa-sign-out-alt icon-main font-regular text-tittle color-pink"></i>
                    </div>
                    <div className="tittle-column align-start justify-start">
                        <h5 className="weight-bold tittle-main text-tittle color-pink">
                            Cerrar sesión
                        </h5>
                    </div>
                </div>
            </div> 
            < ModalDeleteAccount userData = { this.state.userData } context = { this.props.context }></ModalDeleteAccount>
        </div> 
   );   
    }    
    handleLogOut(contextValue) {
        contextValue.props.context.logOut();
        window.location = "/";
    }

    deleteAccount() {
        const modal = document.getElementById('modal-delete-account');
        if (modal) {
            if (modal.classList.contains('hidden')) {
                modal.classList.remove('fadeOut');
                modal.classList.add('animated', 'fadeIn');
                modal.classList.remove('hidden');
            }
        }
    }

    componentDidMount() {
        this.load();
    }

    async load() {
        const user = await this.props.context.loadUser();
        if (user) {
            this.setState({ userData: user.id });
        }
    }
    
}

export default Consumer(LeftBar);