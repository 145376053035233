/**
 *  fav-company.jsx
 *  @version: 1.0.0
 *  @author: DWIT MÉXICO - Sergio
 *  @description: Card de favoritos de Empresa
*/

import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Consumer } from '../../context';
import HolderImage from '../../img/user.png';
import Request from "../../core/httpClient";
import shave from 'shave';
const request = new Request();

class CardFavoriteCompany extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: { id: 0, tipo: 0 }
        }
    }

    passVariable() {
        this.props.history.push(`/empresa/${this.props.favorito.id_empresa}`);
    }

    async deleteFavorite() {
        const user = await this.props.context.loadUser();
        if (user) { this.setState({ user: user.id }); }

        const data = {
            tipo_user: user.id.tipo,
            id_user: user.id.id,
            tipo: 2,
            id_elemento: this.props.favorito.id_empresa
        };

        const res = await request.post('/favorites/set', data);
        if (res.error) {
            console.log(res.message);
        } else {
            this.setState({ fav: !this.state.fav });
        }
        this.props.load();
    }

    render() {
        let nombre = '', ciudad = '', imagen = '', sector = '';
        if (this.props.favorito) {
            nombre = this.props.favorito.nombre;
            ciudad = this.props.favorito.ciudad;
            sector = this.props.favorito.sector;
            shave('.card-name', 22);
            if (this.props.favorito.imagen) {
                imagen = this.props.favorito.imagen;
            } else {
                imagen = HolderImage;
            }
        }

        return (
            <div className="card-company card-company-fav">
                <div className="head"  onClick = { this.passVariable.bind(this) }>
                    <div className="logo responsive-img align-center justify-center">
                        <img src={imagen} alt="HP Logo" title="HP Logo" />
                    </div>
                </div>
                <div className="info column">
                    <div className="title column">
                        <h4 className="font-regular text-center card-name">
                            {nombre}
                        </h4>
                    </div>
                    <div className="white-space-8"></div>
                    <div className="align-center padding">
                        <i className="far fa-compass font-mini"></i>&nbsp;
                        <p className="font-mini">
                            {ciudad}
                        </p>
                    </div>
                    <div className="white-space-8"></div>
                    <div className="align-center padding">
                        <i className="far fa-object-group sector font-mini"></i>&nbsp;
                        <p className="sector">
                            { sector }
                        </p>
                    </div>
                    <div className="btn-container">
                        <div className="contact justify-center">
                            <Link to={`/chat/${this.props.favorito.id_empresa}`} className="font-mini weight-bold color-darkBlue">
                                <i className="fas fa-comments"></i>&nbsp; CHAT
                            </Link>
                        </div>
                        <div className="contact-remove justify-center">
                            <button className="btn-play" onClick={this.deleteFavorite.bind(this)}>
                                <i className="fas fa-times color-white"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    componentDidMount() {
        shave('.card-name', 22);
    }

}

export default withRouter(Consumer(CardFavoriteCompany));