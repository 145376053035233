export function mapLocation(location) {
	let prevLoc = window.localStorage.getItem('goworkers_location_map')
	if(prevLoc) {
		prevLoc = JSON.parse(prevLoc);
	} else {
		prevLoc = [];
	}
	if(prevLoc[prevLoc.length - 1] !== location) {
		prevLoc.push(location);
	}
	window.localStorage.setItem('goworkers_location_map', JSON.stringify(prevLoc));
	this.setState({prevLoc: prevLoc, prevLocation: prevLoc[prevLoc.length - 2]});
}

export function quitLocation(prevLoc) {
	if(prevLoc) {
		let removePrevLoc = prevLoc.slice(0, prevLoc.length - 2);
		window.localStorage.setItem('goworkers_location_map', JSON.stringify(removePrevLoc));
		this.setState({prevLoc: removePrevLoc})
	}
}