/**
 *  favorites.jsx
 *  @version: 1.0.0
 *  @author: DWIT MÉXICO - Sergio
 *  @description: Página de favoritos
*/

import React, { Component } from 'react';
import Header from '../components/header/header';
import HeaderWebHomeLoggedIn from '../components/header/header-desktop';
import CardFavoriteCompany from '../components/cards/fav-company';
import CardFavoriteVideo from '../components/cards/fav-video';
import CardFavoriteJob from '../components/cards/fav-job';
import CardFavoriteCandidate from '../components/cards/fav-candidate';
import MediaQuery from 'react-responsive';
import LeftBar from '../components/left-bar/left-bar';
import Tabs from '../components/tabs/tabs';
import { Consumer } from '../context';
import ModalOfferAdd from '../components/modals/offerAdd';
import ModalVideoAdd from '../components/modals/AddVideo';
import Request from "../core/httpClient";
const req = new Request();

class Favorites extends Component { 
    constructor(props) {
        super(props);
        this.state = {
            user: { id: 0, tipo: 0 },
            favoritos: [],
            tab: 'first',
            videos: [],
            items: [],
            numItems: 3,
            numItemsE: 11,
            loadingState: false,
            loadingScroll: false,
            first: true
        }
    }



    render() {

        return(
            <div className="favorites column">
            <MediaQuery minDeviceWidth = { 768 }>
                <div className="view-web column">
                    <HeaderWebHomeLoggedIn/>
                    <div className="row">
                        <LeftBar active="menuFavorites"/>
                        <div className="column">
                            <div className="row justify-center">
                                <div className="cards-container container  column align-center justify-center">
                                <div className="white-space-32"></div>
                                <Tabs tab={this.state.tab} first="Videos" second={this.state.user.tipo === 1 ? "Ofertas" : "Candidatos"} third={this.state.user.tipo === 1 ? "Empresas" : "Freelancers"} user={this.state.user} changeTab={this.changeTab.bind(this)} />
                                <div className="white-space-16"></div> 
                                    {this.state.loadingState ?
                                        <div className="justify-center">
                                            <i className="fas fa-spin fa-spinner"></i>
                                        </div> : ""}
                                    {this.state.empty ?
                                        <div className="column align-center">
                                            <div className="white-space-16"></div>
                                            <h3 className="text-center">
                                                No hay resultados.
                                            </h3>
                                        </div>
                                    :
                                        <div className="scrollE justify-center" onScroll={this.scrollItems.bind(this)}>
                                            <div className={this.state.tab === 'first'? "row list-videos wrap" : "row list-cards wrap" }>
                                                {this.state.firstE ? this.state.cardsE : this.displayItemsE()}
                                            </div>
                                        </div>}
                                    <div className="white-space-8"></div>
                                    {this.state.loadingScrollE ?
                                        <div className="justify-center">
                                            <i className="fas fa-spin fa-spinner"></i>
                                        </div> : ""}
                            
                                </div>
                            </div>                           
                        </div>
                    </div>
                    <ModalOfferAdd></ModalOfferAdd>
                    <ModalVideoAdd></ModalVideoAdd>
                </div>
                </MediaQuery>
            <MediaQuery maxWidth = { 768 }>
                <Header />
                <Tabs tab = { this.state.tab } first="Videos" second={this.state.user.tipo === 1 ? "Ofertas" : "Candidatos"} third={this.state.user.tipo === 1 ? "Empresas" : "Freelancers"} user = { this.state.user } changeTab = { this.changeTab.bind(this) } />
                <div className="white-space-8"></div>
                <div className="justify-center">
                    <div className="container justify-center header">
                        <h4>
                            Favoritos
                        </h4>
                    </div>
                </div>
                <div className="white-space-8"></div>
                {this.state.loadingState ?
                    <div className="justify-center">
                        <i className="fas fa-spin fa-spinner"></i>
                    </div> : ""}
                {this.state.empty ? 
                    <div className="column align-center">
                        <div className="white-space-16"></div>
                        <h3 className="text-center">
                            No existen favoritos.
                        </h3>
                    </div>
                :
                    <div className="justify-center">
                        <div className="vc container column" ref="iScroll">
                            {this.state.first ? this.state.cards : this.displayItems()}
                        </div>
                    </div>
                }
                <div className="white-space-8"></div>
                {this.state.loadingScroll ?
                    <div className="justify-center">
                        <i className="fas fa-spin fa-spinner"></i>
                    </div> : ""}
                <div className="white-space-32"></div>
            </MediaQuery>
            </div>
        );
    }

    componentDidMount() {
        this.load();

        if (this.refs.iScroll) {
            this.refs.iScroll.addEventListener("scroll", () => {
                if (this.refs.iScroll.scrollTop + this.refs.iScroll.clientHeight >= this.refs.iScroll.scrollHeight) {
                    this.loadMoreItems();
                    this.setState({ first: false });
                }
            });
        }

        if(window.localStorage.getItem('gow_ftab')) {
            this.setState({tab: window.localStorage.getItem('gow_ftab')})
        }
        this.props.context.mapLocation(window.location.pathname)
    }

    scrollItems(event) {
        if (event.target.scrollTop + event.target.clientHeight >= event.target.scrollHeight) {
            this.loadMoreItemsE();
            this.setState({ firstE: false });
        }
    }

    displayItems() {
        var items = [];
        if (this.state.tab === 'first') {
            for (let i = 0; i < this.state.numItems + 1; i++) {
                if (this.state.videos[i]) {
                    items.push(
                       
                    <CardFavoriteVideo video={this.state.videos[i]} key={this.state.videos[i].id_video}/> )
                }
            }
        } else if (this.state.tab === 'second') {
            if (this.state.user.tipo === 1) {
                for (let i = 0; i < this.state.numItems + 1; i++) {
                    if (this.state.videos[i]) {
                        items.push(
                        <CardFavoriteJob oferta={this.state.videos[i]} key={this.state.videos[i].id_oferta}/> )
                    }
                }
            } else {
                for (let i = 0; i < this.state.numItems + 1; i++) {
                    if (this.state.videos[i]) {
                        items.push(
                           
                        <CardFavoriteCandidate candidato={this.state.videos[i]} key={this.state.videos[i].id_candidato}/> )
                    }
                }
            }
        } else {
            if (this.state.user.tipo === 1) {
                for (let i = 0; i < this.state.numItems + 1; i++) {
                    if (this.state.videos[i]) {
                        items.push(
                            
                        <CardFavoriteCompany favorito={this.state.videos[i]} key={this.state.videos[i].id_empresa}/> );
                    }
                }
            } else {
                for (let i = 0; i < this.state.numItems + 1; i++) {
                    if (this.state.videos[i]) {
                        items.push(
                           
                                <CardFavoriteCandidate candidato={this.state.videos[i]}  key={this.state.videos[i].id_candidato} />
                        )
                    }
                }
            }
        }
        return items;
    }

    loadMoreItems() {
        if (this.state.numItems <= this.state.videos.length) {
            this.setState({ loadingScroll: true });
            setTimeout(() => {
                this.setState({ numItems: this.state.numItems + 4, loadingScroll: false });
            }, 3000);
        }
    }

    displayItemsE() {
        var items = [];
        if (this.state.tab === 'first') {
            for (let i = 0; i < this.state.numItemsE + 1; i++) {
                if (this.state.videos[i]) {
                    items.push(
                        <div key={this.state.videos[i].id_video}>
                            <CardFavoriteVideo video={this.state.videos[i]} />
                        </div>);
                }
            }
        } else if (this.state.tab === 'second') {
            if (this.state.user.tipo === 1) {
                for (let i = 0; i < this.state.numItemsE + 1; i++) {
                    if (this.state.videos[i]) {
                        items.push(
                            <div key={this.state.videos[i].id_oferta}>
                                <CardFavoriteJob oferta={this.state.videos[i]} />
                            </div>);
                    }
                }
            } else {
                for (let i = 0; i < this.state.numItemsE + 1; i++) {
                    if (this.state.videos[i]) {
                        items.push(
                            <div key={this.state.videos[i].id_candidato}>
                                <CardFavoriteCandidate candidato={this.state.videos[i]} />
                            </div>);
                    }
                }
            }
        } else {
            if (this.state.user.tipo === 1) {
                for (let i = 0; i < this.state.numItemsE + 1; i++) {
                    if (this.state.videos[i]) {
                        items.push(
                            <div key={this.state.videos[i].id_empresa}>
                                <CardFavoriteCompany favorito={this.state.videos[i]} />
                            </div>);
                    }
                }
            } else {
                for (let i = 0; i < this.state.numItemsE + 1; i++) {
                    if (this.state.videos[i]) {
                        items.push(
                            <div key={this.state.videos[i].id_candidato}>
                                <CardFavoriteCandidate candidato={this.state.videos[i]} />
                            </div>);
                    }
                }
            }
        }
        return items;
    }

    loadMoreItemsE() {
        if (this.state.numItemsE <= this.state.videos.length) {
            this.setState({ loadingScrollE: true });
            setTimeout(() => {
                this.setState({ numItemsE: this.state.numItemsE + 12, loadingScrollE: false });
            }, 3000);
        }
    }

    changeTab(tab) {
        window.localStorage.setItem('gow_ftab', tab);
        this.setState({ tab, cards: [], cardsE: [], first: true, firstE: true, numItems: 3, numItemsE: 11, elements: [], items: [] });
        this.load();
    }

    async load() {
        this.setState({ loading: true });
        const user = await this.props.context.loadUser();
        if (user) { this.setState({ user: user.id }); }

        let listItems = [], listItemsE = [], dataF = '', res = '', favoritos = '', favoritosE = '';

        this.setState({ loadingState: true, empty: false });
        if (this.state.tab === 'first') {
            dataF = { tipo_user: user.id.tipo, id_user: user.id.id, tipo: 1 };
        } else if (this.state.tab === 'second') {
            dataF = { tipo_user: user.id.tipo, id_user: user.id.id, tipo: 3 };
        } else {
            dataF = { tipo_user: user.id.tipo, id_user: user.id.id, tipo: 2 };
        }

        res = await req.post('/favorites/get', dataF);
        if (res.error) {
            console.log("Error en favoritos",res.message);
            this.setState({ loadingState: false });
        } else {
            if (res.favoritos) {
                const favoriteData = res.favoritos.slice(0, 4).map((item) => item);
                const favoriteDataE = res.favoritos.slice(0, 12).map((item) => item);
                this.setState({ videos: res.favoritos, loadingState: false, empty: false, items: favoriteData });
                listItems = favoriteData;
                listItemsE = favoriteDataE;
            }
        } 

        if (this.state.tab === 'first') {
            favoritos = listItems.map((item, index) => <CardFavoriteVideo key={index} video={item} load={this.load.bind(this)} />);
            favoritosE = listItemsE.map((item, index) => <CardFavoriteVideo key={index} video={item} load={this.load.bind(this)} />);
        } else if (this.state.tab === 'second') {
            if (user.id.tipo === 1) {
                favoritos = listItems.map((item, index) => <CardFavoriteJob key={index} oferta={item} load={this.load.bind(this)} />);
                favoritosE = listItemsE.map((item, index) => <CardFavoriteJob key={index} oferta={item} load={this.load.bind(this)} />);
            } else {
                favoritos = listItems.map((item, index) => <CardFavoriteCandidate key={index} candidato={item} load={this.load.bind(this)} />);
                favoritosE = listItemsE.map((item, index) => <CardFavoriteCandidate key={index} candidato={item} load={this.load.bind(this)} />);
            }
        } else {
            if (user.id.tipo === 1) {
                favoritos = listItems.map((item, index) => <CardFavoriteCompany key={index} favorito={item} load={this.load.bind(this)} />);
                favoritosE = listItemsE.map((item, index) => <CardFavoriteCompany key={index} favorito={item} load={this.load.bind(this)} />);
            } else {
                favoritos = listItems.map((item, index) => <CardFavoriteCandidate key={index} candidato={item} load={this.load.bind(this)} />);
                favoritosE = listItemsE.map((item, index) => <CardFavoriteCandidate key={index} candidato={item} load={this.load.bind(this)} />);
            }
        }

        if (favoritos.length !== 0) {
            this.setState({ empty: false });
            this.setState({ cards: favoritos, cardsE: favoritosE });
        } else {
            this.setState({ empty: true });
        }
    }
}

export default Consumer(Favorites);