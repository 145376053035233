import React, { Component } from 'react';

class ModalAlert extends Component {

    render() {
        return (
            <div className="modal column justify-center align-center wow hidden faster" id="modal-profileAlert">
                <div className="container modal-content align-center column" >
                    <div className="column modal-head">
                        <div className="white-space-64"></div>
                        <h3 className="text-center">
                            { this.props.title }
                        </h3>
                        <div className="white-space-8"></div>
                        <p className="text-center">
                            { this.props.description }
                        </p>
                        <div className="white-space-64"></div>
                    </div>
                </div>
            </div>
        );
    }

}

export default ModalAlert;