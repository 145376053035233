/**
 *  offer.jsx
 *  @version: 1.0.0
 *  @author: DWIT MÉXICO - Sergio
 *  @description: Badge de información en ofertas
*/

import React from 'react';

const BadgeOffer = (props) => (
    <div className="badge-offer auto">
        <p className="weight-semi font-mini color-darkBlue">
            { props.name }
        </p>
    </div>
);

export default BadgeOffer;