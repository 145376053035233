/**
 *  bottombar.jsx
 *  @version: 1.0.0
 *  @author: DWIT MÉXICO - Kath
 *  @description: Menu Lateral 
*/

import React, { Component } from 'react';
import HolderImage from '../../img/user.png';
import { Link } from 'react-router-dom';
import { Consumer } from '../../context';
import Request from "../../core/httpClient";
const request = new Request();

class LeftBar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: { id: 0, tipo: 0 }
        };
    }

    render() {
        let tipo = '', imagen = '';
        if (this.state.user) { tipo = this.state.user.tipo.toString(); }
        if ( this.state.imagen ) { imagen = this.state.imagen; } else { imagen = HolderImage; }
        return (
            <div className="left-bar justify-center">
                <div className="column main align-center">
                    
                    <div className="responsive-img justify-center align-center">
                    <Link to={tipo === '1' ? "/perfil/candidato" : "/perfil/empresa"}>
                        <img src = { imagen } alt="Imagen de perfil" title="Imagen de perfil" className="image-profile" />
                    </Link>
                    </div>
                    <div className="row item-left justify-center">
                        <Link to="/inicio">
                            <div className={`column item-bar justify-center align-center ${this.props.active === "menuHome" ? 'active-menu' : ''}` }  id="menuHome">
                                <i className="far fa-newspaper icon-menu"></i>
                                <div className="white-space-8"></div>
                                <h5 className="font-tiny">Inicio</h5>
                            </div>
                        </Link>
                    </div>
                    <div className="row item-left justify-center">
                        <Link to={tipo === '1' ? "/perfil/candidato" : "/perfil/empresa"}>
                            <div className = {`column item-bar justify-center align-center ${this.props.active === "menuProfile" ? 'active-menu' : ''}` }  id="menuProfile">
                                <i className="far fa-user icon-menu "></i>
                                <div className="white-space-8"></div>
                                <h5 className="font-tiny">Mi perfil</h5>
                            </div>
                        </Link>
                    </div>
                    <div className="row item-left justify-center">
                        <Link to={tipo === '1' ? "/postulaciones" : "/ofertas"}>
                            <div className = {`column item-bar justify-center align-center ${this.props.active === "menuOffers" ? 'active-menu' : ''}` }  id="menuOffers">
                                <i className="far fa-list-alt icon-menu"></i>
                                <div className="white-space-8"></div>
                                <h5 className="font-tiny">Mis ofertas</h5>
                            </div>
                        </Link>
                    </div>
                    <div className="row item-left justify-center">
                        <Link to="/favoritos">
                            <div className="column item-bar jsutify-center align-center"  className = {`column item-bar justify-center align-center ${this.props.active === "menuFavorites" ? 'active-menu' : ''}` }  id="menuFavorites">
                                <i className="far fa-heart icon-menu"></i>
                                <div className="white-space-8"></div>
                                <h5 className="font-tiny">Favoritos</h5>
                            </div>
                        </Link>
                    </div>
                    <div className="row item-left justify-center">
                        <Link to="/chats">
                            <div  className="column item-bar jsutify-center align-center"  className = {`column item-bar justify-center align-center ${this.props.active === "menuChats" ? 'active-menu' : ''}` }  id="menuChats">
                                <i className="far fa-comments icon-menu"></i>
                                <div className="white-space-8"></div>
                                <h5 className="font-tiny">Chats</h5>
                            </div>
                        </Link>
                    </div>
                    <div className="row item-left justify-center">
                        <Link to={ tipo === '2'? "/ajustes/perfil/empresa" : "/ajustes/perfil"}>
                            <div  className="column item-bar jsutify-center align-center"  className = {`column item-bar justify-center align-center ${this.props.active === "menuSettings" ? 'active-menu' : ''}` }  id="menuSettings">
                                <i className="far fa-edit icon-menu"></i>
                                <div className="white-space-8"></div>
                                <h5 className="font-tiny">Ajustes</h5>
                            </div>
                        </Link>
                    </div>
                </div>
            </div>
        );   
    }

    componentDidMount() { 
        this.load();
    }

    async load() {
        const user = await this.props.context.loadUser();
        if (user) { 
            this.setState({ user: user.id });
            this.loadData();
        }
    }

    async loadData() {
        const data = { id: this.state.user.id, tipo: this.state.user.tipo };
        const response = await request.post('/users/menu', data);
        let tipo = this.state.user.tipo.toString();
        if (response) {
            if(tipo === '2'){
                this.setState({ imagen: response.menu.imagen });
            }
            else {
                this.setState({ imagen: response.menu.imagen });
            }
        }
        this.setState({ loading: false });

        const allMenuItems = document.getElementsByClassName('item-bar');
        for (let index = 0; index < allMenuItems.length; index++) {
            allMenuItems[index].classList.remove('active-menu');
        }

        switch (this.props.active) {
            case 'menuHome':
                document.getElementById(this.props.active).classList.add('active-menu');
                break;
            case 'menuProfile':
                document.getElementById(this.props.active).classList.add('active-menu');
                break;
            case 'menuOffers' :
                document.getElementById(this.props.active).classList.add('active-menu');
                break;
            case 'menuFavorites':
                document.getElementById(this.props.active).classList.add('active-menu');
                break;
            case 'menuChats':
                document.getElementById(this.props.active).classList.add('active-menu');
                break;
            case 'menuSettings':
                document.getElementById(this.props.active).classList.add('active-menu');
                break;
            default:
                break;
        }
        
    }

    
}

export default  Consumer(LeftBar);