/**
 *  socket.js
 *  @version: 1.0.0
 *  @author: DWIT MÉXICO - Sergio
 *  @description: Gestión de ruta estática del Socket 
 */

import io from 'socket.io-client';

let socket;
if (process.env.NODE_ENV === "production") {
	socket = io("https://gowrokersdwitsites.herokuapp.com");
} else {
	socket = io('http://localhost:8081');
}

export default socket;