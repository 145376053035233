/**
 *  alert.jsx
 *  @version: 1.0.0
 *  @author: DWIT MÉXICO - Sergio
 *  @description: Modal de alerta o notificación
*/

import React from 'react';

const ModalAlert = (props) =>
    (
        <div className="modal modal-alert column justify-center hidden align-center wow animated faster" id="modal-alert">
            <div className="container modal-content align-center column" >
                <div className="column modal-head">
                    <div className="white-space-48"></div>
                    <h3 className="text-center">
                        {props.title}
                    </h3>
                    <div className="white-space-8"></div>
                    <p className="text-center">
                        {props.description}
                    </p>
                    <div className="white-space-48"></div>
                </div>
            </div>
        </div>
    );

export default ModalAlert;