/**
 *  profile-influencer.jsx
 *  @version: 1.0.0
 *  @author: DWIT MÉXICO - kath
 *  @description: PERFIL CANDIDATO VISTO DESDE EMPRESA
*/

import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import Header from '../components/header/header';
import HeaderWebHomeLoggedIn from '../components/header/header-desktop';
import MediaQuery from 'react-responsive';
import LeftBar from '../components/left-bar/left-bar';
import { Consumer } from '../context';
import { Helmet } from 'react-helmet';
import CardVideo from '../components/cards/video';
import HolderImage from '../img/user.png';
import ImageLoader from 'react-loading-image';
import LoadingImage from '../img/loading.gif';
import ModalOfferAdd from '../components/modals/offerAdd';
import ModalVideoAdd from '../components/modals/AddVideo';
import Request from "../core/httpClient";
const request = new Request();

class PublicProfileCandidate extends Component {

    constructor(props) {
        super(props);
        this.state = {
            candidate: '',  loading: '',  intereses: [],
            videos: [], items: [], first: true, numItems: 1,
            firstE: true, itemsE: [], loadingState: false, numItemsE: 11
        };
    }

    goChat() {
        this.props.history.push(`/chat/${this.state.candidate.id_candidato}`)
    }

    render() {
        let candidate = '', foto = '', intereses = '';
        if (this.state.loading) {
            candidate = '';
        } else {
            if (this.state.candidate) { candidate = this.state.candidate; }
        }
        this.state.candidate.foto_perfil_url ? foto = this.state.candidate.foto_perfil_url : foto = HolderImage
        if (this.state.intereses) { intereses = this.state.intereses; }

        return (
            <div className="profile-candidate column align-center">
                <Helmet>
                    <title>WAALA - Perfil</title>
                    <meta name="robots" content="noindex" />
                    <meta property="og:title" content="Go Workers - Perfil" />
                    <meta property="og:type" content="website" />
                </Helmet>
                <MediaQuery minDeviceWidth = { 768 }>
                    <div className="view-web column">
                        <HeaderWebHomeLoggedIn />
                        <div className="row">
                            <LeftBar/>
                            <div className="column">
                                <div className="row justify-center">
                                    <div className="container-profile  column align-center justify-center">
                                        <div className="white-space-32"></div>
                                            <div className="row header-profile">
                                                <div className="column profile-column profile-public justify-center align-center">
                                                    <div className="responsive-img justify-start align-start">
                                                        <img src={foto} alt="" className="profile-image" />
                                                    </div>
                                                </div>
                                                <div className="column column-info justify-start align-center">
                                                    <div className="white-space-16"></div>
                                                    <div className="row">
                                                        <h2 className="weight-bold">{candidate.nombre}</h2>
                                                    </div>                           
                                                    <div className="white-space-16"></div>
                                                    <div className="row wrap">
                                                        <i className="fas fa-medal font-Large color-darkBlue icon-header"></i>
                                                        <p className="text-start font-regular text-abilities">{candidate.funcion}</p>
                                                    </div>
                                                    <div className="white-space-16"></div>
                                                    <div className="row">
                                                        <i className="fas fa-map-marker-alt font-Large color-darkBlue icon-header"></i>
                                                        <p className="text-start font-regular">{candidate.ciudad}</p> 
                                                    </div>
                                                    <div className="white-space-24"></div>
                                                    <div className="row justify-start">                            
                                                        <div className="btn-container-public justify-center">
                                                            <a href = {candidate.curriculum_url} target="_blank" rel="noopener noreferrer" className="btn-publi-border-2 text-center color-darkBlue">
                                                                <i className="fas fa-file-alt font-regular icon-btn color-darkBlue"></i>  
                                                                Curriculum
                                                            </a>
                                                        </div>
                                                        <div className="btn-container-public justify-center">
                                                            <a href = {candidate.video_curriculum_url} target="_blank" rel="noopener noreferrer" className="btn-publi-border-2 text-center color-darkBlue">
                                                                <i className="fa fa-video font-regular icon-btn color-darkBlue"></i>  
                                                                Videocurriculum
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="column content-btns align-start justify-center">                                 
                                                    <div className="btn-container btn-public btn-darkBlue justify-center">
                                                        {
                                                            this.state.fav ?
                                                            <div className="column btn-public  text-center color-white" onClick={this.addFav.bind(this)}>
                                                                <i className="fas fa-heart icon-btn font-regular"></i>
                                                                <div className="white-space-8"></div>
                                                                <h5 className="color-white font-regular weight-bold">
                                                                    Eliminar favorito
                                                                </h5>
                                                            </div>
                                                        :
                                                            <div className="column btn-public  text-center color-white" onClick={this.addFav.bind(this)}>
                                                                <i className="far fa-heart icon-btn font-regular"></i>
                                                                <div className="white-space-8"></div>
                                                                <h5 className="color-white font-regular weight-bold">
                                                                    Añadir favorito
                                                                </h5>
                                                            </div>
                                                        }
                                                    </div>
                                                    <div className="white-space-16"></div>
                                                    <div className="btn-container btn-public btn-lightBlue justify-center">
                                                        <div className="column btn-public text-center color-darkBlue" onClick={this.goChat.bind(this)}>
                                                            <i className="fas fa-comment-dots icon-btn font-regular"></i>
                                                            <div className="white-space-8"></div>
                                                            <h5 className="color-darkBlue font-regular weight-bold">
                                                                Chat
                                                            </h5>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="white-space-16"></div>
                                            <div className="conteiner-labels column">
                                                <div className="white-space-8"></div>
                                                <div className="row row-labels justify-center wrap">
                                                    {
                                                        intereses.map((item, index) =>
                                                        <div className="user-label auto" key={index}>
                                                            <h5>
                                                                {item.name}
                                                            </h5>
                                                        </div>)
                                                    }
                                                </div>
                                            </div>               
                                        <div className="white-space-16"></div>
                                        <div className="gallery-profile" onScroll={this.scrollItemsE.bind(this)}>
                                            <div className={`row ${this.state.empty ? '' : 'list-videos' } wrap`}>
                                                {
                                                    this.state.empty ?
                                                        <div className="column align-center">
                                                            <div className="white-space-16"></div>
                                                            <h3 className="text-center">
                                                                No existen vídeos.
                                                            </h3>
                                                        </div>
                                                        :
                                                        this.state.firstE ? this.state.itemsE.map((item) =>
                                                            <CardVideo video={item} key={item.id_video} />
                                                        ) :
                                                        this.displayItemsE()
                                                }
                                            </div>
                                        </div>
                                        <div className="white-space-16"></div>
                                        {this.state.loadingStateE ? <i className="fas fa-spin fa-spinner"></i> : ""}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </MediaQuery>
                
                <MediaQuery maxWidth = { 768 }>
                    <Header back={this.props.context.prevLocation} />
                    <div className="container column">
                        <div className="candidate-cards column justify-start align-center">
                            <div className="image row">
                                <div className="column column-info justify-center align-center">
                                    <div className="row justify-center align-center">
                                        <h4 className="weight-bold text-center font-regular name">
                                            {candidate.nombre}
                                        </h4>
                                    </div>
                                    <div className="white-space-4"></div>
                                    <div className="column align-center">
                                        <h5 className="left text-center font-mini weight-semi age">
                                            {candidate.edad ? candidate.edad + " Años" : null}
                                        </h5>
                                    </div>
                                    <div className="white-space-4"></div>
                                    <div className="column align-center">
                                        <h5 className="text-center font-mini weight-semi city">
                                            {candidate.ciudad}
                                        </h5>
                                    </div>
                                    <div className="white-space-4"></div>
                                    <div className="column sector align-end justify-end">
                                        <h5 className="text-center weight-semi color-lightBlue uppercase">
                                            {candidate.funcion}
                                        </h5>
                                    </div>
                                </div>
                                <div className="profile-container column">
                                    <div className="align-center justify-center">
                                        <div className="responsive-img justify-center align-center">
                                            <ImageLoader src={foto} alt="Imagen del candidato" title="Imagen del candidato" className="image-profile"
                                                loading={() => <img className="image-profile" alt="Imagen del candidato" title="Imagen del candidato" src={LoadingImage} />} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="tabs-container justify-center">
                                <div className="column">
                                    <a href={candidate.curriculum_url} target="_blank" rel="noopener noreferrer" className="btn btn-square btn-pink text-center font-mini">
                                        <i className="color-darkBlue fas fa-file-alt"></i> Curriculum
                                    </a>
                                </div>
                                <div className="column">
                                    <a href={candidate.video_curriculum_url} target="_blank" rel="noopener noreferrer" className="btn btn-square btn-pink text-center font-mini">
                                        <i className="color-darkBlue fas fa-file-video"></i> Videocurriculum
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div className="vc column" onScroll={this.scrollItems.bind(this)}>
                            {this.state.loadingState ?
                                <div className="justify-center">
                                    <i className="fas fa-spin fa-spinner"></i>
                                </div> : ""}
                            {this.state.empty ? 
                                <div className="column align-center">
                                    <div className="white-space-16"></div>
                                    <h3 className="text-center">
                                        No existen videos.
                                    </h3>
                                </div>
                            :
                                this.state.first ? this.state.items.map((item) =>
                                    <CardVideo key={item.id_video} video={item} />) :
                                    this.displayItems()
                            }
                        </div>
                        <div className="white-space-8"></div>
                        {this.state.loadingScroll ? 
                        <div className="justify-center">
                            <i className="fas fa-spin fa-spinner"></i>
                        </div> : ""}
                    </div>
                    <div className="white-space-64"></div>
                    <div className="btn-container justify-center fixed-contact">
                        <button className="btn btn-lightBlue weight-bold color-darkBlue" onClick={this.goChat.bind(this)} >
                            <i className="fas fa-comments"></i> Chat
                        </button>
                        {this.state.fav ?
                            <button className="btn btn-darkBlue weight-bold color-lightBlue" onClick={this.addFav.bind(this)}>
                                <i className="fas fa-heart"></i> Eliminar favorito
                            </button>
                        :
                            <button className="btn btn-darkBlue weight-bold color-lightBlue" onClick={this.addFav.bind(this)}>
                                <i className="far fa-heart"></i> Añadir favorito
                            </button>}
                    </div>
                </MediaQuery>
                <ModalVideoAdd></ModalVideoAdd>
                <ModalOfferAdd></ModalOfferAdd>  
            </div>
        );
    }

    componentDidMount() {
        this.load();
        //MapLocation
        this.props.context.mapLocation(window.location.pathname);
    }

    scrollItems(event) {
        if (event.target.scrollTop + event.target.clientHeight >= event.target.scrollHeight) {
            this.loadMoreItems();
            this.setState({ first: false });
        }
    }

    scrollItemsE(event) {
        if (event.target.scrollTop + event.target.clientHeight >= event.target.scrollHeight) {
            this.loadMoreItemsE();
            this.setState({ firstE: false });
        }
    }

    displayItems() {
        var items = [];
        for (let i = 0; i < this.state.numItems + 1; i++) {
            if (this.state.videos[i]) {
                items.push(
                    <CardVideo key={this.state.videos[i].id_video} video={this.state.videos[i]} />);
            }
        }
        return items;
    }

    loadMoreItems() {
        if (this.state.numItems <= this.state.videos.length) {
            this.setState({ loadingScroll: true });
            setTimeout(() => {
                this.setState({ numItems: this.state.numItems + 2, loadingScroll: false });
            }, 3000);
        }
    }

    displayItemsE() {
        var items = [];
        for (let i = 0; i < this.state.numItemsE + 1; i++) {
            if (this.state.videos[i]) {
                items.push(
                    <CardVideo video={this.state.videos[i]} key={this.state.videos[i].id_video}/>
                );
            }
        }
        return items;
    }

    loadMoreItemsE() {
        if (this.state.numItemsE <= this.state.videos.length) {
            this.setState({ loadingStateE: true });
            setTimeout(() => {
                this.setState({ numItemsE: this.state.numItemsE + 12, loadingStateE: false });
            }, 3000);
        }
    }

    async load() {
        const user = await this.props.context.loadUser();
        if (user) {
            this.setState({ userData: user.id });
        }

        this.setState({ loading: true, loadingState: true });
        const id_candidato = this.props.match.params.id;

        const data = { id_candidato: id_candidato, id_empresa: user.id.id };
        const response = await request.post("/candidates/get/public", data);
        if (response && !response.error) {
            if (response.empty) {
                this.setState({ empty: true, loading: false });
            } else {
                this.setState({ candidate: response.candidate, freelancer: response.candidate.freelancer, fav: response.candidate.favorito, loading: false });
                const intereses = JSON.parse(response.candidate.intereses);
                this.setState({ intereses });
                this.loadFlickity();
            }
        } else {
            this.setState({ error: response.error, loading: false });
        }      

        const dataVideo = { id: id_candidato, tipo: 1 };
        const videosData = await request.post('/users/videos', dataVideo);
        if (videosData) {
            this.setState({ message: videosData.message });
            if (videosData.empty) {
                this.setState({ empty: videosData.empty, loadingState: false });
            } else {
                this.setState({ videos: videosData.video, loadingState: false });
                const dataVideos = videosData.video.slice(0,2).map((item) => item);
                const twelveVideos = videosData.video.slice(0, 12).map((item) => item);
                this.setState({ items: dataVideos, itemsE: twelveVideos  });
            }
        }
    }

    async addFav() {
        const idCandidato = parseInt(this.props.match.params.id);
        let data = '';
        if (this.state.freelancer === 1) {
            data = {
                tipo_user: this.state.userData.tipo,
                id_user: this.state.userData.id,
                tipo: 2, id_elemento: idCandidato
            };
        } else {
            data = {
                tipo_user: this.state.userData.tipo,
                id_user: this.state.userData.id,
                tipo: 3, id_elemento: idCandidato
            };
        }

        const res = await request.post('/favorites/set', data);
        if (res.error) {
            console.log("Error añadiendo favoritos");
        } else {
            this.setState({ fav: !this.state.fav });
        }
    }

    loadFlickity() {
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = 'https://unpkg.com/flickity@2/dist/flickity.pkgd.min.js';
        document.body.append(script);
    }

}

export default withRouter(Consumer(PublicProfileCandidate));