/**
 *  deleteOffer.jsx
 *  @version: 1.0.0
 *  @author: DWIT MÉXICO - Sergio
 *  @description: Modal de confirmación de eliminarción de oferta
*/

import React, { Component } from 'react';
import { Consumer } from '../../context';
import Request from "../../core/httpClient";
const request = new Request();

class ModalConfirmDeleteOffer extends Component {

    render() {
        return (
            <div className="modal modal-confirm column justify-center align-center wow animated hidden" id="modal-delete-offer">
                <div className="container modal-content align-center column" >
                    <div className="column modal-head">
                        <div className="white-space-48"></div>
                        <h3 className="text-center">
                            {this.props.title}
                        </h3>
                        <div className="white-space-8"></div>
                        <p className="text-center">
                            {this.props.description}
                        </p>
                        <div className="white-space-24"></div>
                        <div className="btn-container-modal">
                            <button className="btn btn-lightBlue color-darkBlue text-center weight-bold" onClick={this.handlePositive.bind(this)}>
                                <i className="fas fa-check color-darkBlue"></i> SI
                            </button>
                            <button className="btn btn-darkBlue color-white text-center weight-bold" onClick={this.handleNegative.bind(this)}>
                                <i className="fas fa-times color-white"></i> NO
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    async handlePositive() {
        //const id_video = JSON.parse(window.localStorage.getItem('item'));
        const idDelete = this.props.context.idDelete;
        console.log("idVideo: ", idDelete);

        const data = { id: idDelete };
        const deleteVideo = await request.post('/offers/delete/offer', data);
        if (deleteVideo) {
            console.log("Mensaje: ", deleteVideo.message);
        } 

        let modal = document.getElementById('modal-delete-offer');
        setTimeout(() => {
            modal.classList.add('hidden');
        }, 550);
        this.props.load();
    }

    handleNegative() {

        // Deshacer algo y cerrar modal

        let modal = document.getElementById('modal-delete-offer'); // Tu ID
        if (modal) {
            modal.classList.remove('fadeIn');
            modal.classList.add('fadeOut');
            setTimeout(() => {
                modal.classList.add('hidden');
            }, 550);
        }
    }

}

export default Consumer(ModalConfirmDeleteOffer);