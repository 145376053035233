/**
 *  header-desktop.jsx
 *  @version: 1.0.0
 *  @author: DWIT MÉXICO - Sergio
 *  @description: Header escritorio
*/

import React, { Component } from 'react';
import ButtonAdd from './header-button-add';
import ButtonVideo from './header-button-video';
import ButtonSearch from './header-button-search';
import Logo from '../../img/isotipo.png';
import { Consumer } from '../../context';

class HeaderWebHomeLoggedIn extends Component {

    constructor(props) {
        super(props);
        this.state = {
            userData: { id: 0, tipo: 0 }, ready: false
        };
    }

    render() {
        return (
            <header className="header-logged justify-center">
                <div className="container">
                    <div className="header-search align-center">
                        <div className="row justify-center align-center">
                            <div className="column">
                                <div className="justify-start align-center">
                                    <img className="logo" src={Logo} alt="Logo Waala" title="Logo Waala" />
                                </div>
                            </div>
                            <div className="justify-end btn-last">
                                <ButtonSearch />
                                <ButtonVideo />
                                {
                                    this.state.ready ? 
                                    (
                                        this.state.userData.tipo !== 1 ? (<ButtonAdd />):(null)
                                    ) : (null)
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        );
    }

    componentDidMount() {
        this.load();
    }

    async load() {
        this.setState({ loading: true });
        const user = await this.props.context.loadUser();
        if (user) {
            this.setState({ userData: user.id, ready: true });
        }
    }

}

export default Consumer(HeaderWebHomeLoggedIn);