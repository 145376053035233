/**
 *  home.jsx
 *  @version: 1.0.0
 *  @author: DWIT MÉXICO - Kath, Sergio
 *  @description: vista de ajustes seguridad y privacidad
*/

import React, { Component } from 'react';
import HeaderWebHomeLoggedIn from '../components/header/header-desktop';
import LeftBar from '../components/left-bar/left-bar';
import MediaQuery from 'react-responsive';
import LeftBarSetting from '../components/left-bar/left-settings-bar';
import Contact from '../components/modals/contact';
import ModalOfferAdd from '../components/modals/offerAdd';
import ModalVideoAdd from '../components/modals/AddVideo';
import { Consumer } from '../context';

class SettingsSecurity extends Component {
    render() {
        return (
            <div className="settings column">
                <MediaQuery minDeviceWidth = { 768 }>
                    <HeaderWebHomeLoggedIn />
                    <div className="row">
                        <LeftBar active = "menuSettings"/>
                        <div className="column align-center">
                        <div className="white-space-32"></div>
                            <div className="row-responsive container">
                                <LeftBarSetting link="/ajustes/perfil" option = "4"/>
                                <div className="column">
                                    <form className="column">                               
                                        <div className="row">
                                            <div className="column right-main content-forms align-center">
                                                <h4>
                                                    Seguridad y Privacidad
                                                </h4>
                                                <div className="white-space-8"></div>
                                                <div className="input-container align-center justify-center">
                                                    <i className="fas fa-shield-alt font-medium color-darkBlue"></i>
                                                    <div className="input input-profile" onClick = { this.handleContact.bind(this) }>
                                                        Enviar comentarios o reportar
                                                    </div>
                                                </div>
                                                <div className="white-space-8"></div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Contact />
                    <ModalOfferAdd></ModalOfferAdd>
                    <ModalVideoAdd></ModalVideoAdd>
                </MediaQuery>
            </div>
        );
    }

    componentDidMount() {
        this.load();
    }

    async load() {
        const user = await this.props.context.loadUser();
        if (user) {
            this.setState({ userData: user.id });
        }
    }

    handleContact() {
        const modal = document.getElementById('modal-contact');
        if (modal) {
            if (modal.classList.contains('hidden')) {
                modal.classList.remove('fadeOut');
                modal.classList.add('animated', 'fadeIn');
                modal.classList.remove('hidden');
            }
        }
    }

}

export default Consumer(SettingsSecurity);