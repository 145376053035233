/**
 *  edit-video.jsx
 *  @version: 1.0.0
 *  @author: DWIT MÉXICO - Sergio
 *  @description: Página de edición de vídeo
*/

import React, { Component } from 'react';
import { HeaderBack } from '../components/header/header';
import { Consumer } from '../context';
import ModalAlert from '../components/modals/alert';
import Request from '../core/httpClient';

const request = new Request();

class EditVideo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userData: { id: 0, tipo: 0 },
            id_video: '', video: '', title: ''
        }
    }

    render() {
        return (
            <div className="upload-summary column">
                <HeaderBack back="/inicio" />
                <div className="content upload-video column justify-center align-center">
                    <div className="card-container container column justify-center align-center">
                        <h2 className="color-white">
                            Editar vídeo
                        </h2>
                        <div className="white-space-32"></div>
                        <p className="color-white weight-semi">
                            Título:
                        </p>
                        <div className="white-space-8"></div>
                        <input type="text" autoComplete="off" required name="title" className="input" minLength="4" maxLength="60" placeholder="Título del vídeo" id="title" value={this.state.title} onChange={event => this.setState({ title: event.target.value })} />
                        <div className="white-space-16"></div>
                        <div className="video-wrapper column" id="video-wrapper">
                            <div className="white-space-16"></div>
                            <p className="text-center font-tiny color-white weight-semi">
                                VISTA PREVIA
                            </p>
                            <div className="white-space-16"></div>
                            <video src={this.state.video} controls></video>
                        </div>
                        <div className="justify-center">
                            <h5 className="color-white">
                                {this.state.message}
                            </h5>
                        </div>
                        <div className="white-space-48"></div>
                    </div>
                </div>
                <div className="btn-container justify-center">
                    <button className="btn-full btn-lightBlue btn-large btn-bottom btn-bottom-light weight-bold" type="button" onClick={this.handleSubmit.bind(this)}>
                        PUBLICAR VÍDEO
                    </button>
                </div>
                <ModalAlert title="VÍDEO ACTUALIZADO" description="" />
            </div>
        );
    }

    componentDidMount() {
        this.load();
    }

    async load() {
        const user = await this.props.context.loadUser();
        if (user) {
            this.setState({ userData: user.id });
            this.validateVideo();
        }
    }

    async validateVideo() {
        this.setState({ loading: true });
        if (this.state.userData) {
            const data = { idVideo: parseInt(this.props.match.params.id), idUsuario: this.state.userData.id };
            const response = await request.post("/videos/validate", data);
            if (response && !response.error) {
                if (response.redirect) {
                    window.location = "/inicio";
                } else {
                    console.log(response);
                    this.setState({
                        id_video: response.video.id_video, video: response.video.video_url ,title: response.video.titulo, loading: false
                    });
                }
            } else {
                this.setState({ network: true });
            }
        } else {
            this.validateVideo();
        }
    }

    async handleSubmit() {
        let title = '';
        title = document.getElementById('title').value;
        this.setState({ loading: true });
        if (title.length > 6) {
            let data = { idVideo: parseInt(this.props.match.params.id), idUsuario: this.state.userData.id, titulo: title };
            const response = await request.post("/videos/update", data);
            if (response.error) {
                this.setState({ message: response.message });
                alert(response.message);
            } else {
                console.log(response);
                if (response.update) {
                    document.getElementById('modal-alert').classList.remove('hidden');
                    document.getElementById('modal-alert').classList.add('fadeIn');
                    if (this.state.userData.tipo === 1) {   
                        setTimeout(() => {
                            window.location = "/perfil/candidato"
                        }, 2000);
                    } else {
                        setTimeout(() => {
                            window.location = "/perfil/empresa"
                        }, 2000);
                    }
                }
            }
        } else {
            alert("Escribe un título adecuado");
            this.setState({ loading: false });
        }
    }

}

export default Consumer(EditVideo);