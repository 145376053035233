/**
 *  home.jsx
 *  @version: 1.0.0
 *  @author: DWIT MÉXICO - Sergio, Kath
 *  @description: Editar perfil de Candidato
*/

import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Consumer } from '../context';
import HolderImage from '../img/user.png';
import { Helmet } from 'react-helmet';
import HeaderWebHomeLoggedIn from '../components/header/header-desktop';
import ImageLoader from 'react-loading-image';
import ModalAlert from '../components/modals/profileAlert';
import Request from "../core/httpClient";
import MediaQuery from 'react-responsive';
import LeftBar from '../components/left-bar/left-bar';
import ModalVideoAdd from '../components/modals/AddVideo';
import LeftBarSetting from '../components/left-bar/left-settings-bar';
const request = new Request();

class ProfileEditCandidate extends Component {

	constructor(props) {
		super(props);
		this.state = {
			userData: { id: 0, tipo: 0 }, fotoPerfil: HolderImage,  ciudad: '',
			nombre: '', email: '', telefono: '', ciudades: [], modalTitle: '', modalDescription: ''
		};
	}    

	render() {
		let cities = [];
		if (this.state.ciudades.ciudades) {
			cities = this.state.ciudades.ciudades;
		}

		return (
			<div className="profile profile-settings column">
				<Helmet>
					<title>Go Workers - Editar Perfil</title>
					<meta name="robots" content="noindex" />
					<meta property="og:title" content="Go Workers - Editar Perfil" />
				</Helmet>
				<MediaQuery minDeviceWidth = { 768 }>
				<div className="settings view-web column">
					<HeaderWebHomeLoggedIn/>
					<div className="row">
						<LeftBar active="menuSettings"/>
						<div className="column align-center">
							<div className="white-space-32"></div>
							<div className="row-responsive container">
								<LeftBarSetting link="/ajustes/perfil" option = "1" tipo="1"/>
								<div className="column">
									<form className="column"  id="desktop-form">
										<div className="row">
											<div className="column right-main align-center">
											<div className="white-space-8"></div>
											<h4>Cambiar foto de perfil</h4>
											<div className="white-space-8"></div>
											<div className="content auto responsive-img justify-center align-center" onClick = { this.changeImage.bind(this) }>
												<img name="image"  src = { this.state.fotoPerfil ? this.state.fotoPerfil : HolderImage } alt="Foto de perfil" title="Foto de perfil" className="image-profile-edit" id="img-preview"/>
												<div className="overlay-image column align-center justify-center" >
													<i className="far fa-edit color-white fa-2x"></i>
												</div>
												<input type="file" name="img-src" id="img-src" className="hidden" onChange = { this.handleChange.bind(this) }/>
											</div>
											<div className="white-space-8"></div>
											</div>
										</div>
										<div className="white-space-8"></div>
										<div className="row">
											<div className="column right-main content-forms align-start">
												<h4 className="tittle">Información personal</h4>
												<div className="white-space-8"></div>
												<div className="input-container align-center justify-center">
													<i className="fas fa-user-edit  font-regular color-darkBlue"></i>
													<input type="text" name="name" placeholder="Nombre" className="input input-profile" required minLength="3" maxLength="60" value = { this.state.nombre } onChange = { event => this.setState({ nombre: event.target.value }) } />
												</div>
												<div className="white-space-8"></div>
												<div className="input-container align-center justify-center">
													<i className="fas fa-map-marked-alt font-regular color-darkBlue"></i>
													<i className="fas fa-sort-down font-tiny color-darkBlue icon-options"></i>
													<select name="city" className="input input-profile select-options" onChange = {event => this.setState({ ciudad: event.target.value })}>
															{cities.map((item) =>
															<option id={item.ciudad} key={item.ciudad} value={item.ciudad} {...this.state.ciudad ? this.state.ciudad === item.ciudad ? document.getElementById(item.ciudad).selected = true : null : null}>
																{item.ciudad}
															</option>
														)}
													</select>
												</div>                                   
												<div className="white-space-8"></div>
												<div className="input-container align-center justify-center"> 
													<i className="fab fa-facebook font-regular color-darkBlue"></i>
													<label name="facebook" className="input input-profile" >Conectar con cuenta de facebook</label>
												</div>
												<div className="white-space-8"></div>
												<div className="input-container align-center justify-center"> 
													<i className="fas fa-tasks font-regular color-darkBlue"></i>
													<Link to="/ajustes/categorias" className="input input-profile">Funciones</Link>
												</div>
												<div className="white-space-8"></div>
												<div className="input-container align-center justify-center"> 
													<i className="fas fa-tags font-regular color-darkBlue"></i>
													<Link to="/ajustes/habilidades" name="facebook" className="input input-profile">Habilidades e Intereses</Link>
												</div>
											</div>
										</div>
										<div className="white-space-8"></div>
										<div className="row">
											<div className="column right-main content-forms align-start">
												<h4 className="tittle">Información privada</h4>
												<div className="white-space-8"></div>
												<div className="input-container align-center justify-center">
													<i className="fas fa-globe font-regular color-darkBlue"></i>
													<input type="email" name="email" placeholder="Correo electrónico" className="input input-profile" value = { this.state.email } onChange = { event => this.setState({ email: event.target.value })} disabled = { true }/>
												</div>
												<div className="white-space-8"></div>
												<div className="input-container align-center justify-center">
													<i className="fas fa-phone font-regular color-darkBlue font-medium color-pink fa-flip-horizontal "></i>
													<input type="text" name="tel" placeholder="Teléfono" className="input input-profile" required minLength="8" maxLength="16"
													value = { this.state.telefono } onChange = { event => this.setState({ telefono: event.target.value }) }/>
												</div>
												<div className="white-space-8"></div>
											</div>
										</div>
										<div className="white-space-16"></div>
										<div className="btn-container auto btn-splash justify-center align-center">
											<button type="button" className="btn-web-forms btn-lightBlue font-smal text-center color-darkBlue  weight-bold" onClick = { this.updateUserData.bind(this, true) }>
												{
													this.state.loading || this.state.loadingData ? 
													(<i className="fas fa-spin fa-spinner font-small color-darkBlue i-right"></i>) :
													("ACTUALIZAR DATOS")
												}
											</button>
										</div>
										<div className="white-space-32"></div>
									</form>
								</div>
							</div>
						</div>
					</div>
				</div>
				</MediaQuery>
			   	<MediaQuery maxWidth = { 768 }>
					<form className="column" id="mobile-form">		
						<div className="profile-container column">
							<Link to="/ajustes">
								<i className="fas fa-times font-small color-white i-left"></i>
							</Link>
							<div className="image column align-center justify-center">
								<div className="responsive-img justify-center align-center">
									<ImageLoader src = { this.state.fotoPerfil ? this.state.fotoPerfil : HolderImage } 
										alt="Imagen del influencer" title="Imagen del influencer" className="image-profile" loading={() => <img className="image-profile" alt="Imagen del influencer" title="Imagen del influencer" src={HolderImage}/>} 
										image={(props) => <img {...props} name="image" id="img-preview" alt="Imagen del influencer" title="Imagen del influencer" className="image-profile" onClick={this.changeImage.bind(this)}></img>}/>
								</div>
							</div>
							<div className="row justify-center align-center">
								<h5>
									Cambiar foto de perfil
								</h5>
								<input type="file" name="img-src" id="img-src" className="hidden" onChange = { this.handleChange.bind(this) }/>
							</div>
						</div>
						<div className="justify-center">
							<div className="container column">
								<div className="white-space-16"></div>
								<div className="row justify-center align-center">
									<h5 className="weight-bold">
										Información personal
									</h5>
								</div>
								<div className="white-space-8"></div>
								<div className="column">
									<div className="basic-data column justify-center align-center">
										<div className="input-container align-center justify-center">
											<i className="fas fa-user-edit font-small color-darkBlue"></i>
											<input type="text" autoComplete="ÑÖcompletes" name="name" placeholder="Nombre" className="input input-profile" 
											required minLength="3" maxLength="60" value = { this.state.nombre } onChange = { event => this.setState({ nombre: event.target.value }) } />
										</div>
										<div className="white-space-8"></div>
										<div className="input-container align-center justify-center">
											<i className="fas fa-map-marked-alt font-small color-darkBlue"></i>
											<select name="city" className="input input-profile" onChange = {event => this.setState({ ciudad: event.target.value })}>
												{cities.map((item) =>
													<option id={item.ciudad} key={item.ciudad} value={item.ciudad} {...this.state.ciudad ? this.state.ciudad === item.ciudad ? document.getElementById(item.ciudad).selected = true : null : null}>
														{item.ciudad}
													</option>
												)}
											</select>
										</div>
									</div>
									<div className="white-space-8"></div>
									<div className="basic-data column justify-center align-center">
										<div className="input-container align-center justify-center">                       
											<i className="fab fa-facebook font-small color-darkBlue"></i>
											<label name="facebook" className="input input-profile-noline">Conectar con cuenta de facebook</label>
										</div>
									</div>
									<div className="white-space-8"></div>
									<div className="basic-data column justify-center align-center">
										<div className="input-container align-center justify-center"> 
											<i className="fas fa-tasks font-small color-darkBlue"></i>
											<Link to="/ajustes/categorias" className="input input-profile-noline">Área de actividad</Link>
										</div>
									</div>
									<div className="white-space-8"></div>
									<div className="basic-data column justify-center align-center">
										<div className="input-container align-center justify-center"> 
											<i className="fas fa-tags font-small color-darkBlue"></i>
											<Link to="/ajustes/habilidades" className="input input-profile-noline">Habilidades e Intereses</Link>
										</div>
									</div>
								
									<div className="white-space-8"></div>
									<div className="row justify-center align-center">
										<h5 className="weight-bold">
											Información privada
										</h5>
									</div>
									<div className="white-space-8"></div>
									<div className="basic-data column justify-center align-center">                                
										<div className="input-container align-center justify-center">
											<i className="fas fa-globe font-small color-darkBlue"></i>
											<input type="email" name="email" placeholder="Correo electrónico" className="input input-profile" value = { this.state.email } onChange = { event => this.setState({ email: event.target.value })} disabled = { true } />
										</div>
										<div className="white-space-8"></div>
										<div className="input-container align-center justify-center">
											<i className="fas fa-phone font-small color-darkBlue font-small fa-flip-horizontal "></i>
											<input type="number" name="tel" placeholder="Teléfono" className="input input-profile-noline" required minLength="8" maxLength="16" autoComplete="ÑÖcompletes" value = { this.state.telefono } onChange = { event => this.setState({ telefono: event.target.value }) } />
										</div>
									</div>
									<div className="white-space-16"></div>
									{
										this.state.loading ?
										(
											<div className="column">
												<div className="justify-center">
													<i className="fas fa-spin fa-spinner color-pink"></i>
												</div>
												<p className="text-center weight-semi">
													ACTUALIZANDO INFORMACIÓN
												</p>
											</div>
										)
										:
										(
											this.state.message ?
											(
												<div className="column">
													<p className="text-center weight-semi">
														{ this.state.loadingData ? this.state.loadingData : this.state.message }
													</p>
												</div>
											)
											:
											( 
												<p className="text-center weight-semi font-tiny">
													PULSE &nbsp;
													<i className="fas fa-check font-small color-pink"></i> PARA ACTUALIZAR
												</p>
											)
										)
									}
									<button type="button" onClick = { this.updateUserData.bind(this, false) }>
										{
											this.state.loading || this.state.loadingData ? 
											(<i className="fas fa-spin fa-spinner font-small color-white i-right"></i>) :
											(<i className = "fas fa-check font-small color-white i-right"></i>)
										}
									</button>
								</div>
							</div>
						</div>
					</form>
				</MediaQuery>
				<ModalAlert title = { this.state.modalTitle } description = { this.state.modalDescription } />
				<ModalVideoAdd></ModalVideoAdd>
			</div>
		);
	}

	changeImage() {
		console.log('click');
		document.getElementById("img-src").click();
		this.setState({ updateImage: true });
	}

	handleChange(event) {
		let resizedImage = "";
		const src = event.target;
		let fr = new FileReader();
		fr.onload = function (e) {
			const image = new Image();
			image.onload = function () {
				const canvas = document.createElement("canvas");
				const max_size = 600;
				let width = image.width;
				let height = image.height;
				if (width > height) {
					if (width > max_size) {
						height *= max_size / width;
						width = max_size;
					}
				} else {
					if (height > max_size) {
						width *= max_size / height;
						height = max_size;
					}
				}
				canvas.width = width;
				canvas.height = height;
				canvas.getContext("2d").drawImage(image, 0, 0, width, height);
				resizedImage = canvas.toDataURL("image/png");
				document.getElementById("img-preview").src = resizedImage;
				this.setState({ imagen: resizedImage });
			}.bind(this);
			image.src = e.target.result;
		}.bind(this);
		if (src.files.length > 0) {
			fr.readAsDataURL(src.files[0]);
		}
	}
	
	imageError(event) { event.target.src = "https://via.placeholder.com/600?text=Click+para.actualizar+imagen"; }

	componentDidMount() {
		this.load();
		//MapLocation
		this.props.context.mapLocation(window.location.pathname);
	}

	async load() {
		const user = await this.props.context.loadUser();
		if (user) {
			this.setState({ userData: user.id });
			this.loadUserData();
		}
	}
	
	async loadUserData() {
		const response = await request.get('/cities');
		if (response.ciudades) { 
			this.setState({ ciudades: response.ciudades });
		}

		this.setState({ loading: true, loadingData: true });
		const data = { id: this.state.userData.id, tipo: this.state.userData.tipo };
		const userData = await request.post("/users/profile", data);
		if (userData && userData.user && !userData.error) {
			this.setState({
				userId: userData.user.id_candidato, fotoPerfil: userData.user.foto_perfil_url, nombre: userData.user.nombre, ciudad: userData.user.ciudad, email: userData.user.email, telefono: userData.user.telefono, loading: false, loadingData: false
			});
		}
	}
	
	async updateUserData(desktop) {
		let form = '';
		if (desktop) {
			form = document.getElementById('desktop-form')
		} else {
			form = document.getElementById('mobile-form')
		}
		
		if (form.name.value.length > 2) {

			this.setState({ loading: true });
			if (this.state.updateImage) {
				if (form.image.src.includes('data:image')) {
					const storageRef = window.firebase.storage().ref();
					let dateTime = new Date();
					let fileName = dateTime.getFullYear() + "-" + (dateTime.getMonth() + 1) + "-" + dateTime.getDate() + "-" + dateTime.getHours() + "/" + dateTime.getMinutes() + ":" + dateTime.getSeconds() + ":" + dateTime.getMilliseconds();
					const ref = storageRef.child(`profile/${fileName}`);
					const imageURL = await ref.putString(form.image.src, 'data_url')
						.then(async snapshot => {
							const url = await snapshot.ref.getDownloadURL()
								.then(downloadURL => {
									return downloadURL;
								}
								);
							return url
						});
					const data = {
						idCandidato: this.state.userId, fotoPerfil: imageURL, nombre: form.name.value,
						ciudad: form.city.value, email: form.email.value, telefono: form.tel.value
					};
					const userDataUpdate = await request.post("/candidates/update/profile", data);
					if (userDataUpdate) {
						if (userDataUpdate.update) {
							this.setState({ message: userDataUpdate.message, update: userDataUpdate.update, modalTitle: 'DATOS ACTUALIZADOS', modalDescription: '' });
							let modal = document.getElementById('modal-profileAlert');
							if (modal.classList.contains('hidden')) {
								modal.classList.remove('fadeOut');
								modal.classList.add('animated', 'fadeIn');
								modal.classList.remove('hidden');
							}
							if (desktop) {
								setTimeout(() => {
									window.location = "/ajustes/perfil";
								}, 2000);
							} else {
								setTimeout(() => {
									window.location = "/ajustes";
								}, 2000);
							}
						} else {
							if (userDataUpdate.unavailable) {
								this.setState({ message: userDataUpdate.message, loading: false });
							}
						}
						this.setState({ loading: false });
					} else {
						this.setState({ message: userDataUpdate.message, loading: false });
					}
					this.setState({ loading: false });
				} else {
					this.setState({ message: 'Por favor selecciona una imagen valida' });
					return;
				}
			} else {
				const data = {
					idCandidato: this.state.userId, fotoPerfil: this.state.fotoPerfil, nombre: form.name.value,
					ciudad: form.city.value, email: form.email.value, telefono: form.tel.value
				};
				const userDataUpdate = await request.post("/candidates/update/profile", data);
				if (userDataUpdate) {
					if (userDataUpdate.update) {
						this.setState({ message: userDataUpdate.message, update: userDataUpdate.update, modalTitle: 'DATOS ACTUALIZADOS', modalDescription: '' });
						let modal = document.getElementById('modal-profileAlert');
						if (modal.classList.contains('hidden')) {
							modal.classList.remove('fadeOut');
							modal.classList.add('animated', 'fadeIn');
							modal.classList.remove('hidden');
						}
						if (desktop) {
							setTimeout(() => {
								window.location = "/ajustes/perfil";
							}, 2000);
						} else {
							setTimeout(() => {
								window.location = "/ajustes";
							}, 2000);
						}
					} else {
						if (userDataUpdate.unavailable) {
							this.setState({ message: userDataUpdate.message, loading: false });
						}
					}
					this.setState({ loading: false });
				} else {
					this.setState({ message: userDataUpdate.message, loading: false });
				}
				this.setState({ loading: false });
			}

		} else {
			this.setState({ modalTitle: "FALTAN DATOS", modalDescription: "Ingresa un nombre" });
			let modal = document.getElementById('modal-profileAlert');
			if (modal.classList.contains('hidden')) {
				modal.classList.remove('fadeOut');
				modal.classList.add('animated', 'fadeIn');
				modal.classList.remove('hidden');
			}
			setTimeout(() => {
				modal.classList.remove('fadeIn');
				modal.classList.add('fadeOut');
			}, 2000);
			setTimeout(() => {
				modal.classList.add('hidden');
			}, 2500);
		}	
	}
}

export default Consumer(ProfileEditCandidate);