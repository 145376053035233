/**
 *  register-brands.jsx
 *  @version: 1.0.0
 *  @author: DWIT MÉXICO - Sergio
 *  @description: Página de registro de marcas
*/

import React, { Component } from 'react';
import { HeaderSingle } from '../components/header/header';
import { Link } from 'react-router-dom';
import { Consumer } from '../context';
import Request from "../core/httpClient";
import MediaQuery from 'react-responsive';
import { HeaderWebLogin } from '../components/header/header';
import SplashImgWeb from '../img/workers.jpg';

class RegisterEnterprise extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ciudades: [],
            city: ''
        };
    }

    componentDidMount() {
        this.load();
    }

    async load() {
        const req = new Request();
        const res = await req.get('/cities');
        if (res.ciudades) {
            this.setState({ ciudades: res.ciudades });
        }
    }

    async hanldeSubmit(event) {
        event.preventDefault();
        this.setState({ loading: true, message: "" });
        let ciudad = '';
        const city = document.getElementById('city');
        if (city) {
            ciudad = city.value;
        }
        const form = event.target;
        const data = {
            Nombre: form.brand.value, Email: form.email.value, Password: form.password.value, Genero: '', Ciudad: ciudad, Tipo: 2, Freelancer: 0
        };
        this.props.context.saveRegister(data);
        this.props.history.push('/categorias');
    }

    async cityChange(e) {
        this.setState({ city: e.target.value });
    }

    render() {
        let cities = [];
        if (this.state.ciudades.ciudades) {
            cities = this.state.ciudades.ciudades;
        }

        return(
            <div className="register splash column">
                <MediaQuery minDeviceWidth = { 768 }>
                    <div className="view-web column">
                        <div className="background justify-center" style={{ backgroundImage: `url(${SplashImgWeb})` }}>
                            <div className="overlay justify-center column align-center">
                                <HeaderWebLogin />
                                <div className="container column align-center justify-center">
                                    <h1 className="color-white font-quadruple">Elige. Trabaja. Disfruta.</h1>
                                </div>
                            </div>
                        </div>
                        <div className="modal justify-center" id="modal-register">
                            <div className="container modal-content align-center column" >
                                <div className="white-space-48"></div>
                                <h1 className="text-center">
                                    Crea una cuenta de empresa
                                </h1>
                                <button className="auto" id="close-modal-register">
                                    <i className="fas fa-times"></i>
                                </button>
                                <div className="white-space-24"></div>
                                <form onSubmit={this.hanldeSubmit.bind(this)} className="column">
                                    <div className="input-container align-center">
                                        <i className="fas fa-ad font-small"></i>
                                        <input type="text" name="brand" placeholder="Nombre de empresa" required minLength="4" maxLength="50" className="input" />
                                    </div>
                                    <div className="white-space-8"></div>
                                    <div className="input-container align-center">
                                        <i className="fas fa-at font-small"></i>
                                        <input type="email" name="email" placeholder="Correo electrónico" className="input" required minLength="6" maxLength="64" />
                                    </div>
                                    <div className="white-space-8"></div>
                                    <div className="input-container align-center">
                                        <i className="fas fa-map-marked-alt font-small"></i>
                                        <select name="city" id="city" className="input" required onChange={this.cityChange.bind(this)}>
                                            {
                                                cities.map((item, index) =>
                                                    <option key={index} value={item.ciudad}>{item.ciudad}</option>
                                                )
                                            }
                                        </select>
                                    </div>
                                    <div className="white-space-8"></div>
                                    <div className="input-container align-center">
                                        <i className="fas fa-key font-small"></i>
                                        <input type="password" name="password" placeholder="Contraseña" className="input" minLength="8" maxLength="16" />
                                    </div>
                                    <div className="white-space-16"></div>
                                    <div className="btn-container justify-center">
                                        <button className="btn btn-lightBlue weight-bold font-regular color-darkBlue">
                                            CREAR CUENTA
                                        </button>
                                    </div>
                                    <div className="white-space-16"></div>
                                    <div className="justify-center">
                                        <Link to="/files" className="text-center font-mini">
                                            Al registrarte, aceptas los Términos y Condiciones de Uso de Go Workers.
                                        </Link>
                                    </div>
                                    <div className="white-space-8"></div>
                                    <div className="btn-container justify-center">
                                        <Link to="/" className="btn btn-darkBlue color-white text-center" id="btn-marcas">
                                            REGISTRO PARA CANDIDATOS
							            </Link>
                                    </div>
                                </form>
                                <div className="white-space-48"></div>
                            </div>
                        </div>
                    </div>
                </MediaQuery>

                <MediaQuery maxDeviceWidth = { 768 }>
                    <HeaderSingle />
                    <div className="white-space-24"></div>
                    <div className="justify-center">
                        <div className="container column forms">
                            <h2 className="text-center" id="h2-register-brand">
                                CREAR CUENTA EMPRESARIAL
                            </h2>
                            <div className="white-space-24"></div>
                            <form onSubmit={this.hanldeSubmit.bind(this)} className="column">
                                <div className="input-container align-center">
                                    <i className="fas fa-ad font-small"></i>
                                    <input type="text" name="brand" placeholder="Nombre de empresa" required minLength="4" maxLength="50" className="input" />
                                </div>
                                <div className="white-space-8"></div>
                                <div className="input-container align-center">
                                    <i className="fas fa-at font-small"></i>
                                    <input type="email" name="email" placeholder="Correo electrónico" className="input" required minLength="6" maxLength="64" />
                                </div>
                                <div className="white-space-8"></div>
                                <div className="input-container align-center">
                                    <i className="fas fa-map-marked-alt font-small"></i>
                                    <select name="city" id="city" className="input" required onChange={this.cityChange.bind(this)}>
                                        {
                                            cities.map((item, index) =>
                                                <option key={index} value={item.ciudad}>{item.ciudad}</option>
                                            )
                                        }
                                    </select>
                                </div>
                                <div className="white-space-8"></div>
                                <div className="input-container align-center">
                                    <i className="fas fa-key font-small"></i>
                                    <input type="password" name="password" placeholder="Contraseña" className="input" minLength="8" maxLength="16" />
                                </div>
                                <div className="white-space-16"></div>
                                <Link to="/files" className="text-center font-mini">
                                    Al registrarte, aceptas los Términos y Condiciones de Uso de Go Workers.
                                </Link>
                                <div className="white-space-16"></div>
                                <div className="btn-container justify-center">
                                    <button className="btn btn-lightBlue weight-bold font-regular color-darkBlue">
                                        CREAR CUENTA
                                    </button>
                                </div>
                            </form>
                            <div className="white-space-16" />
                            <div className="btn-container justify-center">
                                <Link to="/registro" className="btn btn-darkBlue color-white text-center" id="btn-marcas">
                                    REGISTRO PARA CANDIDATOS
							    </Link>
                            </div>
                        </div>
                    </div>
                    <div className="white-space-32"></div>
                </MediaQuery>
            </div>
        );
    }
}

export default Consumer(RegisterEnterprise);