/**
 *  header.jsx
 *  @version: 1.0.0
 *  @author: DWIT MÉXICO - Sergio, kath
 *  @description: Headers como componentes
*/

import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import Isotipo from '../../img/isotipo.png';
import ImageHolder from '../../img/user.png';
import Logo from '../../img/logo.png';
import ButtonLogin from '../header/header-button-login';
import HeaderButtonRegister from '../header/header-button-register';
import { Consumer } from '../../context';
import Request from "../../core/httpClient";
import ImageLoader from 'react-loading-image';
import LoadingImage from '../../img/loading.gif';

const request = new Request();

class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
            user: { id: 0, tipo: 0 }
        };
    }
    handleToSearch() {
        this.props.history.push(`/buscar/?search=`);
    }

    backPage(backPage, prevLoc) {
        this.props.context.quitLocation(prevLoc);
        this.props.history.push(backPage);
    }

    render() {
        let tipo = '', nombre = '', imagen = '';
        if (this.state.user) { tipo = this.state.user.tipo.toString(); }
        if (this.state.nombre) { nombre = this.state.nombre; }
        if (this.state.imagen) { imagen = this.state.imagen; } else { imagen = ImageHolder; }
        let backPage = '/inicio';
        if (this.props.context.prevLoc) {
            const prevLoc = this.props.context.prevLoc;
            backPage = prevLoc[prevLoc.length - 2];
        }
        return (
            <header className="header-single justify-center">
                <div className="container">
                    <div className="header-search align-center">
                        <div className="back auto align-center">
                        {
                            this.props.back ? 
                            (
                                <a onClick={this.backPage.bind(this, backPage, this.props.context.prevLoc)}>
                                    <i className="fas fa-angle-left color-white font-regular"></i>
                                </a>
                            )
                            :
                            (
                                <button id="btn-menu" onClick={this.appearMenu.bind(this)}>
                                    <i className="fas fa-bars color-white font-regular" id="btn-menu-icon"></i>
                                </button>
                            )
                        }
                        </div>
                        <div className="search-button justify-center">
                            <button className="color-white weight-semi" onClick={this.handleToSearch.bind(this) }>
                                <i className="fas fa-search"></i>&nbsp;Busca en goworkers
                            </button>   
                        </div>
                    </div>
                </div>
                <div className="menu-mobile column" id="menu-mobile">
                    <div className="head column justify-center align-center" id="menu-mobile-head">
                        <Link to={tipo === "1" ? "/perfil/candidato" : "/perfil/empresa"} className="image responsive-img justify-center">
                            <ImageLoader class="cover-img" src = { imagen } alt="Imagen de perfil" title="Imagen de perfil" loading = { () => <img alt="Imagen de marca" title="Imagen del marca" src = { LoadingImage } /> } />
                        </Link>
                        <div className="white-space-8"></div>
                        <div className="name justify-center">
                            <Link to="/ajustes" className="color-white text-center">
                                { nombre }
                            </Link>
                        </div>
                    </div>
                    <div className="content column" id="menu-mobile-content">
                        <div className="white-space-16"></div>
                        <Link className="menu-item" to="/inicio">
                            <i className="far fa-newspaper"></i> &nbsp; Inicio
                        </Link>
                        <div className="white-space-16"></div>
                        <Link className="menu-item" to={tipo === "1" ? "/perfil/candidato" : "/perfil/empresa"}>
                            <i className="far fa-user"></i> &nbsp; Mi perfil
                        </Link>
                        <div className="white-space-16"></div>
                        <Link className="menu-item" to = { tipo === "1" ? "/postulaciones" : "/ofertas" } >
                            <i className="far fa-list-alt"></i> &nbsp; Mis ofertas
                        </Link>
                        <div className="white-space-16"></div>
                        <Link className="menu-item" to="/favoritos">
                            <i className="far fa-heart"></i> &nbsp; Favoritos
                        </Link>
                        <div className="white-space-16"></div>
                        <Link className="menu-item" to="/chats">
                            <i className="far fa-comments"></i> &nbsp; Chat
                        </Link>
                        <div className="white-space-16"></div>
                        <Link className="menu-item" to="/ajustes">
                            <i className="far fa-edit"></i> &nbsp; Ajustes
                        </Link>
                        <div className="white-space-16"></div>
                    </div>
                    <Link className="btn btn-lightBlue color darkBlue weight-bold text-center btn-mobile-main" to="/subir/video" >
                    <i className="fas fa-video"></i> Añadir vídeo
                    </Link>
                </div>
            </header>
        );
    }

    appearMenu() {
        document.getElementById("menu-mobile").classList.add('menu-appear');
        this.setState({ isOpen: true });
    }

    componentDidMount() {
        let menuMobile = document.getElementById('menu-mobile');
        let btnMenu = document.getElementById('btn-menu-icon');
        window.onclick = function (event) {
            if (event.target === menuMobile || event.target === btnMenu) {
                menuMobile.classList.add('menu-appear');
            } else {
                menuMobile.classList.remove('menu-appear');
            }
        }
        this.load();
    }

    async load() {
        const user = await this.props.context.loadUser();
        if (user) { 
            this.setState({ user: user.id });
            this.loadData();
        }
    }

    async loadData() {
        const data = { id: this.state.user.id, tipo: this.state.user.tipo };
        const response = await request.post('/users/menu', data);
        if (response) {
            if(response.menu) {
                this.setState({ imagen: response.menu.imagen, nombre: response.menu.nombre });
            }
        }
    }

    handleProfile() {
        if (this.state.user.tipo === 1) {
            window.location = "/perfil/candidato"
        } else {
            window.location = "/perfil/empresa"
        }
    }

}

const HeaderSimple = (props) => (
    <header className="header-single justify-center">
        <div className="container">
            <div className="align-center">
                <div className="back auto justify-center align-center">
                    <Link to = { props.back }>
                        <i className="fas fa-angle-left color-white font-regular"></i>
                    </Link>
                </div>
                <div className="logo responsive-img justify-center align-center">
                    <img src = { Isotipo } alt="Logo Waala" title="Logo Waala" />
                </div>
            </div>
        </div>
    </header>    

);

const HeaderSingle = () => (
    <header className="header-single justify-center">
        <div className="container">
            <div className="align-center">
                <div className="back auto justify-center align-center">
                    <Link to="/">
                        <i className="fas fa-angle-left color-white font-regular"></i>
                    </Link>
                </div>
                <div className="logo responsive-img justify-center align-center">
                    <img src={ Isotipo } alt="Logo Waala" title="Logo Waala" />
                </div>
            </div>
        </div>
    </header>
);

const HeaderSearch = () => (
    <header className="header-single justify-center">
        <div className="container">
            <div className="header-search align-center">
                <div className="back auto justify-center align-center">
                    <Link to="/">
                        <i className="fas fa-angle-left color-white font-regular"></i>
                    </Link>
                </div>
                <form className="align-center justify-center"> 
                  <i className="icon-search fas fa-search"></i>
                  <input className="input-search color-darkBlue"  type="text" name="search" placeholder="Buscar en goworkers" />
                </form>
                <div className="back auto justify-center align-center">
                <input type="checkbox" id="menyAvPaa"/>
                    <label className="fas fa-filter color-white" id="filter" for="menyAvPaa">
                    </label>
                    
                <div className="column align-center" id="menu-filter" >
                   <div className="row header-filter">
                   <div className="column align-start justify-center">
                   <h3 className="color-white">FILTROS</h3>
                   </div>
                   <div className="column align-end justify-center">
                   <label className="fas fa-times color-white" id="closemain" for="menyAvPaa"/>
                   </div>
                   </div>
                   <div className="container column">
                   <div className="white-space-16"></div>
                    <div className="row">
                    <div className="inputfilter-container align-center">
                        <i className="fas fa-dollar-sign color-pink"></i>
                        <input type="text" name="minprice" placeholder="Precio Mín" className="input input-filter" />
                    </div>
                    <div className="inputfilter-container align-center">
                        <i className="fas fa-dollar-sign color-pink"></i>
                        <input type="text" name="minprice" placeholder="Precio Max" className="input input-filter" />
                    </div>
                    </div>
                    <div className="white-space-16"></div>
                    <hr></hr>
                    <div className="row input-container align-center">
                       <div className="column">
                        <h4>Tipo :</h4>
                       </div>
                       <div className="column">
                        <select name="type" className="input input-filter-opt">
                                <option value="Tipo">All</option>
                                <option value="">tip1</option>
                                <option value="">tip2</option>
                                <option value="Otro">Otro</option>
                            </select>
                       </div>
                       
                    </div>

                    <hr></hr>
                    <div className="input-container align-center">
                        <div className="column"> 
                             <h4>Ciudad :</h4>
                        </div>      
                        <div className="column">
                            <select name="city" className="input input-filter-opt">
                                <option value="ciudad">All</option>
                                <option value="">ciudad 1</option>
                                <option value="">ciudad 2</option>
                                <option value="">ciudad 3</option>
                            </select>
                        </div>                                 
                    </div>

                    <hr></hr>
                    <div className="input-container align-center">
                       <div className="column">
                         <h4>Género :</h4>
                       </div>
                       <div className="column">
                            <select name="gender" className="input input-filter-opt">
                                <option value="Tipo">All</option>
                                <option value="Hombre">Hombre</option>
                                <option value="Mujer">Mujer</option>
                                <option value="Otro">Otro</option>
                            </select>
                       </div>                        
                    </div>
                    <hr></hr>

                    <div className="white-space-16"></div>
                    <div className="btn-container justify-center">
                        <Link to="/registro/marca" className="btn btn-purple color-white text-center" id="btn-marcas">
                            APLICAR FILTROS
                        </Link>
                    </div>

                   </div>
                </div>
                </div>
                
            </div>
        </div>
    </header>
);

const HeaderChatSearch = (props) => (
    <div id="chat-search" className="hidden justify-center">
        <form className="container container-controls" onSubmit={props.buscar.bind(this)}>
            <div className="left">
                <input type="text" autoComplete="off" name="mensaje" className="input" placeholder="Escribe un mensaje" autoComplete="none"/>
            </div>
            <div className="right auto align-center">
                <button type="submit" className="btn">
                    <i className="fas fa-search"></i>
                </button>
            </div>
        </form>
    </div>
)

const HeaderBack = (props) => {
    let backPage = '/inicio';
    if(props.back) {
        if(!(props.back.pathname === window.location.pathname))
            backPage = props.back;
    }
    return (
        <header className="header-single justify-center">
            <div className="container">
                <div className="header-search align-center">
                    <div className="back auto justify-center align-center">
                        <Link to = { backPage }>
                            <i className="fas fa-angle-left color-white font-regular"></i>
                        </Link>
                    </div>
                    <form className="align-center justify-center">
                        <i className="icon-search fas fa-search"></i>
                        <input className="input-search" type="text" name="search" placeholder="Buscar en Go Workers" />
                    </form>
                </div>
            </div>
        </header>
    )
};

let HeaderChat = (props) => {
    let imagen = '';
    let nombre = '';
    if(props.user && !props.user.error) {
        imagen = props.user.imagen;
        nombre = props.user.nombre;
    }
    let prevLocation = '/chats';
    if(props.prevLocation) {
        prevLocation = props.prevLocation;
    }
    //funcion Backpage Chat
    function backPage(backPage, prevLoc) {
        props.context.quitLocation(prevLoc);
        props.history.push(backPage);
    }
    return(
    <header className="header-chat justify-center">
        <div className="container">
            <div className="header-search align-center">
                <div className="back auto justify-center align-center">
                    <a onClick={()=> backPage(props.prevLocation, props.prevLoc)}>
                        <i className="fas fa-angle-left color-white font-regular"></i>
                    </a>
                </div>
                <div className="header-profile">
                    <div className="image auto responsive-img justify-start align-center">
                    <ImageLoader src = { imagen } alt="Imagen de perfil" class="cover-img" title="Imagen de perfil" loading = { () => <img alt="Imagen de marca" title="Imagen del marca" src = { LoadingImage } /> } />
                    </div>
                    <div className="description column auto justify-center">
                        <h4 className="color-white">
                            {nombre}
                        </h4>
                       
                    </div>
                </div>
                <div className="auto align-center search">
                    <div className="align-center auto">
                        <a className="search-chat-icon" onClick = { props.openBuscar.bind(this) }>
                            <i className="fas fa-search color-white"></i>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </header>
    )
};
HeaderChat = withRouter(Consumer(HeaderChat));

const HeaderEmpty = () => (

    <header className="header-single justify-center">
        <div className="container">
            <div className="header-search align-center">
                <form className="align-center justify-center">
                    <i className="icon-search fas fa-search"></i>
                    <input className="input-search" type="text" name="search" placeholder="Buscar en waala" />
                </form>
            </div>
        </div>
    </header>

);

const HeaderPassword = () => (
    <header className="header-single justify-center">
        <div className="container">
            <div className="header-search align-center">
            <div className="row justify-center align-center">               
              <h4 className="color-white text-center">Contraseña</h4>
            </div>
            </div>
        </div>
    </header>
);

const HeaderWebLogin = () => (
    <header className="header-web-login justify-center">
        <div className="container">
            <div className="header-search align-center">
                <div className="row justify-center align-center">  
                    <div className="column">         
                        <div className="logo justify-start align-center">
                            <img className="logo" src = { Logo } alt="Logo Waala" title="Logo Waala" />
                        </div>  
                    </div>
                    <div className="column">         
                        <div className="logo justify-center align-center">
                            <img className="logo-letter" src = { Isotipo } alt="Logo Waala" title="Logo Waala" />
                        </div>  
                    </div>
                    <div className="column">
                        <div className="row justify-end">
                            <ButtonLogin />
                            <HeaderButtonRegister />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </header>
);

const HeaderWebInterests = () => (
    <header className="header-web-interest justify-center">
        <div className="container">
            <div className="header-search align-center">
            <div className="row justify-center align-center">  
                <div className="column auto">   
                    <Link to="/registro"> 
                    <i className="fas fa-angle-left fa-1x color-white back-icon font-regular"></i> 
                    </Link> 
                </div> 
                <div className="column">
                   <h2 className="color-white font-regular">Área y hablilidades</h2>
               </div> 
              </div>
            </div>
        </div>
    </header>
);

export default withRouter(Consumer(Header));
export { HeaderSingle, HeaderSimple, HeaderSearch, HeaderBack, HeaderChat, HeaderEmpty, HeaderPassword, HeaderWebLogin, HeaderWebInterests,HeaderChatSearch};