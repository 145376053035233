/**
 *  profile-influencer.jsx
 *  @version: 1.0.0
 *  @author: DWIT MÉXICO - kath
 *  @description: PERFIL CANDIDATO VISTO DESDE EMPRESA
*/

import React, { Component } from 'react';
import Header from '../components/header/header';
import HeaderWebHomeLoggedIn from '../components/header/header-desktop';
import MediaQuery from 'react-responsive';
import LeftBar from '../components/left-bar/left-bar';
import { Link } from 'react-router-dom';
import { Consumer } from '../context';
import { Helmet } from 'react-helmet';
import CardMyVideo from '../components/cards/my-videos';
import ModalConfirmDeleteVideo from '../components/modals/deleteVideo';
import ModalVideoEdit from '../components/modals/editVideo';
import HolderImage from '../img/user.png';
import ImageLoader from 'react-loading-image';
import LoadingImage from '../img/loading.gif';
import Request from "../core/httpClient";
import ModalVideoAdd from '../components/modals/AddVideo';
import ModalVideoCurriculumAdd from '../components/modals/addVideoCurriculum';
import ModalCurriculumAdd from '../components/modals/addCurriculum';
const request = new Request();

class ProfileCandidate extends Component {

    constructor(props) {
        super(props);
        this.state = {
            candidate: '',
            userData: { id: 0, tipo: 0 },
            intereses: [],
            videos: [],
            items: [],
            numItems: 1,
            numItemsE: 11,
            loadingState: false,
            first: true,
            firstE: true,
            itemsE: [],
            id_video: 0
        };
    }

    setEditID(id_video) {
        this.setState({id_video});
    }
    
    handleAddVideoCurriculum() {
        let modalNewsLetter = document.getElementById('modal-videoCurriculum');  
        modalNewsLetter.classList.remove('fadeOut');
        modalNewsLetter.classList.add('animated', 'fadeIn','faster');
        modalNewsLetter.classList.remove('hidden');
    }

    handleAddCurriculum() {
        let modalNewsLetter = document.getElementById('modal-Curriculum');  
        modalNewsLetter.classList.remove('fadeOut');
        modalNewsLetter.classList.add('animated', 'fadeIn','faster');
        modalNewsLetter.classList.remove('hidden');
    }

    render() {
        let candidate = '', foto = '', intereses = [];
        if (this.state.loading) {
            candidate = '';
        } else {
            this.state.candidate ? candidate = this.state.candidate : candidate = ''
            this.state.candidate.foto_perfil_url ? foto = this.state.candidate.foto_perfil_url : foto = HolderImage
        }

        if (this.state.intereses) {
            intereses = this.state.intereses;
        }

        return (
            <div className="profile-candidate column align-center">
                <Helmet>
                    <title>WAALA - Perfil</title>
                    <meta name="robots" content="noindex" />
                    <meta property="og:title" content="Go Workers - Perfil" />
                    <meta property="og:type" content="website" />
                </Helmet>
                <MediaQuery minDeviceWidth = { 768 }>
                    <div className="view-web column">
                        <HeaderWebHomeLoggedIn hiddeOffer={true} />
                        <div className="row">
                            <LeftBar active = "menuProfile" />
                            <div className="column">
                                <div className="row justify-center">
                                    <div className="container-profile  column align-center justify-center">
                                        <div className="white-space-32"></div>
                                        <div className="row header-profile ">
                                            <div className="column profile-column-public justify-center align-center">
                                                <div className="responsive-img justify-start align-start">
                                                    <img src={foto} alt="" className="profile-image-public" />
                                                </div>
                                            </div>
                                            <div className="column justify-start align-center column-info">
                                                <div className="white-space-16"></div>
                                                <div className="row">                              
                                                    <h2 className="weight-bold">{candidate.nombre}</h2>
                                                    <Link to="/ajustes/perfil">
                                                    <i className="fas fa-cog icon-config"></i>
                                                    </Link>
                                                </div>
                                                <div className="white-space-16"></div>
                                                <div className="row">
                                                    <i className="fas fa-medal font-Large color-darkBlue icon-header"></i>
                                                    <p className="text-start font-regular text-abilities">{candidate.funcion}</p>
                                                </div>
                                                <div className="white-space-16"></div>
                                                <div className="row">
                                                    <i className="fas fa-map-marker-alt font-Large color-darkBlue icon-header icon-ubication"></i>
                                                    <p className="text-start font-regular">{candidate.ciudad}</p>
                                                </div>
                                                <div className="white-space-24"></div>
                                                <div className="row justify-start">                            
                                                    <div className="btn-container-public justify-center">
                                                        <a className="btn-publi-border text-center color-darkBlue" onClick={ this.handleAddCurriculum.bind(this) }>
                                                            <i className="fas fa-file-alt font-regular icon-btn color-darkBlue"></i> Curriculum
                                                        </a>
                                                    </div>
                                                    <div className="btn-container-public justify-center">
                                                        <a className="btn-publi-border text-center color-darkBlue"onClick={this.handleAddVideoCurriculum.bind(this) }>
                                                            <i className="fa fa-video font-regular icon-btn color-darkBlue"></i> Video curriculum
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="white-space-16"></div>
                                        <div className="conteiner-labels column">
                                        <div className="white-space-8"></div>
                                        <div className="row row-labels justify-center wrap">
                                            {
                                                intereses.map((item, index) =>
                                                <div className="user-label auto" key={index}>
                                                    <h5 className="font-mini">
                                                        {item.name}
                                                    </h5>
                                                </div>)
                                            }
                                        </div>
                                    </div>               
                                        <div className="white-space-16"></div>
                                        <div className="gallery-profile" onScroll={this.scrollItemsE.bind(this)}>
                                            <div className="row list-videos wrap">
                                                {
                                                    this.state.empty ?
                                                    <div className="column align-center">
                                                        <div className="white-space-16"></div>
                                                        <h3 className="text-center">
                                                            No existen vídeos.
                                                        </h3>
                                                    </div>
                                                :
                                                this.state.firstE ? this.state.itemsE.map((item) =>
                                                    <CardMyVideo video={item} key={item.id_video} desktop={true} setEditID={this.setEditID.bind(this)}/>
                                                    ) :
                                                    this.displayItemsE()
                                                }                           
                                            </div>
                                        </div>
                                        <div className="white-space-16"></div>
                                        {this.state.loadingStateE ? <i className="fas fa-spin fa-spinner"></i> : ""}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <ModalVideoAdd></ModalVideoAdd>
                        <ModalVideoCurriculumAdd/>
                        <ModalCurriculumAdd/>
                        <ModalVideoEdit id_video={this.state.id_video}></ModalVideoEdit>
                    </div>
                </MediaQuery>                
                <MediaQuery maxWidth = { 768 }>
                    <Header />
                    <div className="container column">
                        <div className="candidate-cards column justify-start align-center">
                            <div className="image row">
                                <div className="column column-info justify-center align-center">
                                    <div className="row justify-center align-center">
                                        <h4 className="weight-bold text-center font-regular name">
                                            { candidate.nombre }
                                        </h4>
                                    </div>
                                    <div className="white-space-4"></div>
                                    <div className="column align-center">
                                        <h5 className="left text-center font-mini weight-semi age">
                                            { candidate.edad ? candidate.edad + " Años" : null }
                                        </h5>
                                    </div>
                                    <div className="white-space-4"></div>
                                    <div className="column align-center">
                                        <h5 className="text-center font-mini weight-semi city">
                                            { candidate.ciudad }
                                        </h5>
                                    </div>
                                    <div className="white-space-4"></div>
                                    <div className="column sector align-end justify-end">
                                        <h5 className="text-center weight-semi color-lightBlue uppercase">
                                            { candidate.funcion }
                                        </h5>
                                    </div>
                                </div>
                                <div className="profile-container column">
                                    <div className="align-center justify-center">
                                        <div className="responsive-img justify-center align-center">
                                            <ImageLoader src={foto} alt="Imagen del candidato" title="Imagen del candidato" className="image-profile"
                                            loading={() => <img className="image-profile" alt="Imagen del candidato" title="Imagen del candidato" src={LoadingImage} />} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="white-space-4"></div>
                            <div className="tabs-container justify-center">
                                <div className="column">
                                    <Link to="/subir/cv" rel="noopener noreferrer" className="btn btn-square text-center font-mini">
                                        <i className="color-darkBlue fas fa-file-alt"></i> Curriculum
                                    </Link>
                                </div>
                                <div className="column">
                                    <Link to="/subir/videoCurriculum" className="btn btn-square text-center font-mini">
                                        <i className="color-darkBlue fas fa-file-video"></i> Videocurriculum
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="justify-center">
                            <div className="conteiner-labels column">
                                <div className="white-space-8"></div>
                                <div className="soft-skills" data-flickity='{ "cellAlign": "left", "contain": true, "autoPlay": true, "pageDots": false }'>
                                    {
                                        intereses.map((item, index) =>
                                            <div className="user-label auto" key={index}>
                                                <h5 className="font-mini">
                                                    {item.name}
                                                </h5>
                                            </div>
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="vc column profile-private" onScroll={this.scrollItems.bind(this)}>
                            {
                                this.state.empty ?
                                    <div className="column align-center">
                                        <div className="white-space-16"></div>
                                        <h3 className="text-center">
                                            No existen videos.
                                        </h3>
                                    </div>
                                :
                                this.state.first ? this.state.items.map((item) =>
                                    <CardMyVideo  video={item} desktop={false} key={item.id_video}/>
                                    ) 
                                :
                                this.displayItems()
                            }
                        </div>
                    </div>
                    {this.state.loadingState ? <i className="fas fa-spin fa-spinner"></i>  : ""}
                    <div className="white-space-64"></div>
                    <div className="btn-container justify-center btn-container-candidate fixed-contact">
                        <Link to="/subir/video" className="btn btn-lightBlue btn-contact weight-bold font-regular text-center color-darkBlue">
                            <i className="fas fa-video"></i> Añadir vídeo
                        </Link>
                    </div>
                </MediaQuery>
                <ModalConfirmDeleteVideo title="Eliminar" description="" load={this.getCandidate.bind(this)} />
            </div>
        );
    }

    componentDidMount() {
        this.props.context.mapLocation(window.location.pathname);
        this.getCandidate();
    }


    scrollItems(event) {
        if (event.target.scrollTop + event.target.clientHeight >= event.target.scrollHeight) {
            this.loadMoreItems();
            this.setState({ first: false });
        }
    }

    scrollItemsE(event) {
        if (event.target.scrollTop + event.target.clientHeight >= event.target.scrollHeight) {
            this.loadMoreItemsE();
            this.setState({ firstE: false });
        }
    }

    displayItems() {
        var items = [];
        for (let i = 0; i < this.state.numItems + 1; i++) {
            if (this.state.videos[i]) {
                items.push(
                    <CardMyVideo video={this.state.videos[i]} desktop={false} setEditID={this.setEditID.bind(this)} key={this.state.videos[i].id_video} />
                );
            }
        }
        return items;
    }

    loadMoreItems() {
        if (this.state.numItems <= this.state.videos.length) {
            this.setState({ loadingState: true });
            setTimeout(() => {
                this.setState({ numItems: this.state.numItems + 2, loadingState: false });
            }, 3000); 
        } 
    }

    displayItemsE() {
        var items = [];
        for (let i = 0; i < this.state.numItemsE + 1; i++) {
            if (this.state.videos[i]) {
                items.push(
                    <CardMyVideo video={this.state.videos[i]} desktop={true} setEditID={this.setEditID.bind(this)} key={this.state.videos[i].id_video}/>
                );
            }
        }
        return items;
    }

    loadMoreItemsE() {
        if (this.state.numItemsE <= this.state.videos.length) {
            this.setState({ loadingStateE: true });
            setTimeout(() => {
                this.setState({ numItemsE: this.state.numItemsE + 12, loadingStateE: false });
            }, 3000);
        }
    }

    async getCandidate() {
        const user = await this.props.context.loadUser();
        if (user) {
            this.setState({ userData: user.id });
        }
        const data = { user: user.id.id, tipo: user.id.tipo };
        const response = await request.post("/candidates/get", data);
        if (response && !response.error) {
            if (response.empty) {
                this.setState({ empty: true, loading: false });
            } else {                
                this.setState({ candidate: response.candidate, loading: false });
                const intereses = JSON.parse(response.candidate.intereses);
                this.setState({ intereses });
                this.loadFlickity();
            }
        } else {
            this.setState({ error: response.error, loading: false });
        }

        const dataVideo = { id: user.id.id, tipo: user.id.tipo };
        const videosData = await request.post('/users/videos', dataVideo);
        if (videosData) {
            this.setState({ message: videosData.message });
            if (videosData.empty) {
                this.setState({ empty: videosData.empty });
            } else {
                const dataVideo = videosData.video.slice(0,2).map((item) => item);
                const twelveVideos = videosData.video.slice(0, 12).map((item) => item);
                this.setState({ videos: videosData.video, items: dataVideo, itemsE: twelveVideos });
            }
        }
    }

    loadFlickity() {
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = 'https://unpkg.com/flickity@2/dist/flickity.pkgd.min.js';
        document.body.append(script);
    }

}

export default Consumer(ProfileCandidate);