/**
 *  Login.jsx
 *  @version: 1.0.0
 *  @author: DWIT MÉXICO - Sergio, kath
 *  @description: Modal de inicio de sesión para Web
*/

import React, { Component } from "react";
import { Link, withRouter } from 'react-router-dom';
import { Consumer } from '../../context';
import Request from "../../core/httpClient";
const request = new Request();

class ModalLogin extends Component {	
	
	constructor(props) {
		super(props);
		this.state = {
			error: '', user: { id: 0, tipo: 0 }, loading: '', message: ''
		}
	}

	render() {
    	return (
			<div className="column">
				<div className="modal justify-center hidden" id="modal-login">
					<div className="container modal-content align-center column" >
						<div className="white-space-48"></div>
						<h2 className="text-center font-huge">
							Inicio de sesión
						</h2>
						<button className="btn auto" id="close-modal-login">
							<i className="fas fa-times"></i>
						</button>
						<div className="white-space-24"></div>
						<form className="column align-center justify-center" onSubmit={this.handleSubmit.bind(this)}> 
							<div className="basic-data column justify-center align-center">
								<div className="input-container align-center justify-center">
									<input type="text" name="email" placeholder="Correo electrónico" className="input input-login" minLength="8" required />
								</div>
								<div className="white-space-8"></div>
								<div className="input-container align-center justify-center">                     
									<input type="password" name="password" placeholder="Contraseña" className="input input-login" minLength="8" required />
								</div>
								<div className="white-space-8"></div>
								<div className="btn-container justify-center">
									<button type="submit" className="btn-modal-login btn-darkBlue font-small text-center color-white weight-bold">
										{
											this.state.loading ?
												(<i className="fas fa-spin fa-spinner color-white"></i>) :
												("INICIAR SESIÓN")
										}
									</button>
								</div> 
								<div className="white-space-8"></div>
								<div className="btn-container justify-center">
									<button type="submit" className="btn-border-blue font-small text-center weight-bold">
										<i className="fab fa-facebook-square icon-facebook color-darkBlue"></i> INICIAR CON FACEBOOK
									</button>
								</div>
								{
									this.state.message ?
										(
											<div className="column">
												<div className="white-space-8"></div>
												<div className="justify-center">
													<p className="text-center font-tiny color-red weight-semi"> {this.state.message} </p>
												</div>
											</div>
										)
										: (null)
								} 
								<div className="white-space-24"></div>
								<div className="row justify-center align-center">
									<Link to = "/recoverpassword">¿Olvidaste tu contraseña?</Link>
								</div>
							</div>                 
						</form>
						<div className="white-space-48"></div>
					</div>
				</div>
			</div>      
     	);
	}
	  
	componentDidMount() {
		let modalNewsLetter = document.getElementById('modal-login');
		document.getElementById('close-modal-login').onclick = () => {
			modalNewsLetter.classList.add('fadeOut');
			setTimeout(() => {
				modalNewsLetter.classList.add('hidden');
			}, 500);
		}
	}

	async handleSubmit(event) {
		event.preventDefault();
		this.setState({ loading: true });
		const form = event.target;
		const data = { Email: form.email.value, Password: form.password.value };
		const response = await request.post("/users/login", data);
		if (response) {
			if (response.error) {
				this.setState({ message: response.message });
			} else {
				if (response.user) {
					this.props.context.logIn({ id: response.user, auth: true });
					window.localStorage.removeItem('gow_ftab');
					window.localStorage.removeItem('gow_htab');
					const user = await this.props.context.loadUser();
					this.setState({ user: user.id });
					if (user) {
						if (user.id.tipo === 1 && user.id.freelancer !== 1) {
							this.props.history.push("/inicio");
							window.localStorage.setItem("gwt", true);
						} else {
							if (user.id.tipo === 1 && user.id.freelancer === 1) {
								this.props.history.push("/inicio");
								window.localStorage.setItem("gwt", true);
							} else {
								if (user.id.tipo === 2) {
									this.props.history.push("/inicio");
									window.localStorage.setItem("gwt", false);
								} else {
									this.setState({ message: response.message });
								}
							}
						}
					}
				}
			}
		}
		this.setState({ loading: false, message: response.message });
	}

}

export default withRouter(Consumer(ModalLogin));
