/**
 *  job-postulate.jsx
 *  @version: 1.0.0
 *  @author: DWIT MÉXICO - Sergio
 *  @description: Cards de ofertas postuladas
*/

import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import HolderImage from '../../img/user.png';
import { Consumer } from '../../context';
import Request from '../../core/httpClient';
import shave from 'shave';
const req = new Request()

class CardJobPostulate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            cancelled: false
        }
    }
    async cancel() {
        this.setState({loading: true});
        const data = {
            id: this.props.user.id,
            postulacion: this.props.id
        }
        const res = await req.post('/offers/cancel/postulations/candidate', data);
        if(res.cancelled) {
            window.location.reload();
        }
        this.setState({loading: false});
    }

    render() {        
        return (
            <div className="card-job">
                <div className="head">
                    <div className="logo responsive-img align-center">
                        <img src = { this.props.imagen || HolderImage } alt="Logo de marca" title="Logo de marca" onClick={()=> this.props.history.push(`/oferta/${this.props.id_oferta}`)}/>
                    </div>
                </div>
                <div className="info column">
                    <div className="title column" onClick={()=> this.props.history.push(`/oferta/${this.props.id_oferta}`)}>
                        <h4 className="font-regular padding card-name">
                            {this.props.titulo}
                        </h4>
                        <p className="padding font-mini color-darkgray">
                            Por: {this.props.empresa}
                        </p>
                    </div>
                    <div className="align-center padding" onClick={()=> this.props.history.push(`/oferta/${this.props.id_oferta}`)}>
                        <i className="far fa-compass font-mini"></i>&nbsp;
                        <p className="font-mini">
                            {this.props.ciudad}
                        </p>
                    </div>
                    <div className="white-space-4"></div>
                    <div className="align-center padding" onClick={()=> this.props.history.push(`/oferta/${this.props.id_oferta}`)}>
                        <i className="fas fa-euro-sign font-mini"></i>&nbsp;
                        <p className="font-mini">
                            {this.props.salario}
                        </p>
                    </div>
                    <div className="contact justify-center">
                        {this.props.estado === 'CANCELADO'? 
                            <p className=" weight-semi">CANCELADO</p>
                            :
                            <button className="btn-play weight-semi" onClick={this.cancel.bind(this)}>
                                <i className="fas fa-times"></i>&nbsp; CANCELAR
                                {this.state.loading? <i className="fas fa-spinner fa-spin color-darkBlue"></i> : null}
                            </button>
                        }
                    </div>
                </div>
            </div>
        );
    }

    componentDidMount() {
        shave('.card-name', 22);
    }

}

export default withRouter(Consumer(CardJobPostulate));