/**
 *  header.jsx
 *  @version: 1.0.0
 *  @author: DWIT MÉXICO - Sergio
 *  @description: Boton modal Registro
*/

import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

class HeaderButtonRegister extends Component {

    handleWebRegister() {
        let modalLogin = document.getElementById('modal-register');
        modalLogin.classList.remove('fadeOut');
        modalLogin.classList.add('animated', 'fadeIn', 'faster');
        modalLogin.classList.remove('hidden');
    }

    render() {
        return (
            <div className="btn-container auto btn-splash justify-center">
                <button className="btn-web btn-border-white weight-bold text-center font-small color-white" onClick = { this.handleWebRegister.bind(this) }>
                    Crear cuenta
                </button>
            </div>
        );
    }
}

export default withRouter(HeaderButtonRegister);