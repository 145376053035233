/**
 *  candidates.jsx
 *  @version: 1.0.0
 *  @author: DWIT MÉXICO - Sergio
 *  @description: Pantalla de candidatos postulados a oferta
*/

import React, { Component } from 'react';
import { HeaderBack } from '../components/header/header';
import HeaderWebHomeLoggedIn from '../components/header/header-desktop';
import LeftBar from '../components/left-bar/left-bar';
import CardPostulates from '../components/cards/postulates';
import ModalVideoAdd from '../components/modals/AddVideo';
import ModalOfferAdd from '../components/modals/offerAdd';
import MediaQuery from 'react-responsive';
import { Consumer } from '../context';
import Request from '../core/httpClient';
const request = new Request();

class Candidates extends Component {

    constructor(props) {
        super(props);
        this.state = {
            userData: { id: 0, tipo: 0 },
            titulo: '',
            tab: 'todos'
        }
    }

    changeTab(option) {
        window.localStorage.setItem('gwcp', option);
        this.setState({tab: option});
        let status = '';
        if(option === 'aceptados') {
            status = 'aceptado'
        }
        let candidatos = this.state.candidatosFilt;
        candidatos = candidatos.filter(candidato => candidato.estado.toLowerCase().indexOf(status) !== -1);
        this.setState({candidatos});
    }

    render() {
        let titulo = '', candidatos = [];

        if (this.state.titulo) {
            titulo = this.state.titulo;
        }

        if (this.state.candidatos) {
            candidatos = this.state.candidatos;  
        }

        return (
            <div className="offer-candidates column">
                 <MediaQuery minDeviceWidth = { 768 }>
                <div className="view-web column">
                    <HeaderWebHomeLoggedIn />
                    <div className="row">
                        <LeftBar/>     
                        <div className="column align-center">
                            <div className="white-space-32"></div>
                            <div className="container column">
                            <div className="justify-center header ">
                            <div className=" text-center column">
                                    <h3>
                                        Candidatos
                                    </h3>
                                    <p className="font-regular text-center color-gray">
                                        Oferta: {titulo}
                                    </p>
                                </div>
                            </div>
                            <div className="white-space-24"></div>
                            <div className="tabs-menu justify-center">
                                <div className="container">
                                    <div className={"jobs column" + (this.state.tab === 'todos' ? ' tab-active': '')} onClick = {this.changeTab.bind(this, 'todos')}>
                                        <div className="icon justify-center">
                                            <i className="fas fa-users color-darkBlue"></i>
                                        </div>
                                        <div className="title justify-center">
                                            <button className="color-darkBlue font-mini">
                                                TODOS
                                            </button>                        
                                        </div>
                                    </div>
                                    <div className={"jobs column" + (this.state.tab === 'aceptados' ? ' tab-active': '')} onClick = {this.changeTab.bind(this, 'aceptados') }>
                                        <div className="icon justify-center">
                                            <i className="fas fa-check color-darkBlue"></i>
                                        </div>
                                        <div className="title justify-center">
                                            <button className="color-darkBlue font-mini">
                                                ACEPTADOS
                                            </button>                        
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="cards-container  column align-center justify-center">                       
                        
                                    {this.state.empty ?
                                        <div className="column align-center">
                                            <div className="white-space-16"></div>
                                            <h3 className="text-center">
                                                Sin candidatos postulados.
                                            </h3>
                                        </div>
                                    :
                                        candidatos.map((item, index) =>
                                        <CardPostulates key={index} postulado={item} refresh={this.load.bind(this)}/>)
                                    }
                            
                            </div>

                            </div>
                        </div>                
                       
                    </div>
                </div>
                </MediaQuery>
                <MediaQuery maxWidth = { 768 }>
                <HeaderBack back = { this.props.context.prevLocation } />
                <div className="justify-center header">
                    <div className="container text-center column">
                        <h3>
                            Candidatos
                        </h3>
                        <p className="font-mini text-center color-gray">
                            Oferta: {titulo}
                        </p>
                    </div>
                </div>
                <div className="tabs-menu justify-center">
                    <div className="container">
                        <div className={"jobs column" + (this.state.tab === 'todos' ? ' tab-active': '')} onClick = {this.changeTab.bind(this, 'todos')}>
                            <div className="icon justify-center">
                                <i className="fas fa-users color-darkBlue"></i>
                            </div>
                            <div className="title justify-center">
                                <button className="color-darkBlue font-mini">
                                    TODOS
                                </button>                        
                            </div>
                        </div>
                        <div className={"jobs column" + (this.state.tab === 'aceptados' ? ' tab-active': '')} onClick = {this.changeTab.bind(this, 'aceptados') }>
                            <div className="icon justify-center">
                                <i className="fas fa-check color-darkBlue"></i>
                            </div>
                            <div className="title justify-center">
                                <button className="color-darkBlue font-mini">
                                    ACEPTADOS
                                </button>                        
                            </div>
                        </div>
                    </div>
                </div>
                <div className="justify-center">
                    <div className="container column">
                        {this.state.empty ?
                            <div className="column align-center">
                                <div className="white-space-16"></div>
                                <h3 className="text-center">
                                    Sin candidatos postulados.
                                </h3>
                            </div>
                        :
                            candidatos.map((item, index) =>
                            <CardPostulates key={index} postulado={item} refresh={this.load.bind(this)}/>)
                        }
                    </div>
                </div>
                </MediaQuery>
                <ModalVideoAdd></ModalVideoAdd>
            <ModalOfferAdd></ModalOfferAdd>
            </div>
        );
    }

    componentDidMount() {
        this.load();
        this.props.context.mapLocation(window.location.pathname);
    }

    async load() {
        const user = await this.props.context.loadUser();
        if (user) { this.setState({ userData: user.id }); }

        const tab = window.localStorage.getItem('gwcp');
        if(tab) {
            this.setState({tab})
        }

        const offerData = JSON.parse(window.localStorage.getItem("offerData"));
        this.setState({ titulo: offerData.titulo });

        const data = { id: offerData.id };
        const response = await request.post('/offers/get/candidates/postulations', data);
        if (response) {
            if (response.candidates) {
                this.setState({ candidatos: response.candidates, candidatosFilt: response.candidates, loading: false });
                this.changeTab(tab)
            } else {
                this.setState({ empty: true, message: response.message, loading: false });
            }
        }
    }
}

export default Consumer(Candidates);