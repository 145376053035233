import React, { Component } from "react";

class ChatDelete extends Component {
 
  componentDidMount() {
    	let modalNewsLetter = document.getElementById('modal-delete-chat');
      	document.getElementById('close-modal-delete').onclick = () => {
      		modalNewsLetter.classList.add('fadeOut','faster');
			setTimeout(() => {
				modalNewsLetter.classList.add('hidden');
			}, 500);
    	}
	}

  render() {
    return (
        <div className="modal column justify-center align-center faster hidden" id="modal-delete-chat">
                <div className="container modal-content align-center column" >
                    <div className="column modal-head">
                        <div className="white-space-64"></div>
                        <h3 className="text-center">
                            ¿DESEA ELIMINAR ÉSTE MENSAJE?
                        </h3>
                        <div className="white-space-8"></div>
                        <p className="text-center">
                            Esta acción no puede deshacerse
                        </p>
                        <div className="white-space-32"></div>
                    </div>
					<div className="modal-controlls">
						<button className="btn btn-square btn-lightBlue color-darkBlue weight-semi" id="btn-deleteChat" onClick = { this.props.deleteChat.bind(this) } >
							<i className="fas fa-trash-alt color-darkBlue"></i> SI
						</button>
						<button className="btn btn-square btn-darkBlue color-white weight-semi" id="close-modal-delete">
							<i className="fas fa-times color-white"></i> NO
						</button>
					</div>						
                </div>
            </div>
    	);
  	}
}

export default ChatDelete;