import React, { Component } from 'react';
import { Consumer } from '../context';
import Header from '../components/header/header';
import HeaderWebHomeLoggedIn from '../components/header/header-desktop';
import MediaQuery from 'react-responsive';
import LeftBar from '../components/left-bar/left-bar';
import CardPlayer from '../components/cards/player';
import ModalOfferAdd from '../components/modals/offerAdd';
import ModalVideoAdd from '../components/modals/AddVideo';
import { Link } from 'react-router-dom';
import ThumbNail from '../img/thumbnail.jpg';
import Request from "../core/httpClient";
const req = new Request();

class Video extends Component {
    constructor(props) {
		super(props);
		this.state = {
            userData: { id: 0, tipo: 0 }, videoPrincipal: '', 
            titulo: '', videos: [], prevLocation: '/'
        }
    }

    async addFav() {
        const idVideo = parseInt(this.props.match.params.id);
        let data = '';
        if (this.state.userData.tipo === 1) {
            data = {
                tipo_user: this.state.userData.tipo, id_user: this.state.userData.id,
                tipo: 1, id_elemento: idVideo
            };
        } else {
            data = {
                tipo_user: this.state.userData.tipo, id_user: this.state.userData.id, 
                tipo: 1, id_elemento: idVideo
            };
        }

        const res = await req.post('/favorites/set', data);
        if (res.error) {
            console.log("Existe un error añadiendo favoritos");
        } else {
            this.setState({ fav: !this.state.fav });
        }
    }

    

    render() {
        let user = false;
        if (this.state.user && this.state.userData) {
            if (this.state.user === this.state.userData.id) { user = true; }
        }

        return(
            <div className="video-page column">
                <MediaQuery minDeviceWidth = { 768 }>
                    <div className="settings view-web column">
                        <HeaderWebHomeLoggedIn/>
                        <div className="row">
                            <LeftBar/>
                            <div className="column align-center">
                            <div className="white-space-32"></div>
                            <div className="row container">
                            <div className="column video-desk">
                            {this.state.videoPrincipal !== '' ?
                            this.state.videoPrincipal
                            :
                            <div className="video">
                                <div className="thumbnail responsive-img justify-center align-center">
                                    <img src={ThumbNail} alt="Video Placeholder" title="Video Placeholder" />
                                    <Link to="/video" className="btn-play">
                                        <i className="far fa-play-circle fa-3x color-lightBlue"></i>
                                    </Link>
                                </div>
                            </div>
                            }
                            </div>
                                <div className="column video-list justify-start">
                                    <div className="details">
                                        <div className="column">
                                            <h4 className="padding-left">
                                                {this.state.titulo}
                                            </h4>
                                            <p className="font-mini color-gray padding-left">
                                                Por: {this.state.nombre}
                                            </p>
                                        </div>
                                        <div className="auto fav-icon align-center">
                                            {user ?
                                            null
                                            :
                                            <button type="button" onClick={this.addFav.bind(this)}>
                                                {this.state.fav ?
                                                    <i className="fas fa-heart color-darkBlue"></i>
                                                :
                                                    <i className="far fa-heart color-darkBlue"></i>}
                                            </button> 
                                            }
                                        </div>
                                    </div>
                                    {this.state.empty ? 
                                    <div className="column align-center">
                                        <div className="white-space-16"></div>
                                        <h3 className="text-center">
                                            No existen más videos.
                                        </h3>
                                    </div>
                                    :
                                    this.state.videos.map((item, index) => 
                                        <div key={index}>   
                                            <CardPlayer video={item}/>
                                        </div>
                                    )}
                                </div>
                            </div>
                         </div>
                        </div>
                    </div>
                </MediaQuery>
                <MediaQuery maxWidth = { 768 }>
                <Header back = { this.props.context.prevLocation } />
                {
                    this.state.videoPrincipal !== '' ?
                        this.state.videoPrincipal
                    :
                    <div className="video">
                        <div className="thumbnail responsive-img justify-center align-center">
                            <img src={ThumbNail} alt="Video Placeholder" title="Video Placeholder" />
                            <Link to="/video" className="btn-play">
                                <i className="far fa-play-circle fa-3x color-lightBlue"></i>
                            </Link>
                        </div>
                    </div>
                }
                <div className="justify-center">
                    <div className="container column">
                        <div className="white-space-8"></div>
                        <div className="details">
                            <div className="column">
                                <h4 className="padding-left">
                                    {this.state.titulo}
                                </h4>
                                <p className="font-mini color-gray padding-left">
                                    Por: {this.state.nombre}
                                </p>
                            </div>
                            <div className="auto fav-icon align-center">
                                {
                                    user ?
                                        null
                                    :
                                    <button type="button" onClick={this.addFav.bind(this)}>
                                        {this.state.fav ?
                                            <i className="fas fa-heart color-darkBlue"></i>
                                        :
                                            <i className="far fa-heart color-darkBlue"></i>}
                                    </button> 
                                }
                            </div>
                        </div>
                        {
                            this.state.empty ? 
                                <div className="column align-center">
                                    <div className="white-space-16"></div>
                                    <h3 className="text-center">
                                        No existen más videos.
                                    </h3>
                                </div>
                            :
                            this.state.videos.map((item, index) => 
                                <div key={index}>   
                                    <CardPlayer video={item}/>
                                </div>
                            )
                        }
                    </div>
                </div>
                </MediaQuery>
            <ModalVideoAdd></ModalVideoAdd>
            <ModalOfferAdd></ModalOfferAdd>
            </div>
        );
    }

    componentDidMount() {
        this.loadVideos();
        this.props.context.mapLocation(window.location.pathname)
    }

    async loadVideos() {
        const user = await this.props.context.loadUser();
        if (user) { this.setState({ userData: user.id }); }
        const data = { id: this.props.match.params.id, id_user: user.id.id };
        const videosData = await req.post('/videos/getbyid', data);
        if (videosData) {
            this.setState({ message: videosData.message });
            if (videosData.empty) {
                this.setState({ empty: videosData.empty });
            } else {
                const idVideo = parseInt(this.props.match.params.id);
                const principalVideo = videosData.video.find((item) => item.id_video === idVideo);
                if (principalVideo) {
                    this.setState({ videoPrincipal: <video src={principalVideo.video_url} autoPlay={true} width="360" controls className="video-full"></video> });
                    this.setState({ titulo: principalVideo.titulo, nombre: principalVideo.nombre, user: principalVideo.id_user, fav: principalVideo.favorito });
                }
                if (videosData.video.length > 1) {
                    let videosRandom = videosData.video.filter((item) => item.id_video !== idVideo);
                    let aleatorio = 0, videos = [];
                    for (let i = 0; i < 3; i++) {
                        aleatorio = Math.floor(Math.random() * (videosRandom.length));
                        if (videosRandom[aleatorio]) {
                            videos[i] = videosRandom[aleatorio];
                            videosRandom.splice(aleatorio, 1);
                        }
                    }
                    this.setState({ videos });
                } else {
                    this.setState({ empty: true });
                }
            }
        }
    }
}

export default Consumer(Video);