/**
 *  my-offers.jsx
 *  @version: 1.0.0
 *  @author: DWIT MÉXICO - Sergio
 *  @description: Pantalla de mis ofertas
*/

import React, { Component } from 'react';
import Header from '../components/header/header';
import HeaderWebHomeLoggedIn from '../components/header/header-desktop';
import CardMyOffersPostulate from '../components/cards/my-offers-postulate';
import { Consumer } from '../context';
import LeftBar from '../components/left-bar/left-bar';
import MediaQuery from 'react-responsive';
import Request from "../core/httpClient";
import ModalOfferAdd from '../components/modals/offerAdd';
import ModalVideoAdd from '../components/modals/AddVideo';
import ModalEditOffer from '../components/modals/editOffer';
const request = new Request();

class MyOffers extends Component {

    constructor(props) {
        super(props);
        this.state = {
            userData: { id: 0, tipo: 0 }, 
            ofertas: [],
            id_oferta: 0
        }
    }

    setEditOfferID(id_oferta) {
        this.setState({id_oferta});
    }

    render() {
        let ofertas = '';
        if (this.state.ofertas) {
            ofertas = this.state.ofertas;
        }

        return (
            <div className="my-offers column">
                <MediaQuery minDeviceWidth = { 768 }>
                    <div className="view-web column">
                        <HeaderWebHomeLoggedIn/>
                        <div className="row">
                            <LeftBar active="menuOffers" />
                            <div className="column">
                                <div className="white-space-32"></div>
                                <div className="row justify-center">
                                    <div className="column container ">
                                        <div className="justify-center head-info">
                                            <div className="row justify-center">
                                                <h3 className="text-center">
                                                    Mis Ofertas
                                                </h3>
                                            </div>
                                        </div>
                                        <div className="white-space-16"></div>
                                        <div className="row">
                                            <div className={`cards-container ${this.state.empty ? '' : 'list-videos wrap' } row align-center justify-center`}>
                                            {
                                                    this.state.loading ? 
                                                    (
                                                        <div className="align-center row">
                                                            <div className="white-space-16"></div>
                                                            <i className="fas fa-spin fa-spinner"></i>
                                                        </div>
                                                    )
                                                    :
                                                    (
                                                        this.state.empty ?
                                                        (
                                                            <div className="column justify-center">
                                                                <div className="white-space-8"></div>
                                                                <h3 className="text-center">
                                                                    Sin ofertas disponibles
                                                                </h3>
                                                            </div>
                                                        )
                                                        :
                                                        (
                                                            ofertas.map((item) =>
                                                                <CardMyOffersPostulate key={item.id_oferta} oferta={item} desktop={true} setEditOfferID={this.setEditOfferID.bind(this)}/>)
                                                        )
                                                    )
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <ModalOfferAdd></ModalOfferAdd>
                            <ModalVideoAdd></ModalVideoAdd>
                            <ModalEditOffer id_oferta={this.state.id_oferta}></ModalEditOffer>
                        </div>
                    </div>
                </MediaQuery>
                <MediaQuery maxWidth = { 768 }>
                <Header />
                <div className="justify-center head-info">
                    <div className="container column">
                        <h3 className="text-center">
                            Mis Ofertas
                        </h3>
                    </div>
                </div>
                <div className="justify-center">
                    <div className="container column">
                        {
                            this.state.loading ? 
                            (
                                <div className="align-center column">
                                    <div className="white-space-16"></div>
                                    <i className="fas fa-spin fa-spinner"></i>
                                </div>
                            )
                            :
                            (
                                this.state.empty ?
                                (
                                    <div className="column align-center">
                                        <div className="white-space-8"></div>
                                        <h3 className="text-center">
                                            No existen ofertas.
                                        </h3>
                                    </div>
                                )
                                :
                                (
                                    ofertas.map((item) =>
                                        <CardMyOffersPostulate key={item.id_oferta} oferta={item} />)
                                )
                            )
                        }
                    </div>
                </div>
                </MediaQuery>
            </div>
        );
    }

    componentDidMount() {
        this.load();
        this.props.context.mapLocation(window.location.pathname);
       
    }

    async load() {
        const user = await this.props.context.loadUser();
        if (user) {
            this.setState({ userData: user.id });
            this.loadOffers();
        }
    }

    async loadOffers() {
        this.setState({ loading: true });
        const data = { id: this.state.userData.id };
        const response = await request.post('/offers/getAllById', data);
        if (response) {
            if (response.offers) {
                this.setState({ ofertas: response.offers, loading: false });
            } else {
                this.setState({ empty: true, message: response.message, loading: false });
            }
        }
    }

}

export default Consumer(MyOffers);