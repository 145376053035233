/**
 *  app.js
 *  @version: 1.0.0
 *  @author: DWIT MÉXICO
 *  @description: Punto de Entrada de la aplicación
 * 	@process: 2
*/

import React from 'react';
import Views from './core/views';
import socket from './core/socket';

process.env.AUTH = '8f841fe16f2920045fa988929e1692df6d8a5b6837c5df22c448e51a9ddbfc3b';
let user = window.localStorage.getItem("goworkers");
if ( user ) {
	user = JSON.parse(user);
	socket.emit('infoUser', user.id);
}

const App = () => (
	<div className="App">
		<Views></Views>
	</div>
);

export default App;
