/**
 *  video.jsx
 *  @version: 1.0.0
 *  @author: DWIT MÉXICO - Sergio
 *  @description: Card de Vídeos para perfil de empresa
*/

import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Consumer } from '../../context';
import shave from 'shave';

class CardMyVideo extends Component {

    passVariable() {
        this.props.history.push(`/video/${this.props.video.id_video}`);
    }

    render() {
        let videoUrl = '', titulo = '', nombre = '', funcion = '', sector = '';
       
        if (this.props.video) {
            videoUrl = this.props.video.video_url + "#t=3,4";
            titulo = this.props.video.titulo;
            nombre = this.props.video.nombre;
            funcion = this.props.video.funcion;
            sector = this.props.video.sector;
            shave('.card-name', 22);
        }
        return (
            <div className="card-video">
                <div className="thumbnail responsive-img justify-center align-center">
                    <video src={videoUrl} width="130"></video>
                    <button className="btn-play" onClick={this.passVariable.bind(this)}>
                        <i className="far fa-play-circle fa-2x color-lightBlue"></i>
                    </button>
                </div>
                <div className="info column">
                    <h4 className="font-regular padding card-name">
                        {titulo}
                    </h4>
                    <div className="white-space-8"></div>
                    <div className="align-center padding">
                        <i className="far fa-user-circle font-mini"></i>&nbsp;
                        <p className="font-mini">
                            {nombre}
                        </p>
                    </div>
                    <div className="white-space-8"></div>
                    <div className="padding">
                        <div className="align-center">
                            <i className="far fa-object-group font-mini"></i>&nbsp;
                            <p className="font-mini">
                                { funcion ? funcion : sector }
                            </p>
                        </div>
                    </div>
                    <div className="row">
                    <div className="edit justify-center">
                        { this.props.desktop ?  
                        <button className="font-tiny weight-bold color-darkBlue" onClick={ this.handleEditVideoDesk.bind(this) }>
                            <i className="fas fa-pen"></i>
                        </button> 
                        :  
                        <button className="font-tiny weight-bold color-darkBlue" onClick={this.handleEditVideo.bind(this) }>
                            <i className="fas fa-pen"></i>
                        </button>
                        }
                    </div>
                    <div className="contact contact-delete justify-center">
                        <button className="font-tiny weight-bold color-white" onClick = { this.handleDeleteVideo.bind(this) } >
                            <i className="fas fa-times"></i>
                        </button>
                    </div>
                    </div>
                </div>
            </div>
        );
    }

    componentDidMount() {
        shave('.card-name', 22);
    }

    handleEditVideo() {
        this.props.history.push(`/video/${this.props.video.id_video}/editar`);
    }

    handleEditVideoDesk() {
        let modalNewsLetter = document.getElementById('modal-edit-video');  
        modalNewsLetter.classList.remove('fadeOut');
        modalNewsLetter.classList.add('animated', 'fadeIn','faster');
        modalNewsLetter.classList.remove('hidden');
        this.props.setEditID(this.props.video.id_video);
        shave('.card-name', 22);
    }

    handleDeleteVideo() {
        let modal = document.getElementById('modal-delete-video');
        if (modal && modal.classList.contains('hidden')) {
            modal.classList.remove('hidden', 'fadeOut');
            modal.classList.add('fadeIn');
        }
        this.props.context.setDeleteItem(this.props.video.id_video);
    }
}

export default withRouter(Consumer(CardMyVideo));