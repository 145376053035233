import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Consumer } from '../../context';
import shave from 'shave';

class CardPlayer extends Component {

    passVariable() {              
        window.location = `/video/${this.props.video.id_video}`;
    }

    render() {
        let video_url = '', titulo = '', nombre = '', funcion = '', sector = '';
        if (this.props.video) {           
            video_url = this.props.video.video_url;
            titulo = this.props.video.titulo;
            nombre = this.props.video.nombre;
            funcion = this.props.video.funcion;
            sector = this.props.video.sector;    
            shave('.card-name', 22);
        }

        return (
            <div className="card-video cursor-pointer" onClick={this.passVariable.bind(this)}>
                <div className="thumbnail responsive-img justify-center align-center">
                    <video id="vid" src={video_url + "#t=3,4"}></video>
                </div>
                <div className="info column">
                    <h4 className="font-regular padding card-name">
                        {titulo}
                    </h4>
                    <div className="white-space-8"></div>
                    <div className="align-center padding">
                        <i className="far fa-user-circle font-mini"></i>&nbsp;
                        <p className="font-mini">
                            {nombre}
                        </p>
                    </div>
                    <div className="white-space-8"></div>
                    <div className="padding">
                        <div className="align-center">
                            <i className="far fa-clock font-mini"></i>&nbsp;
                            <p className="font-mini">
                                { funcion ? funcion : sector }
                            </p>
                        </div>
                    </div>
                    <div className="white-space-8"></div>
                </div>
            </div>
        );
    }
}

export default withRouter(Consumer(CardPlayer));