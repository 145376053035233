/**
 *  profile-influencer.jsx
 *  @version: 1.0.0
 *  @author: DWIT MÉXICO - Sergio, Kath
 *  @description: PERFIL EMPRESA VISTO DESDE CANDIDATO
*/

import React, { Component } from 'react';
import Header from '../components/header/header';
import HeaderWebHomeLoggedIn from '../components/header/header-desktop';
import MediaQuery from 'react-responsive';
import LeftBar from '../components/left-bar/left-bar';
import CardVideo from '../components/cards/video';
import CardOffer from '../components/cards/job';
import ImageLoader from 'react-loading-image';
import ModalVideoAdd from '../components/modals/AddVideo';
import ModalOfferAdd from '../components/modals/offerAdd';
import LoadingImage from '../img/loading.gif';
import HolderImage from '../img/user.png';
import { Consumer } from '../context';
import Request from "../core/httpClient";
const request = new Request();

class ProfileBrands extends Component {

    constructor(props) {
        super(props);
        this.state = {
            userData: { id: 0, tipo: 0 }, 
            company: '', 
            cualidades: [],
            videos: [],
            ofertas: [],
            addType: 1,
            numItems: 1,
            numItemsOffer: 1,
            items: [],
            itemsOffer: [],
            numItemsE: 11,
            firstE: true,
            itemsE: [],
            numItemsOfferE: 11,
            itemsOfferE: []
        }
    }

    goChat() {
        this.props.history.push(`/chat/${this.state.company.id_empresa}`)
    }

    render() {
        let company = '', image = '', cualidades = [];
        if (this.state.loading) {
            company = '';
        } else {
            if (this.state.company) {
                company = this.state.company;
            }
        }
        this.state.company.image ? image = this.state.company.image : image = HolderImage

        if (this.state.cualidades) {
            cualidades = this.state.cualidades;
        }
        return(
            <div className="profile-candidate column align-center">
                <MediaQuery minDeviceWidth = { 768 }>
                    <div className="view-web column">
                        <HeaderWebHomeLoggedIn />
                        <div className="row">
                            <LeftBar/>
                            <div className="column">
                                <div className="row justify-center">
                                    <div className="container-profile  column align-center justify-center">
                                        <div className="white-space-32"></div>
                                        <div className="row header-profile">
                                            <div className="column profile-column profile-public justify-center align-center">
                                                <div className="responsive-img justify-start align-start">
                                                    <img src={image} alt="" className="profile-image" />
                                                </div>
                                            </div>
                                            <div className="column column-info justify-center align-center">
                                                <div className="white-space-16"></div>
                                                <div className="row">                              
                                                    <h2 className="weight-bold">{ company.nombre }</h2>   
                                                </div>                           
                                                <div className="white-space-24"></div>
                                                <div className="row wrap">
                                                    <i className="fas fa-medal font-Large color-darkBlue icon-header"></i>
                                                    <p className="text-start font-regular text-abilities">{company.sector}</p>                   
                                                </div>
                                                <div className="white-space-24"></div>
                                                <div className="row">
                                                    <i className="fas fa-map-marker-alt font-Large color-darkBlue icon-header"></i>
                                                    <p className="text-start font-regular">{ company.ciudad }</p>  
                                                </div>
                                                <div className="white-space-24"></div>
                                            </div>
                                            <div className="column content-btns align-start justify-center">                                 
                                                <div className="btn-container btn-public btn-darkBlue justify-center">
                                                    {
                                                        this.state.fav ?
                                                        <div className="column btn-public  text-center color-white" onClick={this.addFav.bind(this)}>
                                                            <i className="fas fa-heart icon-btn font-regular"></i>
                                                            <div className="white-space-8"></div>
                                                            <h5 className="color-white font-regular weight-bold">
                                                                Eliminar favorito
                                                            </h5>
                                                        </div>
                                                        :
                                                        <div className="column btn-public  text-center color-white" onClick={this.addFav.bind(this)}>
                                                            <i className="far fa-heart icon-btn font-regular"></i>
                                                            <div className="white-space-8"></div>
                                                            <h5 className="color-white font-regular weight-bold">
                                                                Añadir favorito
                                                            </h5>
                                                        </div>
                                                    }
                                                </div>
                                                <div className="white-space-16"></div>
                                                <div className="btn-container btn-public btn-lightBlue justify-center" onClick={this.goChat.bind(this)}>
                                                    <div className="column btn-public text-center color-darkBlue">
                                                    <i className="fas fa-comment-dots icon-btn font-regular"></i>
                                                    <div className="white-space-8"></div>
                                                        <h5 className="color-darkBlue font-regular weight-bold">
                                                            Chat
                                                        </h5> 
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="white-space-16"></div>
                                        <div className="conteiner-labels column">
                                            <div className="white-space-8"></div>
                                            <div className="row row-labels justify-center wrap">
                                                {
                                                    cualidades.map((item, index) =>
                                                        <div className="user-label auto" key={index}>
                                                            <h5>
                                                                {item.name}
                                                            </h5>
                                                        </div>
                                                    )
                                                }
                                            </div>
                                        </div>               
                                            <div className="white-space-8"></div>    
                                            <div className="tabs-menu-profile justify-center">
                                                <div className="videos-tab column active-tab" id="videos-tab" onClick={this.handleVideos.bind(this)}>
                                                    <div className="icon justify-center">
                                                        <i className="fas fa-video color-white"></i>
                                                    </div>
                                                    <div className="title justify-center">
                                                        <button type="button" className="btn btn-square text-center weight-semi font-mini color-white">
                                                            Vídeos
                                                        </button>
                                                    </div>
                                                </div>
                                                <div className="offers-tab column" onClick={this.handleOffers.bind(this)} id="offers-tab">
                                                    <div className="icon justify-center">
                                                        <i className="fas fa-user-tie color-white"></i>
                                                    </div>
                                                    <div className="title justify-center">
                                                        <button type="button" className="btn btn-square text-center color-white weight-semi font-mini">
                                                            Ofertas
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="white-space-16"></div>
                                        <div className="my-offers justify-center" id="videos">
                                            <div className="row list-videos wrap" id="os" onScroll={this.scrollItemsE.bind(this)}>
                                                {
                                                    this.state.loadingState ?
                                                        <div className="justify-center align-center">
                                                            <i className="text-center fas fa-spin fa-spinner"></i>
                                                        </div> : ""
                                                }
                                                {
                                                    this.state.emptyVideo ?
                                                        <div className="column align-center">
                                                            <div className="white-space-8"></div>
                                                            <h3 className="text-center">
                                                                No existen videos.
                                                            </h3>
                                                        </div>
                                                        :
                                                        this.state.firstE ? this.state.itemsE.map((item) =>
                                                                <CardVideo video={item} key={item.id_video} /> 
                                                        ) :
                                                            this.displayItemsE()
                                                }
                                            </div>
                                        </div>
                                        <div className="white-space-16"></div>
                                        {
                                            this.state.loadingScrollE ? <i className="fas fa-spin fa-spinner"></i> : ""
                                        }
                                        <div className="my-offers justify-center hidden" id="offers">
                                            <div className="column list-cards" id="os" onScroll={this.scrollItemsE.bind(this)}>
                                                {
                                                    this.state.loadingState ?
                                                        <div className="justify-center">
                                                            <i className="fas fa-spin fa-spinner"></i>
                                                        </div> : ""
                                                }
                                                {
                                                    this.state.emptyOffer ?
                                                        <div className="column align-center">
                                                            <div className="white-space-8"></div>
                                                            <h3 className="text-center">
                                                                No existen ofertas.
                                                        </h3>
                                                        </div>
                                                        :
                                                        this.state.firstE ? this.state.itemsOfferE.map((item, index) =>
                                                            <CardOffer type="profile" key={item.id_oferta} oferta={item} />
                                                        ) :
                                                            this.displayItemsE()
                                                }
                                            </div>
                                        </div>
                                        <div className="white-space-16"></div>
                                        {
                                            this.state.loadingScrollOE ? <i className="fas fa-spin fa-spinner"></i> : ""
                                        }
                                    </div>
                                </div>                            
                            </div>
                        </div>
                        <ModalVideoAdd></ModalVideoAdd>
                    </div>
                </MediaQuery> 
                <MediaQuery maxWidth = { 768 }>
                    <Header back={this.props.context.prevLocation} />
                    <div className="container column">
                        <div className="candidate-cards column justify-start align-center">
                            <div className="image row">
                                <div className="column justify-center align-center">
                                    <div className="justify-center">
                                        <h4 className="weight-bold text-center font-regular name">
                                            { company.nombre }
                                        </h4>
                                    </div>
                                    <div className="white-space-4"></div>
                                    <div className="justify-center">
                                        <h5 className="text-center weight-semi font-tiny age">
                                            { company.sector }
                                        </h5> 
                                    </div>
                                    <div className="white-space-4"></div>
                                    <div className="justify-center">
                                        <h5 className="text-center weight-semi font-tiny city">
                                            {company.ciudad}
                                        </h5>  
                                    </div>
                                </div>
                                <div className="profile-container column">
                                    <div className="align-center justify-center">
                                        <div className="responsive-img justify-center align-center">
                                            <ImageLoader src={image} alt="Imagen del candidato" title="Imagen del candidato" className="image-profile"
                                            loading={() => <img className="image-profile" alt="Imagen del candidato" title="Imagen del candidato" src={LoadingImage} />} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="tabs-container justify-center">
                                <div className="column">
                                    <button type="button" className="btn btn-square text-center weight-semi font-mini active-tab" onClick = { this.handleVideos.bind(this) } id="videos-tab">
                                        <i className="fas fa-video"></i> Vídeos
                                    </button>
                                </div>
                                <div className="column">
                                    <button type="button" className="btn btn-square text-center weight-semi font-mini" onClick={this.handleOffers.bind(this) } id="offers-tab">
                                        <i className="fas fa-file-invoice-dollar"></i> Ofertas
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="white-space-4"></div>
                        <div className="conteiner-labels column">
                            <div className="soft-skills" data-flickity='{ "cellAlign": "left", "contain": true, "autoPlay": true, "pageDots": false }'>
                                {
                                    cualidades.map((item, index) =>
                                        <div className="user-label auto" key={index}>
                                            <h5 className="font-mini">
                                                {item.name}
                                            </h5>
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                    <div className="justify-center" id="videos">
                        <div className="vc container column" onScroll={this.scrollItems.bind(this)}>
                            {this.state.loadingState ?
                                <div className="justify-center">
                                    <i className="fas fa-spin fa-spinner"></i>
                                </div> : ""}
                            {this.state.emptyVideo ?
                                <div className="column align-center">
                                    <div className="white-space-8"></div>
                                    <h3 className="text-center">
                                        No existen videos.
                                        </h3>
                                </div>
                                :
                                this.state.first ? this.state.items.map((item) =>                                    
                                    <CardVideo video={item} key={item.id_video} />
                                    ) :
                                    this.displayItems()}
                        </div>
                    </div>
                    {
                        this.state.loadingScroll ? <i className="fas fa-spin fa-spinner"></i> : ""
                    }
                    <div className="offers justify-center hidden" id="offers">
                        <div className="container column" id="os" onScroll={this.scrollItems.bind(this)}>
                            {this.state.loadingState ?
                                <div className="justify-center">
                                    <i className="fas fa-spin fa-spinner"></i>
                                </div> : ""}
                            {this.state.emptyOffer ?
                                <div className="column align-center">
                                    <div className="white-space-8"></div>
                                    <h3 className="text-center">
                                        No existen ofertas.
                                    </h3>
                                </div>
                                :
                                this.state.first ? this.state.ofertas.map((item) =>
                                    <CardOffer type="profile" key={item.id_oferta} oferta={item} />) :
                                    this.displayItems()}
                        </div>
                    </div>
                    {
                        this.state.loadingScrollO ? <i className="fas fa-spin fa-spinner"></i> : ""
                    }
                    <div className="white-space-64"></div>
                    <div className="btn-container justify-center fixed-contact">
                        <button className="btn btn-lightBlue weight-bold color-darkBlue" onClick={this.goChat.bind(this)}>
                            <i className="fas fa-comments"></i> Chat
                        </button>
                        {this.state.fav ?
                            <button className="btn btn-darkBlue weight-bold color-lightBlue" onClick={this.addFav.bind(this)}>
                                <i className="fas fa-heart"></i> Eliminar favorito
                            </button>
                        :
                            <button className="btn btn-darkBlue weight-bold color-lightBlue" onClick={this.addFav.bind(this)}>
                                <i className="far fa-heart"></i> Añadir favorito
                            </button>}
                    </div>
                </MediaQuery>
                <ModalVideoAdd></ModalVideoAdd>
                <ModalOfferAdd></ModalOfferAdd>       
            </div>
        );
    }

    componentDidMount() {
        this.props.context.mapLocation(window.location.pathname);
        this.load();        
    }

    scrollItems(event) {
        if (event.target.scrollTop + event.target.clientHeight >= event.target.scrollHeight) {
            this.loadMoreItems();
            this.setState({ first: false });
        }
    }

    scrollItemsE(event) {
        if (event.target.scrollTop + event.target.clientHeight >= event.target.scrollHeight) {
            this.loadMoreItemsE();
            this.setState({ firstE: false });
        }
    }

    displayItems() {
        var items = [];
        let videosTab = document.getElementById('videos-tab');
        if (videosTab) {
            if (videosTab.classList.contains('active-tab')) {
                for (let i = 0; i < this.state.numItems + 1; i++) {
                    if (this.state.videos[i]) {
                        items.push(                            
                            <CardVideo video={this.state.videos[i]} key={this.state.videos[i].id_video} /> );
                    }
                }
            } else {
                for (let i = 0; i < this.state.numItemsOffer + 2; i++) {
                    if (this.state.ofertas[i]) {
                        items.push(
                            <CardOffer type="profile" key={this.state.ofertas[i].id_oferta} oferta={this.state.ofertas[i]} />);
                    }
                }
            }
        }
        return items;
    }

    loadMoreItems() {
        let videosTab = document.getElementById('videos-tab');
        if (videosTab) {
            if (videosTab.classList.contains('active-tab')) {

                if (this.state.numItems <= this.state.videos.length) {
                    this.setState({ loadingScroll: true });
                    setTimeout(() => {
                        this.setState({ numItems: this.state.numItems + 3, loadingScroll: false });
                    }, 3000);
                }
            } else {
                if (this.state.numItemsOffer <= this.state.ofertas.length) {
                    this.setState({ loadingScrollO: true });
                    setTimeout(() => {
                        this.setState({ numItemsOffer: this.state.numItemsOffer + 3, loadingScrollO: false });
                    }, 3000);
                }
            }
        }
    }

    displayItemsE() {
        var items = [];
        let videosTab = document.getElementById('videos-tab');
        if (videosTab) {
            if (videosTab.classList.contains('active-tab')) {
                for (let i = 0; i < this.state.numItemsE + 1; i++) {
                    if (this.state.videos[i]) {
                        items.push(
                            <CardVideo video={this.state.videos[i]} key={this.state.videos[i].id_video} />
                        );
                    }
                }
            } else {
                for (let i = 0; i < this.state.numItemsOfferE + 1; i++) {
                    if (this.state.ofertas[i]) {
                        items.push(
                            <CardOffer type="profile" key={this.state.ofertas[i].id_oferta} oferta={this.state.ofertas[i]} />);
                    }
                }
            }
        }
        return items;
    }

    loadMoreItemsE() {
        let videosTab = document.getElementById('videos-tab');
        if (videosTab) {
            if (videosTab.classList.contains('active-tab')) {
                if (this.state.numItemsE <= this.state.videos.length) {
                    this.setState({ loadingScrollE: true });
                    setTimeout(() => {
                        this.setState({ numItemsE: this.state.numItemsE + 12, loadingScrollE: false });
                    }, 3000);
                }
            } else {
                if (this.state.numItemsOfferE <= this.state.ofertas.length) {
                    this.setState({ loadingScrollOE: true });
                    setTimeout(() => {
                        this.setState({ numItemsOfferE: this.state.numItemsOfferE + 12, loadingScrollOE: false });
                    }, 3000);
                }
            }   
        }
    }

    async load() {
        const user = await this.props.context.loadUser();
        if (user) {
            this.setState({ userData: user.id });
        }
        this.setState({ loading: true });
        const id_empresa = this.props.match.params.id;
        const data = { id_empresa: id_empresa, id: user.id.id };
        const response = await request.post("/companies/get/public", data);
        if (response && !response.error) {
            if (response.empty) {
                this.setState({ empty: true, loading: false });
            } else {
                this.setState({ company: response.company, fav: response.company.favorito, loading: false });
                this.loadFlickity();
                if (response.company.habilidades) {
                    const cualidades = JSON.parse(response.company.habilidades);
                    this.setState({ cualidades });
                }
            }
        } else {
            this.setState({ error: response.error, loading: false });
        }

        this.setState({ loadingState: true });
        const dataVideo = { id: id_empresa, tipo: 2 };
        //console.log("Data: ", dataVideo);
        const videosData = await request.post('/users/videos', dataVideo);
        if (videosData) {
            this.setState({ message: videosData.message });
            //console.log("Mensaje: ", videosData.message);
            if (videosData.empty) {
                this.setState({ emptyVideo: videosData.empty, loadingState: false });
                //console.log("Vacío: ", videosData.empty);
            } else {
                this.setState({ videos: videosData.video, emptyVideo: false });
                //console.log("Videos perfil: ", videosData.video);
                const dataVideos = videosData.video.slice(0, 2).map((item) => item);
                const twelveVideos = videosData.video.slice(0, 12).map((item) => item);
                //console.log("dataVideo: ", dataVideo);
                this.setState({ items: dataVideos, loadingState: false, itemsE: twelveVideos });
            }
        }

        const dataOffer = { id: id_empresa };
        const offersData = await request.post('/offers/getAllById', dataOffer);
        if (offersData) {
            this.setState({ message: offersData.message });
            if (offersData.empty) {
                this.setState({ emptyOffer: offersData.empty, loadingState: false });
                //console.log("Vacío: ", videosData.empty);
            } else if (offersData.offers) {
                this.setState({ ofertas: offersData.offers, emptyOffer: false });
                const dataOffers = offersData.offers.slice(0, 3).map((item) => item);
                const twelveOffers = offersData.offers.slice(0, 12).map((item) => item);
                //console.log("dataVideo: ", dataVideo);
                this.setState({ itemsOffer: dataOffers, loadingState: false, itemsOfferE: twelveOffers });
            }
        }
    }

    async addFav() {
        const idEmpresa = parseInt(this.props.match.params.id);

        const data = {
            tipo_user: this.state.userData.tipo,
            id_user: this.state.userData.id,
            tipo: 2,
            id_elemento: idEmpresa
        };

        const res = await request.post('/favorites/set', data);
        if (res.error) {
            console.log("Error al añadir favoritos");
        } else {
            this.setState({ fav: !this.state.fav });
        }
    }

    loadFlickity() {
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = 'https://unpkg.com/flickity@2/dist/flickity.pkgd.min.js';
        document.body.append(script);
    }

    handleVideos() {
        let myVideos = document.getElementById('videos');
        let myOffers = document.getElementById('offers');
        let videosTab = document.getElementById('videos-tab');
        let offersTab = document.getElementById('offers-tab');
        if (offersTab.classList.contains('active-tab')) {
            offersTab.classList.remove('active-tab');
            videosTab.classList.add('active-tab');
            this.setState({ first: true, firstE: true, items: [], itemsOffer: [], numItems: 1, videos: [], ofertas: [], numItemsOffer: 1, numItemsE: 11, itemsE: [], itemsOfferE: [] });
            this.load();
        }
        myOffers.classList.add('hidden');
        myVideos.classList.remove('hidden');
        /*if (myOffers) {
            myOffers.classList.add('hidden');
        }
        if (myVideos) {
            myVideos.classList.remove('hidden');
        }*/
        this.setState({ addType: 1 });
    }

    handleOffers() {
        let myVideos = document.getElementById('videos');
        let myOffers = document.getElementById('offers');
        let videosTab = document.getElementById('videos-tab');
        let offersTab = document.getElementById('offers-tab');

        if (videosTab.classList.contains('active-tab')) {
            videosTab.classList.remove('active-tab');
            offersTab.classList.add('active-tab');
        }
        myVideos.classList.add('hidden');
        myOffers.classList.remove('hidden');
        /*if (myVideos) {
            myVideos.classList.add('hidden');
        }
        if (myOffers) {
            myOffers.classList.remove('hidden');
        }*/
        this.setState({ addType: 2 });
    }
}

export default Consumer(ProfileBrands);