/**
 *  postulations.jsx
 *  @version: 1.0.0
 *  @author: DWIT MÉXICO - Sergio
 *  @description: Pantalla de candidatos para ver sus postulaciones
*/

import React, { Component } from 'react';
import Header from '../components/header/header';
import HeaderWebHomeLoggedIn from '../components/header/header-desktop';
import CardJobPostulate from '../components/cards/job-postulate';
import { Consumer } from '../context';
import Request from '../core/httpClient';
import LeftBar from '../components/left-bar/left-bar';
import MediaQuery from 'react-responsive';
import ModalOfferAdd from '../components/modals/offerAdd';
import ModalVideoAdd from '../components/modals/AddVideo';
const request = new Request();

class Postulations extends Component {

    constructor(props) {
        super(props);
        this.state = {
            userData: { id: 0, tipo: 0 },
            offers: []
        }
    }

    render() {
        return (
            <div className="offer-candidates my-offers column">
                  <MediaQuery minDeviceWidth = { 768 }>
                    <div className="view-web column">
                        <HeaderWebHomeLoggedIn/>
                        <div className="row">
                            <LeftBar active="menuOffers" />
                            <div className="column">
                                <div className="white-space-32"></div>
                                <div className="row justify-center">
                                    <div className="column container ">
                                    <div className="justify-center head-info">
                                            <div className="row justify-center">
                                                <h3 className="text-center">
                                                    Mis Ofertas
                                                </h3>
                                            </div>
                                        </div>
                                        <div className="white-space-16"></div>
                                        <div className="row">
                                            <div className={`cards-container ${this.state.empty ? '' : 'list-videos wrap' } row align-center justify-center`}>
                                            {
                                                this.state.loading?
                                                <div className="justify-center">
                                                    <i className="fas fa-spinner fa-spin color-darkBlue"></i>
                                                </div>
                                                :
                                                this.state.empty ?
                                                    <div className="column align-center">
                                                        <div className="white-space-16"></div>
                                                        <h3 className="text-center">
                                                            No se encontraron postulaciones.
                                                        </h3>
                                                    </div>
                                                    :
                                                    this.state.offers.map((offer,index) =>
                                                        <CardJobPostulate key={index} {...offer} user={this.state.userData}/>
                                                    )
                                                    
                                            }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <ModalOfferAdd></ModalOfferAdd>
                            <ModalVideoAdd></ModalVideoAdd>
                        </div>
                    </div>
                </MediaQuery>
                <MediaQuery maxWidth = { 768 }>
                <Header />
                <div className="justify-center header">
                    <div className="container text-center column">
                        <h3>
                            Mis ofertas
                        </h3>
                    </div>
                </div>
                <div className="justify-center">
                    <div className="container column">
                        {this.state.loading?
                            <div className="justify-center">
                                <i className="fas fa-spinner fa-spin color-darkBlue"></i>
                            </div>
                            :
                            this.state.empty ?
                                <div className="column align-center">
                                    <div className="white-space-16"></div>
                                    <h3 className="text-center">
                                        No se encontraron postulaciones.
                                    </h3>
                                </div>
                                :
                                this.state.offers.map((offer,index) =>
                                    <CardJobPostulate key={index} {...offer} user={this.state.userData}/>
                                )
                        }
                    </div>
                </div>
                </MediaQuery>
            </div>
        );
    }

    componentDidMount() {
        this.load();
        this.props.context.mapLocation(window.location.pathname)
        //console.log(window.screen.availWidth)
    }

    async load() {
        const user = await this.props.context.loadUser();
        if (user) { 
            this.setState({ userData: user.id, loading: true });
            const res = await request.post('/offers/get/postulations/candidate', user.id);
            if(res.offers) {
                this.setState({offers: res.offers, empty: false})
            } else {
                this.setState({offers: [], empty: true})
            }
            this.setState({loading: false})
        }
    }

}

export default Consumer(Postulations);