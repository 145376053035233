/**
 *  video.jsx
 *  @version: 1.0.0
 *  @author: DWIT MÉXICO - Sergio
 *  @description: Card de Vídeos
*/

import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Consumer } from '../../context';
import shave from 'shave';

class CardVideo extends Component {

    passVariable() {
        this.props.history.push(`/video/${this.props.video.id_video}`);
    }

    render() {
        let videoUrl = '', titulo = '', nombre = '', funcion = '', sector = '';

        if (this.props.video) {
            videoUrl = this.props.video.video_url+"#t=3,4";
            titulo = this.props.video.titulo;
            nombre = this.props.video.nombre;
            funcion = this.props.video.funcion;
            sector = this.props.video.sector;
            shave('.card-name', 22);
        }
        return(
            <div className="card-video">
                <div className="thumbnail responsive-img justify-center align-center">
                    <video src={videoUrl}></video>
                    <button className="btn-play" onClick={this.passVariable.bind(this)}>
                        <i className="far fa-play-circle fa-2x color-lightBlue"></i>
                    </button>
                </div>
                <div type="button" className="info column">
                    <h4 className="font-regular padding cursor-pointer card-name" onClick = { this.passVariable.bind(this) }>
                        {titulo}
                    </h4>
                    <div className="white-space-8"></div>
                    <div className="align-center padding cursor-pointer" onClick={this.handleProfile.bind(this)}>
                        <i className="far fa-user-circle font-mini"></i>&nbsp;
                        <p className="font-mini">
                            {nombre}
                        </p>
                    </div>
                    <div className="white-space-8"></div>
                    <div className="padding cursor-pointer" onClick={this.handleProfile.bind(this)}>
                        <div className="align-center">
                            <i className="far fa-object-group font-mini"></i>&nbsp;
                            <p className="font-mini">
                                { funcion ? funcion : sector }
                            </p>
                        </div>
                    </div>
                    <div className="contact justify-center">
                        <button type="button" onClick = { this.handleChat.bind(this) } className="font-tiny weight-bold color-darkBlue">
                            <i className="fas fa-comments"></i>&nbsp; CHAT
                        </button>
                    </div>
                </div>
            </div>
        );
    }    

    componentDidUpdate() {
        shave('.card-name', 22);
    }

    handleProfile() {
        if (this.props.video.tipo === "1") {
            this.props.history.push(`/empresa/${this.props.video.id_user}`);
        } else {
            this.props.history.push(`/candidato/${this.props.video.id_user}`);
        }
    }

    handleChat() {
        this.props.history.push(`/chat/${this.props.video.id_user}`);
    }

}

export default withRouter(Consumer(CardVideo));