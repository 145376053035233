/**
 *  header.jsx
 *  @version: 1.0.0
 *  @author: DWIT MÉXICO - Sergio , kath
 *  @description: Headers como componentes
*/

import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import { Consumer } from '../../context';
import Isotipo from '../../img/isotipo.png';
import MediaQuery from 'react-responsive';
import Request from '../../core/httpClient';

const req = new Request();

class HeaderSearch extends Component {
    constructor(props) {
        super(props);
        this.state={
            userData: {id: 0, tipo: 0}, filters: {}, cities: [], 
            intereses: [], sectores: [], interest:[], funciones: []
        }
    }

    addFilter(filter) {
        const filtersState = this.state.filters;
        Object.assign(filtersState, filter);
        this.setState({filters: filtersState});
    }

    interesesMultiSelect(event) {
        const filtersState = this.state.filters;
        Object.assign(filtersState, {interest: event});
        this.setState({filters: filtersState});
    }

    backPage(backPage, prevLoc) {
        this.props.context.quitLocation(prevLoc);
        this.props.history.push(backPage);
    }

    render() {
        let backPage = '/inicio';
        if (this.props.context.prevLoc) {
            const prevLoc = this.props.context.prevLoc;
            backPage = prevLoc[prevLoc.length - 2];
        }
        return (
            <div className="justify-center search-sticky">
            <MediaQuery minDeviceWidth = { 768 }>
                <header className="header-logged justify-center view-web">
                    <div className="container main-container">
                        <div className="header-search align-center">
                            <div className="row justify-center align-center">  
                                <div className="column">     
                                 
                                        <Link to ="/inicio" className="justify-start align-center">
                                            <img className="logo-search" src = { Isotipo } alt="Logo Waala" title="Logo Waala" />
                                        </Link>
                                 
                                </div> 
                                <form className="search-button form-search align-center justify-center" onSubmit={this.props.search.bind(this)}>
                                    <i className="icon-search icon-search-search fas fa-search"></i>
                                    <input className="input-search input-search-web weight-semi text-input"  type="text" id="search" name="search" placeholder="Buscar en goworkers" onChange={this.props.setSearch.bind(this)}/>
                                </form>
                                <div className="justify-end align-center">           
                                    <input type="checkbox" id="menyAvPaa" checked = { this.state.checked }/>
                                    
                                    <label className="btn-web btn-lightBlue auto row align-center" id="filter" htmlFor="menyAvPaa">
                                        <i className=" fas fa-filter font-tiny color-darkBlue"></i>
                                        <p className="color-darkBlue text-center" id="filter-text">
                                            &nbsp;Filtrar búsqueda
                                        </p>
                                    </label>
                                
                                    <div className="column align-center" id="menu-filter" >
                                <div className="row header-filter">
                                    <div className="column align-start justify-center">
                                        <h3 className="color-white">FILTROS</h3>
                                    </div>
                                    <div className="column align-end justify-center">
                                        <label className="fas fa-times color-white" id="closemain" htmlFor="menyAvPaa"/>
                                    </div>
                                </div>
                                <div className="container column">
                                    <div className="white-space-16"></div>
                                    <div className="row">
                                        <div className="inputfilter-container align-center">
                                            <i className="fas fa-euro-sign color-pink"></i>
                                            <input type="text" name="minprice" placeholder="Salario Mín" className="input input-filter" onChange={(event) => this.addFilter({minprice: event.target.value})}/>
                                        </div>
                                        <div className="inputfilter-container align-center">
                                            <i className="fas fa-euro-sign color-pink"></i>
                                            <input type="text" name="maxprice" placeholder="Salario Max" className="input input-filter" onChange={(event) => this.addFilter({maxprice: event.target.value})}/>
                                        </div>
                                    </div>
                                    <div className="white-space-16"></div>
                                    <hr/>
                                    <div className="input-container align-center">
                                        <div className="column"> 
                                            <h4>Ciudad: </h4>
                                        </div>      
                                        <div className="column">
                                            <select name="city" className="input input-filter-opt" value={this.state.filters.ciudad}
                                                onChange={(event) => this.addFilter({ciudad: event.target.value})}>
                                                <option value="">Todas</option>
                                                {this.state.cities.map((ciudad, index) => {
                                                    return(<option key={index} value={ciudad.ciudad}>{ciudad.ciudad}</option>)
                                                }
                                                )}
                                            </select>
                                        </div>
                                    </div>
                                    <hr/>
                                    {
                                        this.state.userData.tipo.toString() === "2"?
                                        <Fragment>
                                            <div className="input-container align-center">
                                                <div className="column">
                                                    <h4>Género :</h4>
                                                </div>
                                                <div className="column">
                                                    <select name="gender" className="input input-filter-opt" value={this.state.filters.genero}
                                                        onChange={(event) => this.addFilter({genero: event.target.value})}>
                                                        <option value="">Todos</option>
                                                        <option value="H">Hombre</option>
                                                        <option value="M">Mujer</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <hr />
                                            <div className="input-container align-center">
                                                <div className="column">
                                                    <h4>Función :</h4>
                                                </div>
                                                <div className="column">
                                                    <select name="gender" className="input input-filter-opt" value={this.state.filters.genero}
                                                        onChange={(event) => this.addFilter({funcion: event.target.value})}>
                                                        <option value="">Todos</option>
                                                        {this.state.funciones.map((funcion,index)=>
                                                            <option key={index} value={funcion.name}>{funcion.name}</option>
                                                        )}
                                                    </select>
                                                </div>
                                            </div>
                                            <hr />
                                        </Fragment>
                                        : null
                                    }
                                    {
                                        this.state.userData.tipo.toString() === "1"?
                                        <Fragment>
                                            <div className="input-container align-center">
                                                <div className="column">
                                                    <h4>Sector :</h4>
                                                </div>
                                                <div className="column">
                                                    <select name="sector" className="input input-filter-opt" value={this.state.filters.sector}
                                                        onChange={(event) => this.addFilter({sector: event.target.value})}>
                                                        <option value="">Todos</option>
                                                        {this.state.sectores.map((sector,index)=>
                                                            <option key={index} value={sector.name}>{sector.name}</option>
                                                        )}
                                                    </select>
                                                </div>
                                            </div>
                                            <hr />
                                        </Fragment>
                                        : null
                                    }
                                    <div className="white-space-16"></div>
                                    <div className="btn-container justify-center">
                                        <button className="btn btn-darkBlue color-white text-center btn-purple-aply" id="btn-marcas" onClick={this.props.filterCards.bind(this, this.state.filters)}>
                                            APLICAR FILTROS
                                        </button>
                                    </div>
                                </div>
                            </div>
                                 </div>
                            </div>
                        </div>
                    </div>
                </header>
            </MediaQuery>
            <MediaQuery maxWidth = { 768 }>
            <header className="header-single justify-center" id="header-search">
                <div className="container container-header-search">
                    <div className="header-search align-center">
                        <div className="back auto align-center">
                            <a onClick={this.backPage.bind(this, backPage, this.props.context.prevLoc)}>
                                <i className="fas fa-angle-left color-white font-regular"></i>
                            </a>
                        </div>
                        <form className="form-search align-center justify-center" onSubmit={this.props.search.bind(this)}>
                            <i className="icon-search icon-search-search fas fa-search"></i>
                            <input className="input-search input-search-web font-Mini color-darkBlue weight-semi"  type="text" id="search" name="search" placeholder="Busca en goworkers" onChange={this.props.setSearch.bind(this)}/>
                        </form>
                        <div className="filter-icon auto justify-end align-center">                            
                            <input type="checkbox" id="menyAvPaa" checked = { this.state.checked }/>
                            <label className="" id="filter" htmlFor="menyAvPaa">
                                <i className="text-center fas fa-filter font-tiny color-white"></i>
                                <p className="color-white text-center" id="filter-text">
                                    Filtrar
                                </p>
                            </label>
                            
                            <div className="column align-center" id="menu-filter" >
                                <div className="row header-filter">
                                    <div className="column align-start justify-center">
                                        <h3 className="color-white">FILTROS</h3>
                                    </div>
                                    <div className="column align-end justify-center">
                                        <label className="fas fa-times color-white" id="closemain" htmlFor="menyAvPaa"/>
                                    </div>
                                </div>
                                <div className="container column">
                                    <div className="white-space-16"></div>
                                    <div className="row">
                                        <div className="inputfilter-container align-center">
                                            <i className="fas fa-euro-sign color-pink"></i>
                                            <input type="text" name="minprice" placeholder="Salario Mín" className="input input-filter" onChange={(event) => this.addFilter({minprice: event.target.value})}/>
                                        </div>
                                        <div className="inputfilter-container align-center">
                                            <i className="fas fa-euro-sign color-pink"></i>
                                            <input type="text" name="maxprice" placeholder="Salario Max" className="input input-filter" onChange={(event) => this.addFilter({maxprice: event.target.value})}/>
                                        </div>
                                    </div>
                                    <div className="white-space-16"></div>
                                    <hr/>
                                    <div className="input-container align-center">
                                        <div className="column"> 
                                            <h4>Ciudad: </h4>
                                        </div>      
                                        <div className="column">
                                            <select name="city" className="input input-filter-opt" value={this.state.filters.ciudad}
                                                onChange={(event) => this.addFilter({ciudad: event.target.value})}>
                                                <option value="">Todas</option>
                                                {this.state.cities.map((ciudad, index) => {
                                                    return(<option key={index} value={ciudad.ciudad}>{ciudad.ciudad}</option>)
                                                }
                                                )}
                                            </select>
                                        </div>
                                    </div>
                                    <hr/>
                                    {
                                        this.state.userData.tipo.toString() === "2"?
                                        <Fragment>
                                            <div className="input-container align-center">
                                                <div className="column">
                                                    <h4>Género :</h4>
                                                </div>
                                                <div className="column">
                                                    <select name="gender" className="input input-filter-opt" value={this.state.filters.genero}
                                                        onChange={(event) => this.addFilter({genero: event.target.value})}>
                                                        <option value="">Todos</option>
                                                        <option value="H">Hombre</option>
                                                        <option value="M">Mujer</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <hr />
                                            <div className="input-container align-center">
                                                <div className="column">
                                                    <h4>Función :</h4>
                                                </div>
                                                <div className="column">
                                                    <select name="gender" className="input input-filter-opt" value={this.state.filters.genero}
                                                        onChange={(event) => this.addFilter({funcion: event.target.value})}>
                                                        <option value="">Todos</option>
                                                        {this.state.funciones.map((funcion,index)=>
                                                            <option key={index} value={funcion.name}>{funcion.name}</option>
                                                        )}
                                                    </select>
                                                </div>
                                            </div>
                                            <hr />
                                        </Fragment>
                                        : null
                                    }
                                    {
                                        this.state.userData.tipo.toString() === "1"?
                                        <Fragment>
                                            <div className="input-container align-center">
                                                <div className="column">
                                                    <h4>Sector :</h4>
                                                </div>
                                                <div className="column">
                                                    <select name="sector" className="input input-filter-opt" value={this.state.filters.sector}
                                                        onChange={(event) => this.addFilter({sector: event.target.value})}>
                                                        <option value="">Todos</option>
                                                        {this.state.sectores.map((sector,index)=>
                                                            <option key={index} value={sector.name}>{sector.name}</option>
                                                        )}
                                                    </select>
                                                </div>
                                            </div>
                                            <hr />
                                        </Fragment>
                                        : null
                                    }
                                    <div className="white-space-16"></div>
                                    <div className="btn-container justify-center">
                                        <button className="btn btn-darkBlue color-white text-center btn-purple-aply" id="btn-marcas" onClick={this.props.filterCards.bind(this, this.state.filters)}>
                                            APLICAR FILTROS
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </header>
                </MediaQuery>
            </div>
        )
    }

    componentDidMount() {
        this.load();
        const params = new URLSearchParams(this.props.location.search);
        let search = '';
        if(params) {
            search = params.get('search');
            const input = document.getElementById('search');
            if(input) {
                input.value = search;
            }
        }
    }

    async load() {
        const user = await this.props.context.loadUser();
        if(user) {
            this.setState({userData: user.id})
        }
        let cities = [];
        let funciones = [];
        let sectores = [];
        const resC = await req.get('/cities');
        //const resI = await req.get('/intereses');
        const areas = await req.get('/areas');
        if(resC.ciudades) {
            cities = resC.ciudades.ciudades;
        }
        /*if(resI.categorias) {
            intereses = resI.categorias.map(interes => {
                return {...interes, label: interes.categoria, value: interes.categoria}
            });
        }*/
        if(areas.categorias) {
            sectores = areas.categorias[1].sectores;
            funciones = areas.categorias[0].funciones;
        }
        this.setState({cities, funciones, sectores});
    }
};

export default withRouter(Consumer(HeaderSearch));