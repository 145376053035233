/**
 *  postulates.jsx
 *  @version: 1.0.0
 *  @author: DWIT MÉXICO - Sergio
 *  @description: Cards de candidatos / freelancers postulados
*/

import React, { Component } from 'react';
import HolderImage from '../../img/user.png';
import { Consumer } from '../../context';
import Request from '../../core/httpClient';
import shave from 'shave';
const request = new Request();

class CardPostulates extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }
    render() {        
        let nombre = '', ciudad = '', imagen = '', estado = '', funcion = '';
        if (this.props.postulado) {
            nombre = this.props.postulado.nombre;
            ciudad = this.props.postulado.ciudad;
            estado = this.props.postulado.estado;
            funcion = this.props.postulado.funcion;
            shave('.card-name', 22);
            if (this.props.postulado.imagen) {
                imagen = this.props.postulado.imagen;
            } else {
                imagen = HolderImage;
            }
            
        }
        return (
            <div className="card-company card-candidate">
                <div className="head">
                    <div className="logo responsive-img align-center">
                        <img src={imagen} alt="HP Logo" title="HP Logo" className="cover-img" />
                    </div>
                </div>
                <div className="info column">
                    <div className="title column">
                        <h4 className="font-regular padding card-name">
                            {nombre}
                        </h4>
                    </div>
                    <div className="white-space-8"></div>
                    <div className="align-center padding">
                        <i className="far fa-compass font-mini"></i>&nbsp;
                        <p className="font-mini">
                            {ciudad}
                        </p>
                    </div>
                    <div className="white-space-8"></div>
                    <div className="align-center padding">
                        <i className="far fa-object-group font-mini"></i>&nbsp;
                        <p className="font-mini">
                            {funcion}
                        </p>
                    </div>
                    <div className="white-space-8"></div>
                    <div className="btn-container">
                        {estado === 'ACEPTADO' ?
                        <React.Fragment>
                            <div className="column btn btn-lightBlue aling-center justify-center">    
                                <p className="font-mini weight-bold color-darkBlue text-center">ACEPTADO</p>
                            </div>
                           
                            {this.state.loadingCancel?
                                <div className="column align-center justify-center">
                                    <i className="fas fa-spinner fa-spin"></i>
                                </div>:
                                <button className="btn btn-red font-mini weight-bold color-darkBlue text-center" onClick={this.cancelPostulate.bind(this)}>
                                    <i className="fas fa-reply"></i> CANCELAR
                                </button>
                            }
                        </React.Fragment>
                        :
                        this.state.loadingAccept?
                            <div className="column align-center justify-center">
                                <i className="fas fa-spinner fa-spin"></i> 
                            </div>:
                            <button className="btn btn-lightBlue font-mini weight-bold color-darkBlue text-center" onClick={this.acceptPostulate.bind(this)}>
                                <i className="fas fa-reply"></i> ACEPTAR 
                            </button>
                        }
                    </div>
                </div>
            </div>
        );
    }

    componentDidMount() {
        shave('.card-name', 22);
    }

    async acceptPostulate() {
        this.setState({loadingAccept: true});
        const data = { idCandidato: this.props.postulado.id_candidato, id: this.props.postulado.id_postulaciones };
        const response = await request.post('/offers/accept/postulations/candidate', data);
        if (response) {
            if (response.actualizado) {
                this.setState({ actualizado: response.actualizado, loading: false });
                this.props.refresh();
            }
        }
        this.setState({loadingAccept: false});
    }

    async cancelPostulate() {
        this.setState({loadingCancel: true});
        const data = { id: this.props.postulado.id_candidato, postulacion: this.props.postulado.id_postulaciones };
        const response = await request.post('/offers/cancel/postulations/candidate', data);
        if (response) {
            if (response.cancelled) {
                this.setState({ actualizado: response.cancelled, loadingCancel: false });
                this.props.refresh();
            }
        }
        this.setState({loadingCancel: false});
    }
}

export default Consumer(CardPostulates);